/* Hero left universal */

// left text
// right square image

// normal variant
// inverse variant

.hero-left-universal {
	position: relative; // needed for absolute positioning of the overlay

	// styles for bg image
	background-size: cover;
	background-repeat: no-repeat;
	background-position-x: 85%;
	position: relative;

	@include media-breakpoint-up(sm) {
		background-position-x: 95%;
	}
	@include media-breakpoint-up(md) {
		background-position-x: center;
	}

	// overlay container :before
	// gradient container :after
	&:before,
	&:after {
		display: block;
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	// in normal variant they are empty by default, but can be customized if needed

	// inverse variant / overlay and gradient
	&.inverse {

		// dark overlay
		&:before {
			background-color: $blue-dark;
			opacity: .6;
		}
		// dark gradient
		&:after {
			background-image: linear-gradient(to top, $blue-dark, rgba($blue-dark, 0) 30%);
		}
	}


	.container {
		position: relative;
		z-index: 3; // moving it above overlay and gradient pseudo elements

		@include media-breakpoint-up(lg) {
			min-height: 584px;
		}

		& > .row {
			align-items: flex-start;
		}
	}

	// headline icon
	.hero-headline {
		&.icon-top {
			display: flex;
			flex-flow: column;
			align-items: center;
			-ms-flex-align: center;

			@include media-breakpoint-up(lg) {
				align-items: start;
				-ms-flex-align: start;
			}
		}

		.headline-text {
			max-width: 100%; //IE11 fix
		}
	}

	// button group
	.hero-btn-group {
		@include media-breakpoint-up(lg) {
			// has right margin to separate multiple buttons
			.btn-wrapper {
				margin-right: $base-unit * 1.5;
			}
		}
	}

	//left
	.hero-content {
		@include media-breakpoint-down(md) {
			margin-bottom: $base-unit*1.5;
		}
	}

	// right
	.hero-right {
		display: flex;
		justify-content: center;

		.money-back .money-back__item {
			padding-top: $base-unit*1.5;
		}
	}
}
