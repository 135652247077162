/* System requirements Pattern */
.system-req {
	position: relative;
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: 0 auto;
	max-width: $base-unit*70.5;
	min-width: 1px;
	padding: $base-unit*3.5 $base-unit*1.25;
	border-radius: $base-unit*2;
	background-color: $gray09;

	.inverse &:not([class*='bg-']) {
		background-color: $blue-deep;
	}

	@include media-breakpoint-up(lg) {
		padding: $base-unit*5;
		border-radius: $base-unit*2.5;
	}

	h3 {
		margin-top: $base-unit*2.5;

		@include media-breakpoint-up(lg) {
			margin-top: $base-unit*4;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	.system-req-content {
		max-width: $base-unit*56;
		text-align: initial;
		margin-left: auto;
		margin-right: auto;
	}

	.system-req-title {
		margin-bottom: $base-unit;
	}

	.system-req-text+* {
		margin-top: $base-unit;
	}

	.system-req-text+* .feature-items {
		margin-top: $base-unit;

		@include media-breakpoint-up(lg) {
			margin-top: $base-unit*2;
		}
	}
}