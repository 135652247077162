/* Timeline */
.timeline {
	padding-bottom: $base-unit;

	.card-img-wrapper .card-img-top {
		@include radius($base-unit)

		@include media-breakpoint-up(lg) {
			@include radius($base-unit*2)
		}
	}
	.card-body {
		padding: $base-unit;
		text-align: center;

		.badge {
			margin-bottom: $base-unit/2;
		}

		.card-title {
			margin-bottom: $base-unit/2;
		}
	}

	.card-footer {
		padding: 0 $base-unit $base-unit;
	}
}
