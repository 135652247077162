/*
Elevation styles
This component uses bootstrap .border utility but overrides it for .inverse version so there must be used !important :(
*/


// Hover on element just with bg-* color
[class*='bg-'] {
	&.hover:hover {
		@include elevation-box-shadow;
	}

	&.hover-m:hover {
		@include elevation-box-shadow($size:"m");
	}

	&.hover-s:hover {
		@include elevation-box-shadow($size:"s");
	}
}


// Flat - has border only on mobiles
.flat {
	border: $elevation-border !important;

	@include media-breakpoint-up(lg) {
		border: $elevation-border-transparent !important;

		&.hover:hover {
			border: $elevation-border !important;
		}
	}

	.inverse & {
		border: $elevation-border-inverse !important;

		@include media-breakpoint-up(lg) {
			border: $elevation-border-transparent !important;

			&.hover:hover {
				border: $elevation-border-inverse !important;
			}
		}
	}
}


// Border
.border {
	border: $elevation-border;

	&.elevation-orange {
		border-color: #FFDDBF !important;
	}

	&.elevation-gray07 {
		border-color: #cfd5d9 !important;
	}

	&[class*='hover-']:hover,
	&.hover:hover {
		background-color: $white !important;
	}

	&[class*='bg-'].hover:hover {
		@include elevation-box-shadow;
	}

	&[class*='bg-'].hover-m:hover {
		@include elevation-box-shadow($size:"m");
	}

	&[class*='bg-'].hover-s:hover {
		@include elevation-box-shadow($size:"s");
	}

	.inverse & {
		border: $elevation-border-inverse !important;

		//on inverse only L should be used
		&.hover:hover {
			background-color: $blue-deep !important;
		}
	}
}


// Shadow - three sizes of shadow, two values of alpha. Always with color background (defined in mixin).
.shadow {
	&,
	&-l {
		@include elevation-box-shadow;

		&.hover:hover { @include elevation-box-shadow(2); }
	}

	&-m {
		@include elevation-box-shadow(1,"m");

		&.hover:hover { @include elevation-box-shadow(2,"m"); }
	}

	&-s {
		@include elevation-box-shadow(1,"s");

		&.hover:hover { @include elevation-box-shadow(2,"s"); }
	}

	&2,
	&-l2 { @include elevation-box-shadow(2); }

	&-m2 { @include elevation-box-shadow(2,"m"); }

	&-s2 { @include elevation-box-shadow(2,"s"); }
}
