.ds-header {
	position: relative;
	z-index: map-get($z-index, ds-header);

	display: flex;
	justify-content: space-between;
	padding: ($unit * 1.125) ($unit * 1.25);
	background: $white;
	box-shadow: 0 ($unit/16) 0 rgba($blue-dark, .1);

	//	@desktop only
	@include media-breakpoint-up(lg) {
		padding: ($unit * 1.875) ($unit * 2.5);
	}


	&__left {
		display: flex;
	}

	&__right {
		//...
	}


	//
	//	nested components - local overrides
	//
	.ds-logo {
		//...

		//	@desktop only
		@include media-breakpoint-up(lg) {
			margin-right: $unit * 3.5;
		}
	}
}
