.message-bar {
	position: relative;
	z-index: map-get($z-index, cmp-message-bar);
	padding: $base-unit 20px;
	overflow: hidden;

	@include media-breakpoint-only(lg) {
		padding-top: $base-unit/2;
		padding-bottom: $base-unit/2;
	}

	&.theme-dark { background: $blue-dark; }
	&.theme-light { background: $orange-faint; }
	&.theme-white {
		background: $white;

		img[class*='bar-icon-']:not(.nofilter) {
			filter: $filter-gray03;
		}
	}

	&.sticky-message {
		position: sticky;
		top: 0;
		transition: all 150ms ease-out;

		&.hidden {
			top:-100px
		}
	}
	

	&.monochrome {
		.btn-secondary {
			@include btn-secondary($btn-theme-monochrome);
		}
	}

	&.with-link {
		&:not(.with-button) .bar-text { text-decoration: underline; }

		&:hover,
		&:active,
		&:focus {
			.bar-text { text-decoration: none; }
			&.theme-dark { background: $blue-deeper; }
			&.theme-light { background: $orange-off-white; }
			&.bg-red { background: linear-gradient(0deg, rgba(7, 29, 43, 0.16) 0%, rgba(7, 29, 43, 0.16) 100%), #D93511; }
			&.theme-white { background: $gray10; }
			&.btn-sm:before {
				height: calc(100% + (#{$base-unit}/4));
				width: calc(100% + (#{$base-unit}/4));
				border-radius: 22px;
			}

			&:not(.monochrome) {
				/* BUTTON HOVER STATE */
				.btn:not(:disabled):not(.disabled) {
					&.btn-primary {
						@include btn-hover-primary ($btn-theme-primary);
					}

					&.btn-secondary {
						@include btn-hover-secondary ($btn-theme-secondary);
					}
				}

				&.inverse {
					.btn:not(:disabled):not(.disabled) {
						&.btn-secondary {
							@include btn-hover-secondary ($btn-theme-secondary, $inverse:true);
						}
					}
				}
			}

			&.monochrome {
				/* BUTTON HOVER STATE */
				.btn:not(:disabled):not(.disabled) {
					&.btn-secondary {
						@include btn-hover-secondary ($btn-theme-monochrome);
					}
				}
			}
		}
	}

	.bar-content,
	.counter-wrapper {
		min-height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
	}

	&.retention .bar-image-wrap {
		position: absolute;
		left: 20px;
		
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.counter-wrapper {
		gap: 0 $base-unit*3;

		
		.btn-wrapper {
			margin-left: 0;
		}

		@include media-breakpoint-down(xs) {
			flex-direction: column;
			align-items: flex-start;
			gap: $base-unit 0;
		}
	}


	.bar-text {
		margin: 0;
		
		@include media-breakpoint-only(lg) {
			font-size: $h7-font-size;
			line-height: $h7-line-height;
		}
	}


	img[class*='bar-icon-'] {
		height: $base-unit*3;

		&.bar-icon-left {
			margin-right: $base-unit/2;
		}

		&.bar-icon-right {
			margin-left: $base-unit/2;
		}

		@include media-breakpoint-up(lg) {
			&.bar-icon-left { margin-right: $base-unit*1.5; }
			&.bar-icon-right { margin-left: $base-unit*1.5; }
		}
	}


	
	// Icon filter by themes (bg colors)
	img[class*='bar-icon-']:not(.nofilter) {
		filter: $filter-orange-bright;
	}

	&.bg-red {
		img[class*='bar-icon-']:not(.nofilter) {
			filter: $filter-white;
		}
	}
	// -----



	.bar-image {
		margin-right: $base-unit/2;
		max-width: $base-unit*6;
		height: auto;

		@include media-breakpoint-up(lg) {
			margin-right: $base-unit*1.5;
			max-width: none;
		}
	}

	.btn-wrapper {
		margin-left: $base-unit/2;

		@include media-breakpoint-up(lg) {
			margin-left: $base-unit*1.5;
		}
	}

	// GLOWEB-5448 RUBY users limitation
	&#ru-message-bar {
		display: none;
	}

	//GLOWEB-5390 large icon
	&-cookies-cmp {
		.bar-content {
			img {
				@include media-breakpoint-down(sm) {
					width: 50%;
					height: auto;
				}
			}
		}
	}
}

.cmp-countdown {
	display: flex;

	.time-block {
		width: 40px;
	}

	.colon {
		width: 7px;
	}

	.time-block,
	.colon {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.numbers {
		font-size: 18px;
		line-height: 18px;
		font-weight: 700;
	
		@include media-breakpoint-up(lg) {
			font-size: 24px;
			line-height: 24px;
		}
	}
}
