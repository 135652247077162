.product-tile {
	padding: $base-unit*1.5;
	text-align: center;
	position: relative;
	height: 100%;

	@include border-radius(0 $base-unit*1.5 $base-unit*1.5 $base-unit*1.5);
	@include media-breakpoint-up(md) {
		padding: $base-unit*2;
		display: flex;
		flex-direction: column;
	}

	&-icon {
		.product-icon.medium.box {
			img {
				width: $base-unit*2;
				height: $base-unit*2;
				margin: $base-unit*.5;

				@include media-breakpoint-up(md) {
					width: $base-unit*3;
					height: $base-unit*3;
					margin: $base-unit*.75;
				}
			}
		}
	}

	&-name {
		margin: $base-unit*.5 0 0 0;

		@include media-breakpoint-up(md) {
			margin: $base-unit 0 0 0;
		}
	}

	&-platforms {
		margin-top: $base-unit*.5;
		display: flex;
		align-items: center;
		justify-content: center;

		.icon {
			margin: 0 $base-unit*.5;

			.inverse & {
				filter: $filter-gray06;
			}
		}
	}

	&-description {
		margin-top: $base-unit;

		@include media-breakpoint-up(md) {
			margin-top: $base-unit*1.5;
			margin-bottom: $base-unit*1.5;
		}
	}

	&-button {
		margin-top: $base-unit;

		@include media-breakpoint-up(md) {
			margin-top: auto;
		}
	}

	&-label {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);

		.badge-primary {
			.inverse & {
				background-color: $orange-pale;
				color: $blue-dark;
			}
		}
	}
}


// clickable tile
a.product-tile {
	display: block;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		&,
		&.light {
			.btn:not(:disabled):not(.disabled) {
				&.btn-sm:before {
					height: calc(100% + (#{$base-unit}/4));
					width: calc(100% + (#{$base-unit}/4));
					border-radius: 22px;
				}

				&.btn-link {
					@include btn-link-hover-primary ($btn-theme-secondary);
				}
			}
		}

		.inverse & {
			.btn:not(:disabled):not(.disabled) {
				&.btn-link {
					@include btn-link-hover-primary ($btn-theme-secondary, $inverse:true);
				}
			}
		}
	}
}