.sticky-bar {
	background: $white;
	padding: ($base-unit * .75) 0;
	transform: translateY(-200%);
	transition: all 150ms ease-out;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;

	@include elevation-box-shadow;

	.container {
		max-width: 100%;
		padding-left: $base-unit*2.5;
		padding-right: $base-unit*2.5;

		@include media-breakpoint-down(md) {
			padding-left: $base-unit*1.25;
			padding-right: $base-unit*1.25;
		}
	}

	&.is-sticky {
		display: block;
		position: fixed;
		z-index: map-get($z-index, cmp-sticky-bar);
		transform: translateY(0);
	}

	&.no-logo {
		.lp-avast-logo {
			display: none !important;
		}
	}

	&-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__right {
		display: flex;
		align-items: center;
	}

	.logo-erb {
		display: flex;
		align-items: center;

		// product name
		h6 {
			display: inline-block;

			@include media-breakpoint-down(sm) { display: none; }
		}

		img {
			margin-right: $base-unit/2;
			height: 27px;

			@include media-breakpoint-up(lg) {
				height: 36px;
			}
		}

		.product-icon { margin-right: $base-unit; }
	}

	// there are various name for class on this element
	.product-awards,
	.sticky-awards {
		display: none;

		@include media-breakpoint-up(lg) {
			display: flex;
			align-items: center;
		}

		span {
			margin-left: $base-unit;

			@extend .caption;
		}

		&+.btn-wrapper {
			margin-left: $base-unit*1.5;
		}
	}

	.product-awards__disclaimer {
		margin-left: 1rem;
		font-size: .8125rem;
		line-height: 1rem;
		color: $gray03;
	}

	// there are various name for class on this element
	.dot,
	.sticky-dot {
		display: none;
		margin: 0 $base-unit*1.5;
		font-size: $base-unit*1.125;

		@include media-breakpoint-up(lg) { display: block; }
	}

	// there are various name for class on this element
	.get-free,
	p.get-free,
	.sticky-text {
		@extend .h7;
		margin: 0 $base-unit/2 0 0;
		//font-weight: 800;

		@include media-breakpoint-up(lg) {
			margin: 0 $base-unit*1.5 0 0;
		}
	}


	// Maybe not required wrapper
	.buttons {
		display: flex;
		align-items: center;
	}

	.or {
		display: none;
		margin: 0 $base-unit;

		@include media-breakpoint-up(lg) { display: inline-block; }
	}

	@include media-breakpoint-down(md) {
		.btn:not(.btn-stay) {display: none;}

		// Keep the button primary, no matter what.
		.btn-stay {
			@include btn-primary($btn-theme-primary);
		}
	}
}
