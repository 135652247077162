.money-back {
	display: flex;
	align-items: center;

	&__item {
		display: inline-flex;
		align-items: center;
		text-align: left; // to keep it left on mobile

		span {
			flex: 1 1 auto;
		}

		.icon {
			margin-right: 10px;

			.inverse & {
				filter: $filter-white;
			}

			&,
			.light & {
				filter: $filter-gray03;
			}

			@include media-breakpoint-down(md) {
				@include sprite-image('icons', 'money-back', 24);
			}
		}
	}

	&.money-back--center {
		justify-content: center;
	}
}
