/*
Carousel Component

NOTE:
Width of .tiny-slider is calculated from sum of active items and their margins.
This sizes must be aligned with item sizes in settings (in js file).
*/


.carousel-slider {
	overflow-x: hidden;
	padding: $base-unit*4 0 $base-unit*10;

	@media (max-width: 1279px) { padding: $base-unit*3 0 $base-unit*6; }

	.tns-slider { display: flex; }


	// Buttons / Controls
	//------------------------

	.slider-controls {
		button {
			position: absolute;
			z-index: 10;
			display: none;

			img { width: auto!important; }

			.btn-icon-normal,
			.btn-icon-hover {
				margin: 0 auto;
			}
		}

		&:focus {
			button { outline: 2px solid $gray03; }
		}

		@media (min-width: 1280px) {
			button { display: block; }

			.prev {
				right: -$base-unit*17.75;
				top: $base-unit*7.5;
			}

			.next {
				right: -$base-unit*17.75;
				top: $base-unit*3.5;
			}
		}
		@media (min-width: 1366px) {
			.prev,
			.next { right: -$base-unit*3.5; }
		}

		@include media-breakpoint-up(xl) {
			.prev { top: $base-unit*5.5; }

			.next {
				right: -$base-unit*8;
				top: $base-unit*5.5;
			}
		}
	}

	&.infinity,
	&.single {
		@media (min-width: 1280px) {
			button {
				display: block;
				top: calc(50% - 24px)!important;
			}

			.prev { left: -$base-unit*6; }

			.next { right: -$base-unit*6; }
		}
	}


	// Dots / Navigation
	//------------------------

	.tns-nav {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		padding: 0 $base-unit/2;
		bottom: -$base-unit*3;
		left: 0;
		right: 0;
		z-index: 9;

		button {
			height: $base-unit*.375;
			width: $base-unit*.375;
			min-width: $base-unit*.375;
			border-radius: 50%;
			background-color: rgba($blue-bright, .4);
			padding: 0;
			border: 0;

			&:not(:first-of-type) {
				margin-left: $base-unit*1.5;

				@include media-breakpoint-down(sm) {margin-left: $base-unit;}
			}

			&:focus {
				outline: 1px solid $blue-bright;
			}

			&.tns-nav-active {
				height: $base-unit*.5;
				width: $base-unit*.5;
				min-width: $base-unit*.5;
				background-color: $blue-bright;
			}

			.inverse & {
				background-color: $gray03;
				
				&.tns-nav-active {  background-color: $orange-bright; }
			}
		}
	}


	// Carousel tiny slider styling
	//-----------------------------

	.tiny-slider {
		position: relative;
		margin: 0 auto;

		.tns-item {
			transition-duration: .4s;
			transition-property: opacity;
			transition-timing-function: ease;
			display: inline-flex;
			height: auto;
		}

		.tns-inner { position: relative; }

		@include media-breakpoint-up(lg) {
			.tns-item:not(.tns-slide-active) {
				opacity: .3;
			}
		}
	}

	&.overflow-visible {
		.tns-ovh { overflow: visible; }
	}


	// Slider Variant / Blog Articles
	//----------------------------------

	&.slider {
		.tiny-slider {
			position: relative;

			//item margins:
			.tns-item {
				@media (max-width: 1279px) { margin-right: $base-unit; }
			}

			@include media-breakpoint-up(lg) {
				margin-left: -$base-unit*2;
				margin-right: -$base-unit*2;
				max-width: $base-unit*69;
			}

			@media (min-width: 1280px) { max-width: $base-unit*59.25; }
			@media (min-width: 1366px) { max-width: $base-unit*79; }
		}

		@include media-breakpoint-only(lg) {
			.tns-inner:after { display: none; }

			.tns-inner:before { background-color: $white; }
		}
	}


	// Sizes of Infintiy wrapper
	// Wrapper has 3 sizes: large, medium and small
	//-------------------

	&.infinity {
		padding: $base-unit*4.25 0 $base-unit*8.5;

		@media (max-width: 1279px) { padding: $base-unit*4 0 $base-unit*7; }

		.tiny-slider {
			@include media-breakpoint-up(md) { max-width: $base-unit*38; }

			//item margins:
			.tns-item {
				margin-right: $base-unit;

				@media (min-width: 1280px) { margin-right: $base-unit*2; }
			}
		}

		&.large .tiny-slider {
			.tns-item { @media (min-width: 1280px) and (max-width: 1599.98px) { margin-right: $base-unit*1.5; }}

			@media (min-width: 1280px) { max-width: $base-unit*62.25; }
			@media (min-width: 1366px) { max-width: $base-unit*66.5; }

			@include media-breakpoint-up(xl) { max-width: $base-unit*70.5; }
		}

		&.medium .tiny-slider {
			@media (min-width: 1280px) { max-width: $base-unit*63.25; }
		}

		&.small .tiny-slider {
			.tns-item { @media (min-width: 1280px) and (max-width: 1599.98px) { margin-right: $base-unit*1.5; }}

			@media (min-width: 1280px) { max-width: $base-unit*49.5; }
			@media (min-width: 1366px) { max-width: $base-unit*53; }

			@include media-breakpoint-up(xl) { max-width: $base-unit*56; }
		}
	}


	// Single variant (https://zpl.io/bzEGnz4)
	//----------------------------------------

	&.single.large {
		padding: $base-unit*5 0 $base-unit*10;

		@media (max-width: 1279px) { padding: $base-unit*5 0 $base-unit*8; }

		.tiny-slider {
			.single-image-slide { width: 100%; }

			//item margins:
			.tns-item {
				margin: 0 $base-unit*.75;

				@include media-breakpoint-up(xl) { margin: 0 $base-unit; }
			}

			max-width: $base-unit*21.5;

			@include media-breakpoint-up(md) { max-width: $base-unit*33.25; }
			@include media-breakpoint-up(lg) { max-width: $base-unit*39.25; }

			@media (min-width: 1280px) { max-width: $base-unit*49.5; }
			@media (min-width: 1366px) { max-width: $base-unit*54; }

			@include media-breakpoint-up(xl) { max-width: $base-unit*58; }

			.tns-item:not(.tns-slide-active) {
				opacity: .3;
			}
		}
	}


	// Gradients
	//-------------------

	&.infinity,
	&.slider,
	&.single {
		@include media-breakpoint-up(lg) {
			.tns-inner:before {
				display: block;
				position: absolute;
				content: '';
				width: 1000px;
				height: 107%;
				left: -1016px;
				background-image: linear-gradient(to left, rgba($white, 0) 0%, $white 248px);
				z-index: 3;
				top: -7%;
			}
	
			.tns-inner:after {
				display: block;
				position: absolute;
				content: '';
				width: 1000px;
				height: 107%;
				right: -1000px;
				background-image: linear-gradient(to right, rgba($white, 0) 0%, $white 248px);
				z-index: 3;
				top: -7%;
			}

			.inverse & {
				.tns-inner:before { background-image: linear-gradient(to left, rgba($blue-dark, 0) 0%, $blue-dark 248px); }

				.tns-inner:after { background-image: linear-gradient(to right, rgba($blue-dark, 0) 0%, $blue-dark 248px); }
			}
		}

		@media (min-width: 1280px) {
			.tns-inner:before { left: -1024px; }
		}

		// gradients for other than white backgrounds (based on maps in variables)
		@each $color, $value in $colors-map {
			&.bg-#{$color} {
				.tns-inner:before {
					background-image: linear-gradient(to left, rgba($value, 0) 0%, $value 248px);
					
					@include media-breakpoint-only(lg) { background-color: $value; }
				}

				.tns-inner:after { background-image: linear-gradient(to right, rgba($value, 0) 0%, $value 248px); }
			}
		}
		@each $color, $value in $grays-map {
			&.bg-#{$color} {
				.tns-inner:before {
					background-image: linear-gradient(to left, rgba($value, 0) 0%, $value 248px);
					
					@include media-breakpoint-only(lg) { background-color: $value; }
				}
				
				.tns-inner:after { background-image: linear-gradient(to right, rgba($value, 0) 0%, $value 248px); }
			}
		}
	}
}// end of carousel