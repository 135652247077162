/*********************
BUTTON component

Content:
- button medium
- button large
- button small

- color themes assignment

- button link primary
- button link secondary

- button hint
************************/

.btn-wrapper {
	display: inline-block;
	vertical-align: top;
	max-width: 100%; //IE fix so buttons won't overlap container

	&.with-badge {
		position: relative;

		.badge {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

/**************
 STYLES BY SIZE
**************/

/* DEFAULT SIZE (MIDDLE) */
.btn {
	position: relative;
	font-size: $btn-font-size;
	text-transform: uppercase;
	font-weight: 800;
	letter-spacing: $btn-letter-spacing;
	padding: 0 $btn-padding-x;
	display: inline-flex;
	align-items: center;
	height: $btn-height;
	z-index: 0;
	border-width: 0;

	&:before {
		content: "";
		position: absolute;
		height: 100%;
		width: 100%;
		border-radius: $btn-border-radius;
		z-index: -1;
		transition: all ease-out .2s;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	/* generic icon styles */
	.btn-icon-hover {
		display: none;
	}

	img {
		height: $btn-icon-height;
	}

	// Disabled comes first so active can properly restyle
	&.disabled {
		cursor: initial;
	}

	&.disabled:before,
	&:disabled:before {
		display: none;
		pointer-events: none;
		border-radius: $btn-border-radius;
	}

	&:hover,
	&.active {
		&:before {
			height: calc(100% + (#{$base-unit}/4));
			width: calc(100% + (#{$base-unit}/4));
			border-radius: 30px;
		}

		.btn-icon-hover {
			display: inline;
		}

		.btn-icon-normal {
			display: none;
		}
	}

	&.btn-icon-left {
		.btn-icon {
			margin-left: 0;
			margin-right: $btn-icon-padding-text;
		}

		span {
			text-align: left;
		}
	}

	&.btn-icon-right {
		.btn-icon {
			margin-left: $btn-icon-padding-text;
			margin-right: 0;
		}

		span {
			text-align: left;
			width: 100%;
		}
	}

	&.btn-icon-middle {
		.btn-icon {
			margin-right: 0;
			margin-left: 0;
			height: $btn-icon-middle-height;
		}
	}

	@include media-breakpoint-down(md) {
		font-size: $base-unit * .75; //12px;
		line-height: $btn-span-line-height-sm;
		padding: 0 $btn-padding-x-sm;
		height: $btn-height-sm;

		img {
			height: $btn-icon-height-sm;
		}

		&.btn-icon-left .btn-icon {
			margin-left: 0;
			margin-right: $btn-icon-padding-text-sm;
		}

		&.btn-icon-right .btn-icon {
			margin-left: $btn-icon-padding-text-sm;
			margin-right: 0;
		}

		&.btn-icon-middle .btn-icon { height: 16px; }
	}
}


/* LARGE SIZE */
.btn-lg {
	font-size: $btn-font-size-lg;
	letter-spacing: $btn-letter-spacing-lg;
	padding: 0 $btn-padding-x-lg;
	height: $btn-height-lg;

	&:before {
		border-radius: $btn-border-radius-lg;
	}

	img {
		height: $btn-icon-height-lg;
	}

	&:hover,
	&.active {
		&:before {
			height: calc(100% + (#{$base-unit}/2));
			width: calc(100% + (#{$base-unit}/2));
			border-radius: 40px;
		}
	}

	&.btn-icon-left {
		.btn-icon {
			margin-left: 0;
			margin-right: $btn-icon-padding-text-lg;
		}
	}

	&.btn-icon-right {
		.btn-icon {
			margin-left: $btn-icon-padding-text-lg;
			margin-right: 0;
		}
	}

	@include media-breakpoint-down(md) {
		font-size: $base-unit * .875; //14px
		line-height: $btn-span-line-height;
		padding: 0 $btn-padding-x;
		height: $btn-height;

		img {
			height: $btn-icon-height;
		}

		&.btn-icon-left .btn-icon {
			margin-left: 0;
			margin-right: $btn-icon-padding-text;
		}

		&.btn-icon-right .btn-icon {
			margin-left: $btn-icon-padding-text;
			margin-right: 0;
		}
	}
}


/* SMALL SIZE */
.btn-sm {
	font-size: $btn-font-size-sm;
	letter-spacing: $btn-letter-spacing-sm;
	padding: 0 $btn-padding-x-sm;
	height: $btn-height-sm;

	&:before {
		border-radius: $btn-border-radius-sm;
	}

	img { height: $btn-icon-height-sm; }

	&:hover,
	&.active {
		&:before {
			border-radius: 22px;
		}
	}

	&.btn-icon-left {
		.btn-icon {
			margin-left: 0;
			margin-right: $btn-icon-padding-text-sm;
		}
	}

	&.btn-icon-right {
		.btn-icon {
			margin-left: $btn-icon-padding-text-sm;
			margin-right: 0;
		}
	}

	@include media-breakpoint-down(md) {
		font-size: $base-unit * .625; //10px
		line-height: $btn-span-line-height-xs;
		padding: 0 $btn-padding-x-xs;
		height: $btn-height-xs;

		&:before {
			border-width: $btn-border-width-sm;
		}

		img {
			height: $btn-icon-height-xs;
		}

		&.btn-icon-left .btn-icon {
			margin-left: 0;
			margin-right: $btn-icon-padding-text-xs;
		}

		&.btn-icon-right .btn-icon {
			margin-left: $btn-icon-padding-text-xs;
			margin-right: 0;
		}
	}
}


/***********************
 COLOR THEMES ASSIGNMENT
***********************/

.btn-primary {
	@include btn-primary($btn-theme-primary);
}

.btn-secondary {
	@include btn-secondary($btn-theme-secondary);
}

.btn-secondary.monochrome {
	@include btn-secondary($btn-theme-monochrome);
}


/***********
 BUTTON LINK
***********/
// Make a button look and behave like a link
$theme-values: map-get($btn-themes, $btn-theme-secondary);

/* BUTTON LINK PRIMARY */
.btn-link {
	border: none;
	padding: 0;
	transition: all ease-out .2s;
	height: $base-unit;

	span {
		line-height: $base-unit;
	}

	&.btn-icon-left .btn-icon { margin-left: 0; }

	&.btn-icon-right .btn-icon { margin-right: 0; }

	&:before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		padding: 20px;
		//display: none;
	}

	&:disabled,
	&.disabled {
		opacity: .8;
		pointer-events: none;
		text-decoration: none;
		box-shadow: none;

		&:before { display: none; }
	}

	&:not(:disabled):not(.disabled) {
		&:focus,
		&.focus {
			text-decoration: none;
			box-shadow: none;
		}
	}

	.inverse & {
		color: $gray06;

		.btn-icon { filter: $filter-orange-bright;}

		&:not(:disabled):not(.disabled) {
			&:focus,
			&.focus {
				color: $white;
				outline: 3px solid $white;
			}

			&:hover,
			&:active,
			&.active,
			&:hover:focus,
			&:active:focus {
				@include btn-link-hover-primary ($btn-theme-secondary, true);
			}
		}
	}

	&,
	.light & {
		color: $blue-dark;

		.btn-icon { filter: map-get($theme-values, "filter-color");}

		&:not(:disabled):not(.disabled) {
			&:focus,
			&.focus {
				color: $blue-dark;
				outline: 3px solid $gray03;
			}

			&:hover,
			&:active,
			&.active,
			&:hover:focus,
			&:active:focus {
				@include btn-link-hover-primary ($btn-theme-secondary);
			}
		}
	}
}

/* BUTTON LINK SECONDARY */
.btn-link-secondary {
	display: inline;
	padding: 0;
	transition: all ease-out .2s;
	height: $base-unit;
	border: none;

	&:before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		padding: 20px;
	}

	span {
		line-height: $base-unit;
	}

	.inverse & {
		color: $gray06;

		span { border-bottom-color: $white; }

		&:disabled,
		&.disabled {
			opacity: .8;
			pointer-events: none;

			&:before { display: none; }
		}

		&:not(:disabled):not(.disabled) {
			&:focus,
			&.focus {
				color: $white;
				box-shadow: none;
				outline: 3px solid $white;
			}

			&:hover,
			&:active,
			&.active,
			&:hover:focus,
			&:active:focus {
				@include btn-link-hover-secondary ($btn-theme-secondary,true);
			}
		}
	}

	&,
	.light & {
		color: $blue-dark;

		span {
			border-bottom: 1px solid $blue-dark;
		}

		&:disabled,
		&.disabled {
			opacity: .6;
			pointer-events: none;
		}

		&:not(:disabled):not(.disabled) {
			&:focus,
			&.focus {
				color: $blue-dark;
				box-shadow: none;
				outline: 3px solid $gray03;
			}

			&:hover,
			&:active,
			&.active,
			&:hover:focus,
			&:active:focus {
				@include btn-link-hover-secondary ($btn-theme-secondary);
			}
		}
	}
}


/***********
 BUTTON HINT
***********/
.btn-hint {
	display: block;
	font-size: 14px;
	line-height: $body-md-line-height;
	font-weight: 400;
	margin-top: $base-unit * 2;
	text-align: center;

	a {
		font-weight: 700;
		//border-bottom: 1px solid;
		//border-bottom-color: $link-color;

		/* &:hover {
			border-bottom: 0;
		} */

		/* .inverse & {
			border-bottom-color: $link-primary-color-light;
		}

		.inverse .light & {
			border-bottom-color: $link-color;
		} */
	}

	@include media-breakpoint-up(lg) {
		font-size: $body-md-font-size;
	}
}
