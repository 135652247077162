/* Testimonial */

// used with grid
.testimonial {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: $base-unit*2;

	@include media-breakpoint-up(lg) {
		padding-bottom: $base-unit*2.5;
	}

	// itemprop data
	[itemprop="itemReviewed"] {
		display: none;
	}

	// message
	.message {
		flex: 0 1 auto;

		// image on the top of the card / optional
		.img {
			text-align: center;
			padding: 0 0 $base-unit*1.5;

			@include media-breakpoint-up(lg) {
				padding-bottom: $base-unit*2.25;
			}

			img { margin: 0 auto; }
		}

		// main text / review / testimonal
		.text {
			position: relative;
			padding-top: $base-unit*2.5;

			@include media-breakpoint-up(lg) {
				padding-top: $base-unit*3;
			}

			.quotation-icon {
				position: absolute;
				top: 0;
				left: 0;
				filter: $filter-orange-bright;
			}
		}

		// link below text / optional
		.link {
			display: block;
			margin-bottom: $base-unit*1.5;

			@include media-breakpoint-up(lg) {
				margin-bottom: $base-unit*2;
			}

			a {
				text-decoration: underline;
				text-transform: uppercase;
				font-weight: bold;
			}
		}
	}

	// details
	.details {
		display: flex;

		// on smaller desktops put flag above personal details to save space
		@media (min-width: 992px) and (max-width: 1279.98px) {
			flex-direction: column;
		}
	}

	// region flag
	.region {
		a,
		div {
			display: block;
			width: $base-unit*3; // must be specified to reflect flag transformation
			height: $base-unit*3; // must be specified to reflect flag transformation

			&:before {
				background-color: transparent;
				background-image: url('../../o/i/flags/flag-language-selector.svg');
				background-repeat: no-repeat;
				content: "";
				display: inline-block;
				vertical-align: middle;
				height: $base-unit*1.5;
				width: $base-unit*1.5;
				transform: scale(1.5);
				transform-origin: left top;
			}

			@include media-breakpoint-up(lg) {
				width: $base-unit*4;
				height: $base-unit*4;

				&:before {
					transform: scale(2);
				}
			}
		}
	}


	// alternate image position on left
	&.img-left {
		.details {
			padding-left: $base-unit*4;
		}

		.message .img {
			position: absolute;
			padding: 0;
			bottom: 0;
			left: 0;
			width: $base-unit*3;
			height: $base-unit*3;
			margin: 0 $base-unit $base-unit*2.25 $base-unit*2;

			img {
				width: 100%;
				border-radius: 50%;
			}
		}


		@include media-breakpoint-up(lg) {
			padding-left: $base-unit*14;

			.details { padding-left: 0; }

			.message .img {
				top: 0;
				width: $base-unit*10;
				height: $base-unit*10;
				margin: $base-unit*2;
			}
		}
	}

	// personal data
	.person {
		position: relative;
		margin-bottom: $base-unit/2;

		.personal-info {
			margin-bottom: 0;

			.note {
				display: block;
				margin-bottom: 0;
				line-height: 1.3rem;
			}

			.author {
				display: block;
				margin-bottom: 0;
				line-height: $base-unit*1.5;
				font-weight: 800;
			}

		}
	}

	// company
	.info {
		padding-top: $base-unit/2;
		margin-bottom: 0;
	}
}
