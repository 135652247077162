//
// ACCORDION
//
$theme-values: map-get($btn-themes, $btn-theme-secondary);
$theme-values-inverse: map-get($btn-themes, $btn-theme-monochrome);

.accordion-holder {
	.accordion-panel {
		position: relative;
		display: flex;
		flex-direction: column;
		transition: box-shadow ease-in .1s;

		&:first-child {
			border-radius: 0 $base-unit*1.5 0 0;
			border-bottom: $accordion-border;
		}

		&:last-child {
			border-radius: 0 0 $base-unit*1.5 $base-unit*1.5;
		}

		.panel-header-toggler {
			text-decoration: none;
			padding: $base-unit;

			.accordion-title-icon {
				position: absolute;
				left: $base-unit;
				margin-top: 3px;
				filter: $accordion-icon-color;

				@include media-breakpoint-up(lg) {
					left: $base-unit*2;
					margin-top: 0;
				}
			}

			&:focus {
				outline-offset: 0;
				outline: none;

				h3.h6,
				h6 { color: $accordion-header-color-hover; }

				.accordion-title-icon { filter: $accordion-icon-color-hover; }
			}

			@include media-breakpoint-up(lg) {
				padding: $base-unit*2.25;
			}

			// Accordions toggler
			.button-circle {
				position: absolute;
				right: $base-unit;
				top: $base-unit;
				transition: all ease-in .1s;

				@include media-breakpoint-up(lg) {
					right: $base-unit*2;
					top: $base-unit*2;
				}

				img,
				.icon {
					transition: all .2s;
					// issue with rotation and position (FIXME):
					position: relative;
					top: 0;
					left: 0;
					transform: none;

					// spceific icons for hover and not collapsed states. Fixes IE11 issue.
					&.btn-icon-normal { display: block; }
					&.btn-icon-hover { display: none; }
				}
			}

			&:not(.collapsed) {
				h3.h6,
				h6 { color: $accordion-header-color-hover; }

				.button-circle {
					background-color: map-get($theme-values, "background-color-hover");

					img,
					.icon {
						filter: map-get($theme-values, "filter-color-hover");
						transform: rotate(45deg) scale(1.1);

						// specific icons for hover and not collapsed states. Fixes IE11 issue.
						&.btn-icon-normal { display: none; }
						&.btn-icon-hover { display: block;}
					}
				}

				&:hover .button-circle {
					box-shadow: map-get($theme-values, "circle-shadow-hover1"), map-get($theme-values, "circle-shadow-hover2");

					img,
					.icon {
						// specific icons for hover and not collapsed states. Fixes IE11 issue.
						&.btn-icon-normal { display: none; }
						&.btn-icon-hover { display: block;}
					}
				}

				.accordion-title-icon { filter: $accordion-icon-color-hover; }
			}

			&.collapsed:hover {
				h3.h6,
				h6 { color: $accordion-header-color-hover; }

				.button-circle {
					background-color: map-get($theme-values, "background-color-hover");
					box-shadow: map-get($theme-values, "circle-shadow-hover1"), map-get($theme-values, "circle-shadow-hover2");

					img,
					.icon {
						filter: map-get($theme-values, "filter-color-hover");
						// spceific icons for hover and not collapsed states. Fixes IE11 issue.
						&.btn-icon-normal { display: none; }
						&.btn-icon-hover { display: block;}
					}
				}

				.accordion-title-icon { filter: $accordion-icon-color-hover; }
			}
		}

		.panel-preface,
		.panel-body {
			font-size: $body-md-font-size-m;
			line-height: $body-md-line-height-m;

			@include media-breakpoint-up(lg) {
				font-size: $body-md-font-size;
				line-height: $body-md-line-height;
			}
		}

		.panel-header {
			border-bottom-width: 0;

			h3.h6,
			h6 {
				padding: 6px $base-unit*4.25 6px 0;
				margin-bottom: 0;

				@include media-breakpoint-up(lg) {
					padding: 0 $base-unit*5.5 0 0;
				}
			}

			+ .list-group .list-group-item:first-child {
				border-top: 1px;
			}

			&:hover {
				h3.h6,
				h6 { color: $accordion-header-color-hover; }
			}
		}

		.panel-body {
			color: $text-muted;
			word-break: break-word;
			padding-bottom: $base-unit;

			@include media-breakpoint-up(lg) {
				padding-bottom: $base-unit*2;
			}

			&:not(.panel-body-full-width) {
				padding-left: $base-unit;
				padding-right: $base-unit;

				@include media-breakpoint-up(lg) {
					padding-left: $base-unit*2.25;
					padding-right: $base-unit*2.25;
				}
			}

			.feature-item__title {
				color: initial;
			}
		}

		&:not(:first-of-type) {
			margin-top: -1px;
		}

		&:hover {
			z-index: 4;
		}
	}

	//Link under Accordion
	.accordion-bottom-link {
		margin-top: $base-unit*2;
		text-align: center;

		@include media-breakpoint-up(lg) {
			margin-top: $base-unit*2.5;
		}
	}


	/***************
	Primary Variant
	***************/
	&.accordion-spaces .accordion-panel {
		@include radius($base-unit*1.5);
	}

	&.accordion-icon .accordion-panel,
	.accordion-panel.accordion-icon {
		.panel-header-toggler {
			padding-left: $base-unit*3;
			padding-right: $base-unit*4;

			@include media-breakpoint-up(lg) {
				padding: $base-unit*2 $base-unit*5.5 $base-unit*2.25 $base-unit*5;
			}
		}

		.panel-header h3.h6,
		.panel-header h6 {
			@include media-breakpoint-down(md) {
				padding-top: 4px;
			}
		}

		.panel-preface {
			padding: 0 $base-unit $base-unit*1.5;
			color: $text-muted;

			@include media-breakpoint-up(lg) {
				margin: -$base-unit*.5 0 0;
				padding: 0 $base-unit*5.5 $base-unit*2.25 $base-unit*5;
			}
		}

		.panel-body {
			padding-top: $base-unit;

			&:not(.panel-body-full-width) {
				@include media-breakpoint-up(lg) {
					padding-right: $base-unit*5.5;
					padding-left: $base-unit*5;
				}
			}
		}
	}

	&.accordion-spaces .accordion-panel:not(:last-child),
	.accordion-panel.accordion-spaces:not(:last-child) {
		margin-bottom: $base-unit*1.5;
	}

	/***************
	Primary Variant with illustration
	***************/
	.illustration {
		align-items: flex-start;

		&-wrapper {
			display: flex;

			.illustration-img {
				width: 128px;
				height: 100%;
				margin: $base-unit*2;
				margin-right: 0;
			}

			.illustration-header {
				padding-left: $base-unit;

				@include media-breakpoint-up(lg) {
					padding-left: $base-unit*1.5;
				}

				.panel-preface {
					padding-left: 0;
				}
			}
		}

		&.accordion-panel .panel-body:not(.panel-body-full-width) {
			@include media-breakpoint-up(lg) {
				padding-right: $base-unit*2;
				padding-left: $base-unit*2;
			}
		}
	}

	//inverse styles
	.inverse & .accordion-panel {
		.panel-header-toggler {
			.accordion-title-icon { filter: $accordion-icon-color-inverse; }

			&:focus {
				h3.h6,
				h6 { color: $accordion-header-color-inverse-hover; }

				.accordion-title-icon { filter: $accordion-icon-color-inverse-hover; }
			}

			&:not(.collapsed) {
				h3.h6,
				h6 { color: $accordion-header-color-inverse-hover; }

				.button-circle {
					background-color: $gray08;
					box-shadow: none;

					img,
					.icon {
						filter: $filter-blue-dark;
					}
				}

				.accordion-title-icon { filter: $accordion-icon-color-inverse-hover; }
			}

			&.collapsed:hover {
				h3.h6,
				h6 { color: $accordion-header-color-inverse-hover; }

				.button-circle {
					background-color: $gray08;
					box-shadow: none;

					img,
					.icon {
						filter: $filter-blue-dark;
					}
				}

				.accordion-title-icon { filter: $accordion-icon-color-inverse-hover; }
			}
		}

		.panel-body,
		.panel-preface {
			color: $text-muted-inverse;

			a {
				color: $text-muted-inverse;
				border-bottom-color: $text-muted-inverse;
			}
		}
	}
}
