/* ❗ If you do some changes in this file, please inform in the #help-crm Slack channel about it. ❗ */

@import "header-desktop";
@import "header-mobile";

// region selector +
@import "region-selector";

// utility classes
@mixin vertical-separation-line {
	position: absolute;
	right: -1px;
	top: 34px;
	content: "";
	display: block;
	width: 1px;
	height: calc(100% - #{$cmp-header__height--mobile});
	background-color: $cmp-header__border-color;
}
@mixin horizontal-separation-line {
	content: "";
	display: block;
	height: 1px;
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	background-color: $cmp-header__border-color;
}


//
//	Shared (mobile, desktop)
//
.header {
	position: relative;
	z-index: 1000;
	height: $cmp-header__height--mobile;

	@include media-breakpoint-up(lg) {
		height: $cmp-header__height--desktop;
	}

	// 🔷 variant: transparent
	&.transparent {
		background: transparent;

		// move Hero under Header (Logo is over the Hero visual)
		+ .content-holder > .hero,
		+ .content-holder > .js-platform-switch > .hero {
			margin-top: -$cmp-header__height--mobile;
			padding-top: $base-unit * 3;

			@include media-breakpoint-up(lg) {
				margin-top: -$cmp-header__height--desktop;
				padding-top: $base-unit * 4;
			}
		}
	}
}



// desktop
.navigation-for-desktop {
	.header-wrap {
		.mobile,
		.hidden-desktop {
			display: none !important;
		}

		.desktop {
			display: block !important;
		}
	}
}

//mobile
.navigation-for-mobile {
	.header-wrap {
		.desktop,
		.hidden-mobile {
			display: none !important;
		}

		.mobile {
			display: block !important;
		}
	}
}

@include media-breakpoint-up(lg) {
	body:before {
		content: 'navigation-desktop' !important;
		display: none !important;
	}
}
@include media-breakpoint-down(sm) {
	body:before {
		content: 'navigation-mobile' !important;
		display: none !important;
	}
}

// hides the menu until JS is loaded
// JS will add .navigation-for... class into a BODY, that changes it to display: block;
// TODO: when JS is off, Logo is not visible
.header {
	.header-wrap {
		visibility: hidden;

		.navigation-for-desktop &,
		.navigation-for-mobile & {
			visibility: visible;
		}
	}
}

[class*="navigation-for-mobile"] {
	.header {
		.third-menu {
			display: none;
		}
	}
}

/* Logic */
// used for both versions
[class*="navigation-for"] {

	// basic logic that is same for desktop and mobile version

	// second menu items are flex
	&.first-menu-for-home .second-menu.for-home,
	&.first-menu-for-business .second-menu.for-business,
	&.first-menu-for-partners .second-menu.for-partners,
	&.first-menu-about-us .second-menu.about-us,
	&.first-menu-regions .second-menu.regions,
	&.blogs-special-menu .second-menu.blogs {
		display: flex;
	}

	// third menu items are block
	&.second-menu-security .third-menu.security,
	&.second-menu-privacy .third-menu.privacy,
	&.second-menu-about-pages .third-menu.about-pages,
	&.second-menu-privacy-pages .third-menu.privacy-pages,
	&.second-menu-performance .third-menu.performance,
	&.second-menu-family .third-menu.family,
	&.second-menu-bundles .third-menu.bundles,
	&.second-menu-products .third-menu.products,
	&.second-menu-press-center .third-menu.press-center,
	&.second-menu-investors .third-menu.investors,
	&.second-menu-diversity .third-menu.diversity,
	&.second-menu-business-partners .third-menu.business-partners,
	&.second-menu-resources .third-menu.resources,
	&.second-menu-login .third-menu.login,
	&.blogs-special-menu .third-menu.topics {
		display: block;
	}

	// only desktop and mobile are used
	.header {
		.second-menu { display: none; }
	}

	// Navigation styles - general
	.header {

		// Colors
		a:hover {
			color: $blue-bright;
		}

		// Reset
		ul {
			margin: 0;
			padding: 0;

			li {
				padding: 0;
				list-style: none;
			}
		}

		// mixin for icon
		@mixin region-selector-flag {
			background-color: transparent;
			background-image: $region-selector-flags;
			background-repeat: no-repeat;
			content: "";
			display: inline-block;
			vertical-align: middle;
			height: 24px;
			width: 24px;
		}

		// user avatar icon
		// used in business products
		.block-header {
			.header-info {
				&:before {
					content: '';
					display: inline-block;
					position: relative;
					top: -2px;
					width: 16px;
					height: 16px;
					background: transparent url(../../i/v2/components/navigation/user-avatar.svg) center center no-repeat;
					margin-right: 8px;
					vertical-align: middle;
				}
			}
		}

		// displayed region
		.region .category:before {
			position: relative;
			top: 0;
			margin-right: 10px;

			@include region-selector-flag;
		}

		// region selector items
		.second-menu.regions {

			// flag before menu item
			.custom-regions li {
				font-size: 14px;
				padding-bottom: 9px;
				white-space: nowrap;

				a:before {
					margin-right: 10px;

					@include region-selector-flag;
				}
			}
		}

		// operation system logos
		// styles for latte template
		.os {
			display: block;

			.icon {
				display: none;
			}

			&.win .icon:nth-of-type(1) {
				display: inline-block;
			}

			&.mac .icon:nth-of-type(2) {
				display: inline-block;
			}

			&.android .icon:nth-of-type(3) {
				display: inline-block;
			}

			&.ios .icon:nth-of-type(4) {
				display: inline-block;
			}

			&.win-smb .icon:nth-of-type(5) {
				display: inline-block;
			}

			&.mac-smb .icon:nth-of-type(6) {
				display: inline-block;
			}

			&.servers-smb .icon:nth-of-type(7) {
				display: inline-block;
			}

			&.linux-smb .icon:nth-of-type(8) {
				display: inline-block;
			}

			&.android-smb .icon:nth-of-type(9) {
				display: inline-block;
			}

			&.ios-smb .icon:nth-of-type(10) {
				display: inline-block;
			}
		}
	}

}

//media query handling exceptions
.navigation-for-desktop {
	.header {

		// some third menus has only two tiles in a row
		@include media-breakpoint-down(lg) {
			.third-menu.privacy,
			.third-menu.performance {
				width: 570px;
			}

			.third-menu.solutions {
				width: 902px;
			}

			.third-menu.privacy,
			.third-menu.performance,
			.third-menu.solutions {
				.block-products {
					flex-wrap: wrap;

					& > li {
						width: 50%;
						position: relative;
						text-align: left;

						&:nth-child(2) {
							border-right: none;
						}

						&:nth-child(1),
						&:nth-child(2) {
							&:after {
								@include horizontal-separation-line;
							}
						}
					}
				}
			}

			.third-menu.privacy {
				.block-products {
					flex-wrap: wrap;

					& > li {
						width: 50%;

						&:nth-child(3),
						&:nth-child(4),
						&:nth-child(5),
						&:nth-child(6) {
							&:after {
								@include horizontal-separation-line;
							}
						}
					}
				}
			}
		}

		// privacy should be in multiple rows even on lg
		@include media-breakpoint-up(xl) {
			.third-menu.privacy {
				width: 892px;

				.block-products {
					flex-wrap: wrap;

					& > li {
						width: 33.2%;
						position: relative;
						text-align: left;

						// cancels separation line for the last product in first row
						&:nth-child(3)::before {
							display: none;
						}
						// enables it for the last product (which is now not a last in line
						&:last-child::before {
							@include vertical-separation-line;
						}

						&:nth-child(1),
						&:nth-child(2),
						&:nth-child(3),
						&:nth-child(4),
						&:nth-child(5),
						&:nth-child(6) {
							&:after {
								@include horizontal-separation-line;
							}
						}
					}
				}
			}
		}
	}
}

/* Platform switching */
// using only hiding, because content is displayed in modes (block, flex...)

// win
.js-pc .header {
	.content-mac,
	.content-android,
	.content-ios {
		display: none;
	}
}
// mac
.js-mac .header {
	.content-windows,
	.content-android,
	.content-ios {
		display: none;
	}
}
// android
.js-android .header {
	.content-windows,
	.content-mac,
	.content-ios {
		display: none;
	}
}
// ios
.js-ios .header {
	.content-windows,
	.content-mac,
	.content-android {
		display: none;
	}
}
