/*!
=========================================================
* Avast styles
=========================================================
* Include only global stuff here
=========================================================
*/

// Bootstrap mixins and functions
@import "bootstrap/_mixins";
@import "bootstrap/_functions";

// AOS - Animate on scroll library
//@import "vendor/aos/aos.scss";
@import "vendor/tiny-slider/tiny-slider.scss";

// Cookie Consent - OneTrust
@import "vendor/onetrust/onetrust-avast-floating-center.scss";

// Custom variables here
@import "avast/_variables";

// Bootstrap
@import "bootstrap/bootstrap";

// Custom Mixins
@import "avast/_mixins";

// Custom Components
@import "avast/_components";

// Custom Layout
@import "avast/_layout";


//
//	👇 You can't touch this! 👇
//
@import "cashback";	// contact <frantisek.rapcak@avast.com> for more information
