/* System requirements card */

.sys-req-card {
	position: relative;
	text-align: center;
	display: flex;
	flex-direction: column;/* 
	font-size: $body-lg-font-size;
	line-height: $base-unit*2; */
	margin: 0 auto;
	max-width: 1128px;
	min-width: 1px;
	padding: $base-unit*2.5 $base-unit*1.25;
	background-color: $gray09;

	.inverse & {
		background-color: $blue-deep;
	}

	@include radius(48px);
	@include media-breakpoint-up(lg) {
		padding: $base-unit*5 $base-unit*13.4;
	}

	&__text {
		margin-bottom: $base-unit*1.5;

		p:last-child {
			margin-bottom: 0;
		}
	}

	&__compatibility {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: $base-unit*3;
		margin-top: $base-unit*3;

		&:last-child {
			margin-bottom: 0;
		}

		.sys-req-card__compatibilityIcon {
			margin-right: $base-unit;
		}

		span {
			line-height: 1;
		}

		&.multipleIcons {
			flex-direction: column;

			.sys-req-card__compatibilityIcon {
				margin-right: 0;
				margin-bottom: $base-unit*.5;
			}

			span {
				margin-bottom: $base-unit*2;
			}
		}
	}

	a {
		font-weight: 700;
	}

	strong,
	b {
		font-weight: 700;
	}
}