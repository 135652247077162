.product-box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: $gray10;
	height: 100%;
	padding-bottom: $base-unit*2;
	margin-bottom: $base-unit * 1.25;
	border-radius: $base-unit * 1.5;

	.inverse & {
		background-color: $blue-deeper;
	}

	@media (min-width: $breakpoint-custom-lg1) {
		flex-direction: row;
		margin-bottom: $base-unit * 2;
		padding: $base-unit $base-unit*2.5 $base-unit $base-unit;
	}

	&-content {
		@include media-breakpoint-down(lg) {
			margin-right: 0;
		}
	}

	.actionbox-group {
		display: flex;
		justify-content: center;

		@include media-breakpoint-down(lg) {
			flex-wrap: wrap;
		}
	}

	.actionbox-wrap {
		margin-top: $base-unit/2;
		margin-bottom: $base-unit/2;

		.labelActive {
			flex: 0 0 0 !important;
		}

		.action-box-wrap {
			height: 100%;
		}
	}

	.actionbox {
		margin-top: $base-unit/2;
		margin-bottom: $base-unit/2;

		.js-platform-switch {
			height: 100%;
		}

		.try-box-wrap {
			height: 100%;
		}

		.js-pc,
		.js-mac,
		.js-android,
		.js-android-market,
		.js-ios {
			height: 100%;
		}
	}
}
