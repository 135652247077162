@import "_abox-grid.scss";

.abox-wrap {
	display: flex;
	justify-content: center;
	text-align: center;

	.abox-container {
		display: grid;
		position: relative;
		background: none;
		margin: $base-unit/2 auto;
		text-align: center;

		
		.abox-guts, // vue template need only one root element
		.abox-table-section { // slot for layout displayed on table at mobile resolutions
			display: contents;
		}

		&.show-toggler {
			margin-top: 0;

			@include media-breakpoint-only(md) {
				margin-top: $base-unit*2;
			}

			// on desktop with toggler all top slot has padding plus half of toggler
			@include media-breakpoint-up(lg) {
				margin-top: $base-unit*2.5;

				[abox].abox-top {
					padding-top: $base-unit*3.25;
				}
			}

			.abox-toggler {
				position: absolute;
				z-index: 3;
				left: 50%;
				top: 0;
				transform: translateX(-50%);

				@include media-breakpoint-up(lg) {
					transform: translate(-50%, -50%);
				}

				@for $d from 1 through 5 {
					&[set="#{$d}"] {
						@include media-breakpoint-down(md) {
							// on mobile with toggler only first top slot has padding plus half of toggler
							// - must be abox-top from same set as toggler
							// - must be only first abox-top from the set
							~.abox-guts[set="#{$d}"] {
								.abox-top {
									margin-top: 17px; // half of the toggler
									padding-top: $base-unit*2.5;
								}

								~.abox-guts[set="#{$d}"] .abox-top {
									margin-top: 0;
									padding-top: $base-unit*1.5;
								}
							}
						}
					}
				}

				.custom-select {
					width: auto;
					height: auto;
					margin-bottom: 0;
					padding: 7px 43px 7px 15px;

					@include media-breakpoint-up(lg) {
						padding: 7px 47px 7px 23px;
					}

					&[disabled="disabled"] {
						opacity: 1;
						background-image: none;
						background-color: $white;
						padding: 7px 23px;
						text-align: center;
						box-shadow: none;
						cursor: initial;
						color: $blue-dark;

						@include media-breakpoint-up(lg) {
							padding: 7px 15px;
						}

						&:hover {
							border-color: $gray08;
						}
					}
				}

				.toggler-horizontal {
					background-color: $white;
					border-radius: 20px;
					display: flex;
					padding: 3px; // -1px for border
					border: 1px solid $gray08;
					box-shadow: 0 10px 20px -10px rgba($blue-dark, .2);

					@include media-breakpoint-down(md) {
						max-width: 332px;
					}

					&.disabled {
						box-shadow: none;

						.toggler-option.selected {
							background-color: transparent;
							//color: $gray06;
							cursor: initial;

							&:hover {
								background-color: $white;
							}
						}
					}

					.toggler-option {
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: transparent;
						border-radius: 16px;
						cursor: pointer;
						max-width: 240px;
						padding: $base-unit/4 $base-unit;
						white-space: nowrap;
						overflow: hidden;
						margin: 0	;

						@include media-breakpoint-up(lg) {
							padding: $base-unit/4 $base-unit*1.5;
						}

						&:last-child {
							border-right: none;
						}

						&:hover,
						&:focus,
						&:active {
							background-color: $orange-faint;
							outline-offset: -1px;  // fix outline overlaping

						}

						&.selected {
							background-color: $orange-pale;
						}
					}
				}
			}
		}


		// Sizes of boxes based on the count of boxes next to each other and on displayed multibox
		[abox] {
			width: 256px;
			
			@include media-breakpoint-up(lg) {
				width: 316px;
			}
		}

		&.has-multibox {
			@include media-breakpoint-up(lg) {
				&.boxcount-2 [abox] {
					width: 280px;

					// added margin 16px
					&[multibox=start],
					&[multibox=end] {
						width: 296px;
					}
				}

				&.boxcount-3,
				&.boxcount-4,
				&.boxcount-5 {
					[abox] {
						width: 256px;

						// added margin 16px
						&[multibox=start],
						&[multibox=end] {
							width: 272px; // added margin 16px
						}
					}
				}
			}
		}

		
		
		// Slots styling based on GRID
		[abox] {
			background-color: $white;
			padding-left: $base-unit*2;
			padding-right: $base-unit*2;
			border-left: 1px solid $gray08;
			border-right: 1px solid $gray08;

			&.empty {
				padding-top: 0;
				padding-bottom: 0;
			}

			&:not([multibox]) {
				margin-left: $base-unit;
				margin-right: $base-unit;

				&.abox-bottom {
					margin-bottom: $base-unit/2;
				}

				&.abox-top:not([abox="1"]) {
					margin-top: $base-unit/2;
				}
			}

			&[multibox] {
				&.abox-top,
				&.abox-bottom {
					border-radius: 0;
					border-left: 0;
				}

				&[multibox=start] {
					border-left: 1px solid $gray08;
					margin-left: $base-unit;
					padding-left: $base-unit*3;
				}

				&[multibox=end] {
					border-right: 1px solid $gray08;
					margin-right: $base-unit;
					padding-right: $base-unit*3;
				}
			}

			&.abox-top {
				border-top: 1px solid $gray08;
				padding-top: $base-unit*1.5;
				border-radius: $base-unit $base-unit 0 0;

				&[multibox=start] {
					border-radius: $base-unit 0 0 0;
				}

				&[multibox=end] {
					border-radius: 0 $base-unit 0 0;
				}
			}

			&.abox-bottom {
				border-bottom: 1px solid $gray08;
				padding-bottom: $base-unit*1.5;
				border-radius: 0 0 $base-unit $base-unit;

				&[multibox=start] {
					border-radius: 0 0 0 $base-unit;
				}

				&[multibox=end] {
					border-radius: 0 0 $base-unit 0;
				}
			}


			@include media-breakpoint-up(lg) {
				&.abox-top {
					padding-top: $base-unit*2;
				}

				&.abox-bottom {
					padding-bottom: $base-unit*2;
				}

				&[multibox] {
					border-right: 0;
					border-left: 1px solid $gray08;
				}

				&:not([multibox]) {
					&.abox-bottom {
						margin-bottom: 0;
					}

					&.abox-top:not([abox="1"]) {
						margin-top: 0;
					}
				}
			}


			@include media-breakpoint-down(md) {
				&[multibox] {
					margin-left: $base-unit;
					margin-right: $base-unit;

					&.abox-top,
					&.abox-bottom {
						border-radius: 0;
						border-left: 1px solid $gray08;
					}

					&.abox-top:not([multibox=start]) {
						border-top: 0;

						&:before {
							content: "";
							display: block;
							position: relative;
							height: 1px;
							width: 100%;
							left: auto;
							right: auto;
							top: -$base-unit*1.5;
							background-color: $gray08;
						}
					}

					&.abox-bottom:not([multibox=end]) {
						border-top: 0;
						border-bottom: 0;
					}

					&.abox-top[multibox=start] {
						border-radius: $base-unit $base-unit 0 0;
					}

					&.abox-bottom[multibox=end] {
						border-radius: 0 0 $base-unit $base-unit;
						margin-bottom: $base-unit/2;
					}

					&[multibox=start] {
						padding-left: $base-unit*2;
					}

					&[multibox=end] {
						padding-right: $base-unit*2;
					}
				}
			}
		}


		/* PROMO Overlay
		- Overalay div is rendered only on multibox and if in table
		- Single box has just orange border when promoted
		- VUE will check if promo column is above multibox and if this column is first or last multibox column and create proper class for overlay div */
		&[class*=promo-] {
			.promo-frame {
				border: 1px solid $orange-bright;
				z-index: 2;
				border-radius: $base-unit/2;
				pointer-events: none;
				margin-top: $base-unit;
				margin-bottom: $base-unit;

				@include media-breakpoint-down(md) {
					margin-left: $base-unit*2;
					margin-right: $base-unit*2;
					margin-top: 0;
					margin-bottom: 0;
				}

				&.multi-start {
					margin-left: $base-unit*2;

					@include media-breakpoint-down(md) {
						margin-top: $base-unit*2;
					}
				}

				&.multi-end {
					margin-right: $base-unit*2;

					@include media-breakpoint-down(md) {
						margin-bottom: $base-unit*1.5;
					}
				}
			}

			// multibox boxes expansion for promo frame
			[multibox] {
				@include media-breakpoint-up(lg) {
					&.abox-top {
						padding-bottom: $base-unit;
					}

					&.abox-bottom {
						padding-top: $base-unit;
					}
				}

				@include media-breakpoint-down(md) {
					&[multibox="start"].abox-top {
						padding-bottom: $base-unit;
					}

					&[multibox="end"].abox-bottom {
						padding-top: $base-unit;
					}
				}
			}

			// with toggler the promo overlay has bigger top margins
			&.show-toggler {
				.promo-frame {
					@include media-breakpoint-up(lg) {
						margin-top: $base-unit*2.25;
					}

					@include media-breakpoint-down(md) {
						&.multi-start {
							margin-top: $base-unit*3;
						}
					}
				}
			}

			// 'promo-x' classes individual styles
			@for $p from 1 through 5 {
				&.promo-#{$p} {
					$nextCol: $p + 1;

					// remove border between boxes
					@include media-breakpoint-up(lg) {
						[multibox]:not([multibox="start"]) {
							&[abox="#{$nextCol}"] {
								border-left: none;
							}
						}
					}

					@include media-breakpoint-down(md) {
						[multibox] {
							&.abox-top[abox="#{$p}"]:not([multibox="start"]),
							&.abox-top[abox="#{$nextCol}"] {
								&:before {
									display: none;
								}
							}
						}
					}

					// single box promo is just an orange border (promo overlay is not rendered)
					[abox="#{$p}"]:not([multibox]) {
						border-color: $orange-bright;
					}
				}
			}
		}


		// SLOTS STYLES
		.abox-product {
			padding-bottom: $base-unit/2;

			.abox-title {
				margin: 0;
			}
		}

		.abox-headline {
			padding-bottom: $base-unit/2;

			.abox-tagline {
				justify-content: center;
				display: inline-flex;
			}
		}

		.abox-platforms {
			padding-bottom: $base-unit;
			align-items: center;
			display: flex;
			justify-content: center;

			.icon {
				margin: 0 4px;
			}

			@include media-breakpoint-up(lg) {
				padding-bottom: $base-unit*1.5;
			}
		}

		.abox-instructions,
		.abox-note-above-cta {
			margin: 0;
		}

		.abox-label {
			display: flex;
			justify-content: center;
			padding-bottom: 8px;

			.badge {
				height: max-content;
				line-height: 12px;
				padding: 6px $base-unit*.75;
				white-space: normal;
			}
		}

		.abox-installments {
			font-weight: 700;
		}

		.abox-price {
			padding-top: $base-unit/2;
			padding-bottom: $base-unit/2;

			/* NEW PRICE LAYOUT (HYPERION) */
			.price-header {
				line-height: $base-unit*2;
				font-weight: 800;
				margin: 0 $base-unit/4 0 0;
			}

			.price-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;

				.price-new .h3 {
					line-height: $base-unit*2;
					font-weight: 800;
					margin: 0 $base-unit/4 0 0;
				}

				.price-misc {
					display: flex;
					flex-direction: column;
					align-items: start;
					position: relative;
					font-size: 14px;
					line-height: $base-unit;

					.price-old {
						position: relative;
						text-decoration: line-through;
						bottom: -2px;
					}

					.price-period {
						position: relative;
						font-weight: 700;
						top: -1px;
					}
				}

				&.period-only {
					align-items: baseline;
				}
			}
			/* // NEW PRICE LAYOUT (HYPERION) */
		}

		.abox-price-old {
			.price-old {
				text-decoration: line-through;
			}

			// used for EN-GB region
			// - inherits some styles from .abox__priceOld
			&.abox-month-price {
				.month-price {
					margin-bottom: 0;	// reset margin set by .text-small (used on the HTML element)
				}
			}
		}

		.abox-cta {
			padding-top: 14px;
			padding-bottom: $base-unit/2;

			@include media-breakpoint-up(lg) {
				padding-top: $base-unit;
			}
		}

		.abox-cta-note {
			padding-top: $base-unit/4;
			padding-bottom: $base-unit/4;

			.subscription-link {
				cursor: pointer;
				white-space: nowrap;

				.sub-text {
					white-space: normal;
				}

				.sub-icon {
					@include sprite-image('icons', 'info', 16);
					flex: 0 0 auto;
					display: inline-block;
					cursor: pointer;
					vertical-align: top;

					&:hover {
						@include sprite-image('icons', 'hover-info', 16);
						flex: 0 0 auto;
					}
				}
			}
		}

		.abox-cta-secondary {
			line-height: 0;

			// any first element will have padding. (fixed issue when the content is hidden by platform switcher)
			&>* {
				margin-top: $base-unit;

				@include media-breakpoint-up(lg) {
					margin-top: $base-unit*1.25;
				}
			}
		}

		.abox-footer {
			padding-top: $base-unit/2;

			@include media-breakpoint-up(lg) {
				padding-top: $base-unit;
			}
		}
		

		// Simulation of multibox on separate boxes
		&.fake-multibox {
			[abox] {
				margin-left: 0;
				margin-right: 0;
				border-radius: 0;
				border-left: 0;

				&.abox-top,
				&.abox-bottom {
					border-right: 0;
				}

				@media (min-width: $breakpoint-lg) and (max-width: 1365.98px) {
					width: 256px;
				}

				@include media-breakpoint-down(md) {
					&.abox-bottom {
						margin-bottom: 0;
					}

					&.abox-top:not([abox="1"]) {
						border-top: none;
						margin-top: 0;
					}
				}
			}

			[abox="1"] {
				border-left: 1px solid $gray08;
				padding-left: $base-unit*3;

				&.abox-top {
					border-radius: $base-unit 0 0 0;
				}

				&.abox-bottom {
					border-radius: 0 0 0 $base-unit;
				}

				@include media-breakpoint-up(lg) {
					margin-left: $base-unit;
				}

				@media (min-width: $breakpoint-lg) and (max-width: 1365.98px) {
					width: 272px;
				}

				@include media-breakpoint-down(md) {
					padding-left: $base-unit*2;
					margin-left: 0;

					&.abox-top {
						border-radius: $base-unit $base-unit 0 0;
					}

					&.abox-bottom {
						border-radius: 0;
					}

					&.abox-top,
					&.abox-bottom {
						border-right: 1px solid $gray08;
					}
				}
			}

			@for $p from 2 through 4 {
				&.boxcount-#{$p} {
					[abox="#{$p}"] {
						border-right: 1px solid $gray08;
						padding-right: $base-unit*3;

						&.abox-top {
							border-radius: 0 $base-unit 0 0;
						}

						&.abox-bottom {
							border-radius: 0 0 $base-unit 0;
						}

						@include media-breakpoint-up(lg) {
							margin-right: $base-unit;
						}

						@media (min-width: $breakpoint-lg) and (max-width: 1365.98px) {
							width: 272px;
						}

						@include media-breakpoint-down(md) {
							padding-right: $base-unit*2;
							margin-right: 0;

							&.abox-top {
								border-radius: 0;
							}

							&.abox-bottom {
								border-radius: 0 0 $base-unit $base-unit;
							}

							&.abox-top,
							&.abox-bottom {
								border-left: 1px solid $gray08;
							}
						}
					}
				}
			}

			&:not(.boxcount-1) {
				.abox-toggler[set="1"][span-start="1"][span-end="1"] {
					@include media-breakpoint-up(lg) {
						left: calc(50% + 16px);
					}
				}
			}
		}
		// /Simulation end
	}
}


// Inverse styling
.inverse {
	.abox-wrap .abox-container {
		&.theme-light {
			&:not(.table-view) {
				[abox] {
					@extend .light;
				}
			}
		}

		&.theme-dark {
			&:not(.table-view) {
				[abox] {
					background-color: $blue-deep;
					border-color: $blue-mid;
				}
			}

			.abox-platforms .icon {
				filter: $filter-gray06;
			}

			.badge {
				background: $blue-mid;
				border: 1px solid $blue-mid-mid;
				color: $white;
			}

			.abox-cta-note {
				.subscription-link {
					.sub-icon {
						filter: $filter-white;
					}
				}
			}

			&.show-toggler .abox-toggler {
				.toggler-horizontal {
					background-color: $blue-deep;
					border-color: $blue-mid;
					
					.toggler-option {
						color: $white;

						&:hover,
						&:focus,
						&:active {
							background-color: $blue-mid;
						}

						&.selected {
							background-color: #37586C;
						}
					}
				}

				.custom-select[disabled="disabled"] {
					background-color: $blue-deep;
					color: $gray03;

					&:hover {
						border-color: $blue-mid;
					}
				}
			}
		}
	}

	.combined-actionbox {
		.abox-wrap .abox-container.theme-dark [abox] {
			@include media-breakpoint-down(md) {
				&.abox-top:not([abox="1"]):before {
					background-color: $blue-mid;
				}
			}
		}
	}
}


// Change of the layout in the mobile table
.tables-common {
	.abox-wrap .abox-container {
		width: 100%;
		margin-bottom: 0;

		[abox] {
			width: auto;
			margin: 0;
			border: none;
			border-radius: 0;
			background-color: transparent;

			@media (max-width: $breakpoint-custom-lg2) {
				padding-left: $base-unit;
				padding-right: $base-unit;
			}

			// product names should be hidden on desktop tables
			&.abox-product {
				@include media-breakpoint-up(md) {
					display: none;
				}
			}
		}
		

		&[class*=promo-] {
			&.show-toggler {
				.abox-toggler {
					@include media-breakpoint-down(md) {
						top: 0;
						text-align: initial;
					}
				}

				// FIX ME: When there is a spanning instructions slot, the frame in the table is messing with the grid possitions for some reason.
				.promo-frame {
					height: calc(100% + (#{$base-unit}*2.5));
					margin-top: -$base-unit*2.5;
				}
			}

			.promo-frame {
				// FIX ME: When there is a spanning instructions slot, the frame in the table is messing with the grid possitions for some reason.
				position: absolute;
				width: 100%;
				height: calc(100% + (#{$base-unit}*.5));

				margin: 0;
				margin-top: -$base-unit*.5;
				border-top: none;
				border-radius: 0;
				border-width: 2px;

				&.multi {
					margin-left: -1px;
					margin-right: -1px;
				}
			}
		}

		// Reordered structure
		@include media-breakpoint-down(sm) {
			.abox-toggler {
				position: relative;
				left: 0;
				transform: none;
				padding-bottom: $base-unit;

				&.multi {
					display: none;
				}

				&[set] ~ .abox-guts[set] .abox-top {
					padding-top: $base-unit*1.5;
					margin-top: 0;
					border-top: 1px solid $gray08;
				}
			}

			[abox] {
				padding-left: 0;
				padding-right: 0;

				&:not([multibox]).abox-bottom {
					margin-bottom: 0;
				}
			}

			.abox-guts {
				text-align: left;
			}

			.abox-product {
				.abox-title {
					font-size: $h5-font-size-m;
					line-height: $h5-line-height-m;
				}
			}

			.abox-headline {
				padding-top: $base-unit/4;
			}

			.abox-platforms {
				justify-content: start;
			}

			.abox-table-section {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				text-align: right;

				.abox-price {
					.currency,
					.integer,
					.decimal,
					.period {
						font-size: $base-unit*1.125;
						line-height: $base-unit*1.6875;
					}

					.price-prefix,
					.price-suffix {
						margin-top: 0;
						display: flex;
					}
				}
			}
		}
	}

	// MD breakpoint, based on number of columns
	@include media-breakpoint-down(md) {
		&.columns-2,
		&.columns-3 {
			.abox-wrap {
				.promo-frame {
					display: none;
				}

				.abox-container {
					margin-top: $base-unit;
					margin-bottom: 0;
				}
			}
		}
	}

	@media (max-width: $breakpoint-custom-lg1) {
		&.columns-4,
		&.columns-5 {
			.abox-wrap {
				.promo-frame {
					display: none;
				}

				.abox-container {
					margin-top: $base-unit;
					margin-bottom: 0;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
		&.columns-2,
		&.columns-3,
		&.columns-4,
		&.columns-5 {
			.abox-wrap .abox-container {
				margin-top: 0;
			}
		}
	}

	// Promo and border, applicable only on desktop, based on number of columns
	@mixin table-borders($num) {
		&.highlight-#{$num}:not(.unframed) {
			.promo-#{$num} {
				.abox-bottom:not([abox="#{$num}"]) {
					.inverse & { border-bottom: solid 1px $blue-mid-mid; }
					&,
					.light & { border-bottom: solid 1px $gray08; }
				}
			}

			// borders here cannot be here as actual borders as those cut off the space available for aboxes
			@if $num > 1 { // if the promo is not on first column, add the shadow to the left side of the TD
				tfoot td[colspan] {
					.inverse & { box-shadow: 1px 0 0 0 $blue-mid-mid inset; }
					&,
					.light & { box-shadow: 1px 0 0 0 $gray08 inset; }
				}

				&:not(.columns-#{$num}) { // if the promo is not on the last column, add the shadow on the right side as well
					tfoot td[colspan] {
						.inverse & { box-shadow: -1px 0 0 0 $blue-mid-mid inset, 1px 0 0 0 $blue-mid-mid inset; }
						&,
						.light & { box-shadow: -1px 0 0 0 $gray08 inset, 1px 0 0 0 $gray08 inset; }
					}
				}
			}

			&:not(.columns-#{$num}) { // if the promo is not on the last column
				tfoot td[colspan] {
					@if $num > 1 { // if the promo is not on first column, add the shadow to the left side of the TD
						.inverse & { box-shadow: -1px 0 0 0 $blue-mid-mid inset, 1px 0 0 0 $blue-mid-mid inset; }
						&,
						.light & { box-shadow: -1px 0 0 0 $gray08 inset, 1px 0 0 0 $gray08 inset; }

					} @else { // else add it to both sides
						.inverse & { box-shadow: -1px 0 0 0 $blue-mid-mid inset; }
						&,
						.light & { box-shadow: -1px 0 0 0 $gray08 inset; }
					}
				}
			}

			&.borders-orange {
				.promo-#{$num} {
					.abox-bottom:not([abox="#{$num}"]) {
						border-bottom: solid 1px $orange-pale;
					}
				}
	
				// borders here cannot be here as actual borders as those cut off the space available for aboxes
				@if $num > 1 { // if the promo is not on first column, add the shadow to the left side of the TD
					tfoot td[colspan] {
						box-shadow: 1px 0 0 0 $orange-pale inset;
					}
	
					&:not(.columns-#{$num}) { // if the promo is not on the last column, add the shadow on the right side as well
						tfoot td[colspan] {
							box-shadow: -1px 0 0 0 $orange-pale inset, 1px 0 0 0 $orange-pale inset;
						}
					}
				}
	
				&:not(.columns-#{$num}) { // if the promo is not on the last column
					tfoot td[colspan] {
						@if $num > 1 { // if the promo is not on first column, add the shadow to the left side of the TD
							box-shadow: -1px 0 0 0 $orange-pale inset, 1px 0 0 0 $orange-pale inset;
	
						} @else { // else add it to both sides
							box-shadow: -1px 0 0 0 $orange-pale inset;
						}
					}
				}
			}
		}
	}

	@for $i from 1 through 5 {
		&.columns-2,
		&.columns-3 {
			@include media-breakpoint-up(lg) {
				@include table-borders($i);
			}
		}

		&.columns-4,
		&.columns-5 {
			@media (min-width: $breakpoint-custom-lg2) {
				@include table-borders($i);
			}
		}
	}
}


// Change of some stlyes when in combined actionbox
.combined-actionbox {
	.abox-wrap {
		@include media-breakpoint-up(lg) {
			margin-left: $base-unit;
			margin-right: $base-unit;
		}

		.abox-container {
			&.show-toggler {
				margin-top: 0;
				margin-bottom: 0;
			}

			&[class*=promo-] {
				.promo-frame {
					&.multi-end {
						margin-right: 0;
						
						@include media-breakpoint-down(md) {
							margin-right: $base-unit;
						}
					}

					&.multi-start {
						margin-left: 0;
						
						@include media-breakpoint-down(md) {
							margin-left: $base-unit;
							margin-top: $base-unit;
						}
					}
						
					@include media-breakpoint-down(md) {
						margin-left: $base-unit;
						margin-right: $base-unit;
					}
				}

				&.show-toggler {
					// in CAB, with toggler the promo overlay has different top margins
					.promo-frame {
						@include media-breakpoint-down(md) {
							&.multi-start {
								margin-top: $base-unit*2;
							}
						}
					}
				}
			}

			@include media-breakpoint-down(md) {
				.abox-toggler[set="1"] ~ .abox-guts[set="1"] .abox-top {
					margin-top: 0;
				}

				.abox-toggler {
					transform: translate(-50%, -50%);
				}
			}

			// In CAB, single boxes next to each other has same width as multibox boxes
			&:not(.has-multibox) {
				@include media-breakpoint-up(lg) {
					&.boxcount-2 [abox] {
						width: 280px;
					}
					
					&.boxcount-3 [abox] {
						width: 256px;
					}
				}
			}

			
			// no additional margins when in CAB, same widths for all
			&.has-multibox {
				@include media-breakpoint-up(lg) {
					&.boxcount-2 [abox] {
						&[multibox=start],
						&[multibox=end] {
							width: 280px;
						}
					}

					&.boxcount-3,
					&.boxcount-4,
					&.boxcount-5 {
						[abox] {
							&[multibox=start],
							&[multibox=end] {
								width: 256px;
							}
						}
					}
				}
			}

			[abox] {
				&.abox-top {
					border-top: none;
				}

				&.abox-bottom {
					border-bottom: none;
				}
				
				&[multibox] {
					&[multibox=start] {
						border-left: none;
						padding-left: $base-unit*2;
						
						@include media-breakpoint-up(lg) {
							margin-left: 0;
						}
					}

					&[multibox=end] {
						border-right: none;
						padding-right: $base-unit*2;
						
						@include media-breakpoint-up(lg) {
							margin-right: 0;
						}
					}
					
					@include media-breakpoint-down(md) {
						&.abox-top {
							border-left: none;
						}

						&.abox-bottom {
							border-left: none;
						}
					}
				}

				&:not([multibox]) {
					margin-left: 0;
					margin-right: 0;
					border-left: none;
					border-right: none;
				}

				@include media-breakpoint-down(md) {
					width: 288px;
					border-left: none;
					border-right: none;

					&.abox-top:not([abox="1"]) {
						margin-top: 0;

						&:before {
							content: "";
							display: block;
							position: relative;
							height: 1px;
							width: 256px;
							left: 50%;
							transform:translateX(-50%);
							//right: auto;
							top: -$base-unit*1.5;
							background-color: $gray08;
						}
					}
				}
			}
		}
	}
}