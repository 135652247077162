/* Try box component */

// Hide trybox on mobile devices (android, ios,...)
/* body.platform-android,
body.platform-ios {
	.try-box-wrap {
		display: none;
	}

	.table-cmp-view {
		display: block;
	}
} */

.try-box-wrap {
	@include media-breakpoint-down(md) {
		margin-bottom: $base-unit * .5;
	}
}

.try-box {
	height: 100%;
	margin: 0 $base-unit * .5;
	border-radius: $base-unit;
	border: 1px solid $gray08;

	&[class*="span"] {
		padding-left: 0;
	}

	.form-select-container {
		position: relative;
		display: inline-block;

		.form-select-arrow {
			&:after {
				content: url("../../i/v2/components/pricing-box/down-arrow.svg");
				top: 12px;
			}
		}
	}

	.try-box-container {
		position: relative;
		padding: $base-unit * 2;
		width: 316px;
		height: 100%;
	}

	.box {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
		text-align: center;

		&:last-of-type {
			border-right: none;
		}
	}

	.header-wrap {
		@include media-breakpoint-down(md) {
			flex: auto;
		}

		&.placeholder {
			padding-top: $base-unit * 1.25;
		}
	}

	.title {
		color: $blue-dark;
		font-size: $base-unit*.75;
		line-height: 18px;
		font-weight: 800;
		letter-spacing: 2.5px;
		text-transform: uppercase;
		display: block;
		margin-bottom: 25px;

		@include media-breakpoint-up(lg) {
			font-size: 15px;
			line-height: $base-unit*1.5;
		}
	}

	.content-instead-toggler {
		font-size: 11px;
		line-height: $base-unit*1.125;
		color: $gray03;

		@include media-breakpoint-up(lg) {
			font-size: 14px;
			line-height: $base-unit*1.5;
		}
	}

	.tip {
		margin: $base-unit auto 0;

		a {
			color: $gray03;
			text-decoration: underline;
		}
	}

	.footer-wrap {
		display: flex;
		flex-direction: column;
	}

	.btn-wrapper,
	a.bi-download-link:not(.btn) img {
		margin-top: $base-unit*1.5;
		margin-bottom: $base-unit;
	}

	a.bi-download-link:not(.btn) img {
		width: auto;
		height: 40px;
	}

	.js-try-button {
		display: none;

		&.active {
			display: inline-block;
		}
	}

	.discount-placeholder {
		height: $base-unit*1.5;
	}

	.slot {
		font-size: 20px;
		line-height: 30px;
		color: $blue-dark;
		font-weight: 900;

		@include media-breakpoint-up(lg) {
			font-size: $base-unit*2;
			line-height: $base-unit*3;
		}
	}
}

.try-box-wrap.table-cmp-view {
	@include media-breakpoint-down(sm) {
		.footer-wrap {
			flex-direction: row;
			align-items: center;
		}

		.header-wrap {
			display: none;
		}

		.slot {
			font-size: 16.5px;
			line-height: 30px;
			font-weight: 800;
			flex-direction: row;
			justify-content: flex-start;
		}

		.btn-wrapper,
		a.bi-download-link:not(.btn) img {
			margin-top: $base-unit/2;
		}

		.tip {
			display: none;
		}
	}
}

.inverse {
	.try-box:not(.light) {
		background: $blue-deep;
		border-color: $blue-mid;

		.title {
			color: $white;
		}

		.content-instead-toggler {
			color: $gray06;
		}

		.tip {
			color: $gray06;

			a {
				color: $gray06;
			}
		}
	}
}
