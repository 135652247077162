@each $color, $value in $colors-map {
	&.bg-#{$color} {
		background-color: $value;
	}
}

@each $gray, $value in $grays-map {
	&.bg-#{$gray} {
		background-color: $value;
	}
}

// Classes for BACK compatibility (should be removed from code as the name of color has changed when rebranded)
.bg-extra-dark { background-color: $blue-dark; }  // inverse background 

//Grey bg colors (Should be renamed accross the code)
.bg-grey-90 { background-color: $gray01; }
.bg-grey-80 { background-color: $gray02; }
.bg-grey-60 { background-color: $gray03; }
.bg-grey-50 { background-color: $gray04; }
.bg-grey-40 { background-color: $gray05; }
.bg-grey-20 { background-color: $gray06; }
.bg-grey-15 { background-color: $gray07; }
.bg-grey-10 { background-color: $gray08; }
.bg-grey-05 { background-color: $gray09; }
.bg-grey-03 { background-color: $gray10; }


//filters
.filter-black { filter: $filter-black; }
.filter-white { filter: $filter-white; }
.filter-blue-dark { filter: $filter-blue-dark; }
.filter-blue-deep { filter: $filter-blue-deep; }
.filter-blue-deeper { filter: $filter-blue-deeper; }
.filter-blue-mid-mid { filter: $filter-blue-mid-mid; }
.filter-blue-bright { filter: $filter-blue-bright; }
.filter-blue-pale { filter: $filter-blue-pale; }
.filter-blue-faint { filter: $filter-blue-faint; }
.filter-green-bright { filter: $filter-green-bright; }
.filter-green-faint { filter: $filter-green-faint; }
.filter-orange-bright { filter: $filter-orange-bright; }
.filter-orange-light { filter: $filter-orange-light; }
.filter-orange-pale { filter: $filter-orange-pale; }
.filter-orange-off-white { filter: $filter-orange-off-white; }

//Grays (Should be renamed accross the code)
.filter-gray10,
.filter-grey-03 { filter: $filter-gray10; }

.filter-gray09,
.filter-grey-05 { filter: $filter-gray09; }

.filter-gray08,
.filter-grey-10 { filter: $filter-gray08; }

.filter-gray07,
.filter-grey-15 { filter: $filter-gray07; }

.filter-gray06,
.filter-grey-20 { filter: $filter-gray06; }

.filter-gray05,
.filter-grey-40 { filter: $filter-gray05; }

.filter-gray04,
.filter-grey-50 { filter: $filter-gray04; }

.filter-gray03,
.filter-grey-60 { filter: $filter-gray03; }

.filter-gray02,
.filter-grey-80 { filter: $filter-gray02; }

.no-filter { filter: none !important; }

