.ds-icon {
	display: inline-block;
	width: $unit;
	height: $unit;
	background-image: url('https://static3.avast.com/web/i/v2/components/icons/sprites/icons-16.svg?v3');


	//
	//	icons - 16x16px
	//
	&.next--16 { background-position: -160px 0; }


	//
	//	icons - 24x24px
	//
	&.home--24 { background-position: 0 -80px; }


	//
	//	icons - 32x32px
	//
	&.burger--32 { background-position: -48px -192px; }
	&.cross--32 { background-position: -528px 0; }


	//
	//	DEV only
	//
	&.ERROR__icon-name-not-defined { background: $dev--error !important; }
}




//
//	TODO
//	----
//	👉 ____TASK_DESCRIPTION_GOES_HERE____
//
.ds-icon {
	&.home--24 {
		width: $unit * 1.5;
		height: $unit * 1.5;
		background-image: url('https://static3.avast.com/web/i/v2/components/icons/sprites/icons-24.svg?v3');
	}

	&.burger--32,
	&.cross--32 {
		width: $unit * 2;
		height: $unit * 2;
		background-image: url('https://static3.avast.com/web/i/v2/components/icons/sprites/icons-32.svg?v3');
	}
}
