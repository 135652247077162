.dtyp {
	h1 {
		word-break: break-word;
	}

	// Manual Download Note
	.dtyp-note {
		line-height: 37px;

		a {
			font-weight: 700;
			color: $orange-bright;
		}
	}

	// Loading Spinner
	.dtyp-progress {

		span {
			display: inline-block;
			width: 30px;
			height: 30px;
			margin: 0 auto;
			background: url('../../i/v2/components/icons/others/spinner-dark.gif') no-repeat center center;
		}

		&-text {
			margin-top: $base-unit/4;

			@include media-breakpoint-up(lg) {
				margin-left: $base-unit/2;
			}
		}
	}

	// Manual Download and Spinner elements animation; driven by JS
	.dtyp-note,
	.dtyp-progress {
		opacity: 0;
		transition: opacity 0.33s;
		margin-bottom: $base-unit*1;

		&-show { opacity: 1; }
	}

	// Central image
	.dtyp-central-img {
		width: 100%;
		max-width: 383px;
		height: auto;
		margin: $base-unit*5 auto;
	}

	.dtyp-central-img-small {
		width: 100%;
		max-width: 200px;
		height: auto;
		margin: 0 auto $base-unit*3 auto;
	}

	// Popup
	&.dtyp-popup {
		overflow-y: auto;
		overflow-x: hidden;

		background-color: rgba($white, .98);

		&.inverse {
			background-color: rgba($blue-dark, .98);
		}

		opacity: .8;
		display: flex;
		justify-content: center;
		padding-top: $base-unit*6.5;

		position: fixed;
		z-index: 9000;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		transition: transform .25s linear;
		transform: scale3d(.8, .8, .8);
		will-change: transform;
		//z-index: -1;

		&.is-open {
			opacity: 1;
			transform: scale3d(1, 1, 1);

			@at-root {
				body.dtypup-open {
					overflow-y: hidden;
				}
			}
		}

		// Close button
		.dtyp-close {
			position: absolute;
			right: $base-unit*2;
			top: 36px;
			z-index: 9001;

			.btn-icon {
				filter: $filter-blue-dark;
			}
		}

		&.inverse .dtyp-close .btn-icon {
			filter: $filter-white;
		}
	}

	// Page
	&.dtyp-page {
		padding: $base-unit*4;

		&-firefox {
			position: relative;
			padding-top: $base-unit*5.5;
		}

		&-chrome {
			.dtyp-arrow-chrome {
				position: fixed;
				z-index: 1000;
			}
		}
	}

	// Support
	.dtyp-support {
		margin-top: $base-unit*3.5;

		.icon {
			filter: $filter-blue-dark;
			margin-right: $base-unit/2;
		}
	}

	&.inverse .dtyp-support .icon {
		filter: $filter-white;
	}

	// Chrome Arrow
	.dtyp-arrow-chrome {
		width: 160px;
		height: auto;
		position: absolute;
		left: $base-unit*2;
		bottom: 0;
		display: none;
		z-index: 9000;
		opacity: 0;
		will-change: transform;

		@include media-breakpoint-down(md) {
			display: none;
		}

		animation:
			animateArrow0 500ms ease-in-out 3000ms 1 normal,
			animateArrow1 2500ms ease-in-out 3500ms 1 normal,
			animateArrow2 2000ms ease-in-out 6000ms infinite normal;

		.dtyp-arrow-header {
			background: url('../../i/v2/components/logos/avast-logos/avast-logo-blades.svg') no-repeat 50% 50%;
			background-size: $base-unit*3.5;
			height: 104px;
			position: relative;
			background-color: $white;
			border-radius: $base-unit $base-unit 0 0;
		}

		.dtyp-arrow-body {
			color: $blue-dark;
			background: $orange-pale;
			padding: $base-unit*1.5 $base-unit $base-unit*1.5 $base-unit;
			font-weight: 800;
			border-radius: 0 0 $base-unit*1.5 $base-unit*1.5;
			text-align: center;
			word-break: break-word;
		}

		.dtyp-arrow-footer {
			background-color: $orange-pale;
			height: 103px;
			width: 103px;
			position: relative;
			border-radius: 0 0 $base-unit 0;
			transform: rotate(45deg) translate(-20.5%, -60%);
			z-index: -1;

			&:before {
				position: absolute;
				display: block;
				content: '';
				width: $base-unit*2;
				height: $base-unit*2;
				background: url('../../i/v2/components/icons/sprites/icons-32.svg') no-repeat;
				background-position: -384px 0px;
				transform: rotate(-45deg);
				bottom: 4px;
				right: 4px;
			}
		}

		@keyframes animateArrow0 {
			0% {bottom: 10px;opacity: 0}
			100% {bottom: 500px;opacity: 0}
		}

		@keyframes animateArrow1 {
			0% {bottom: 500px;opacity: 0}
			33% {bottom: 10px;opacity: 1}
			60% {bottom: 200px;opacity: 1}
			100% {bottom: 10px;opacity: 1}
		}

		@keyframes animateArrow2 {
			0% {bottom: 10px;opacity: 1}
			50% {bottom: 100px;opacity: 1}
			100% {	bottom: 10px;opacity: 1}
		}
	}



	// Firefox Arrow
	.dtyp-arrow-firefox {
		position: absolute;
		top: 36px;
		right: 179px;
		z-index: 9001;
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			filter: $filter-orange-bright;
			margin-left: $base-unit;
		}

		@include media-breakpoint-down(md) {
			right: 0;
			left: 0;
		}

		@include media-breakpoint-down(sm) {
			max-width: 250px;
			text-align: end;
			margin: 0 auto;
		}

		@include media-breakpoint-down(xs) {
			display: none;
		}
	}

	.dtyp-headline, h1.dtyp-headline {
		font-size: 56px;
		line-height: 72px;
		font-weight: 750;
		margin-bottom: 48px;
	}

	.dtyp-subheader, h3.dtyp-subheader {
		font-weight: 750;
		font-size: 32px;
		line-height: 48px;
	}
}

/* GLOWEB-7941 DTYP chrome UI AB test */
.dtyp-download-top {
	font-size: 20px;
	line-height: 24px;
	font-weight: 750;
	padding: $base-unit*1;
	background-color: $green-bright;

	.dtyp-top-arrow {
		display: inline-block;
		margin: 0 $base-unit*.5;
		width: 48px;
		height: 48px;
		vertical-align: middle;
		background-image: url('../../i/v2/components/download-popup/dtp-arrow-circle.svg');
	}
}
/* GLOWEB-7941 DTYP chrome UI AB test */
