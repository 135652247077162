@each $sprite-name, $sprite-map in $sprite-images {
	$sprite-sizes: map-get($sprite-map, sizes);
	$sprite-names: map-get($sprite-map, names);

	@each $size in $sprite-sizes {
		@each $name, $values in $sprite-names {
			.icon-#{$size}-#{$name} {
				@include sprite-image($sprite-name, $name, $size);
				flex: 0 0 auto; //so the icon does not shrink in flex
			}
		}
	}
}
