// Custom Forms

.custom-select {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	margin-bottom: $base-unit;
	padding-right: $base-unit*2.5;

	@include media-breakpoint-down(md) {
		font-size: 12px;
		line-height: 18px;
	}

	.inverse & {
		background-color: $blue-deep;
		background-image: escape-svg($custom-select-indicator-inverse);
		color: $white;
		border-color: $blue-mid;

		@include box-shadow(0 10px 20px -10px rgba($black, .4));
	}

	&,
	.light & {
		background-color: $white;
		background-image: escape-svg($custom-select-indicator);
		color: $input-color;
		border-color: $gray08;

		@include box-shadow(0 10px 20px -10px rgba($blue-dark, .16));
	}

	&.custom-select-sm {
		@include border-radius($custom-select-border-radius-sm, 0);
	}

	&.custom-select-lg {
		@include border-radius($custom-select-border-radius-lg, 0);
	}

	&:hover,
	&:active,
	&.active,
	&:active:hover,
	&.active:hover {
		.inverse &:not([disabled="disabled"]) {border-color: #37586C;}

		&,
		.light &:not([disabled="disabled"]) {border-color: $gray05;}
	}

	&:focus,
	&:focus:hover {
		border-color: $blue-bright;

		.inverse & {	
			@include box-shadow(0 10px 20px -10px rgba($black, .4), $input-focus-box-shadow);
		}

		&,
		.light & {
			@include box-shadow(0 10px 20px -10px rgba($blue-dark, .16), $input-focus-box-shadow);
		}
	}
}

.custom-control {
	margin-bottom: $base-unit;
}

.custom-control-label {
	&:before {
		.inverse & {border-color: $blue-mid;}

		&,
		.light & {border-color: $gray08;}
	}
}

.custom-control-input {
	~ .form-text,
	~ .valid-feedback,
	~ .invalid-feedback {
		margin-top: 0;
	}

	~ .custom-control-label:before {
		.inverse & {
			@include box-shadow(inset 0 2px 4px rgba($black, .32));
		}

		&,
		.light & {
			@include box-shadow(inset 0 2px 4px rgba($blue-dark, .12));
		}
	}

	&:checked ~ .custom-control-label:before {
		.inverse & {
			border-color: $blue-bright;
			background-color: $blue-deep;
		}

		&,
		.light & {
			border-color: $blue-bright;
			background-color: $white;
		}
	}

	&:focus ~ .custom-control-label:before,
	&:focus:not(:checked) ~ .custom-control-label:before,
	&:not(:disabled):active ~ .custom-control-label:before {
		border-color: $blue-bright;
	}
	
	&:focus ~ .custom-control-label:before {
		.inverse & {
			@include box-shadow(inset 0 2px 4px rgba($black, .32), $input-focus-box-shadow);
		}

		&,
		.light & {
			@include box-shadow(inset 0 2px 4px rgba($blue-dark, .12), $input-focus-box-shadow);
		}
	}

	&:not(:checked) ~ .custom-control-label:before {
		.inverse & {background-color: $blue-deep;}

		&,
		.light & {background-color: $white;}
	}

	&[disabled],
	&:disabled {
		~ .custom-control-label {
			.inverse & {color: $gray03;}

			&,
			.light & {color: $gray06;}

			&:before {
				opacity: $custom-control-opacity-disabled;
			}
		}
	}
}

.custom-radio {
	.inverse & {
		.custom-control-input:checked ~ .custom-control-label {
			&:after {
				background-image: escape-svg($custom-radio-indicator-icon-checked-white);
			}
		}
	}

	&,
	.light & {
		.custom-control-input:checked ~ .custom-control-label {
			&:after {
				background-image: escape-svg($custom-radio-indicator-icon-checked);
			}
		}
	}
}

.custom-checkbox {
	.inverse & {
		.custom-control-input:checked ~ .custom-control-label {
			&:after {
				background-image: escape-svg($custom-checkbox-indicator-icon-checked-white);
			}
		}
	}

	&,
	.light & {
		.custom-control-input:checked ~ .custom-control-label {
			&:after {
				background-image: escape-svg($custom-checkbox-indicator-icon-checked);
			}
		}
	}
}
