@import "../../../bootstrap/functions";
@import "../../variables";
@import "../../../bootstrap/mixins";

.toggle {
    display: flex;
    align-items: center;
    text-align: left;

    >span {
        /* Label */
        padding-left: $base-unit * 0.75;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: $base-unit * 2;
        height: $base-unit * 0.875;
        margin-bottom: 0;

        /* Hide default HTML checkbox */
        input {
            opacity: 0;
            width: 0;
            height: 0;

            &:checked+.slider {
                user-select: none;
                background-color: rgba($orange-bright, 0.5);

                &:before {
                    user-select: none;
                    background-color: $orange-bright;
                    transform: translateX($base-unit * 0.75);
                }
            }

            &:focus+.slider {
                user-select: none;
                box-shadow: 0 0 ($base-unit * 0.0625) $orange-bright;
            }
        }
    }

    /* The slider */
    .slider {
        user-select: none;
        position: absolute;
        width: $base-unit * 2;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $orange-bright;
        transition: 0.4s;

        &:before {
            user-select: none;
            position: absolute;
            content: "";
            height: $base-unit * 1.25;
            width: $base-unit * 1.25;
            bottom: -$base-unit * 0.1875;
            background-color: $gray05;
            transition: 0.4s;
        }

        /* Rounded sliders */
        &.round {
            border-radius: $base-unit * 2.125;
            user-select: none;
            background-color: rgba($gray05, 0.5);

            &:before {
                border-radius: 50%;
                user-select: none;
            }
        }
    }
}

/* --- INVERSE --- */
.inverse {
    .toggle {
        >span {
            color: $white;
        }

        .switch {
            input {
                &:checked+.slider {
                    background-color: $gray05;

                    &:before {
                        background-color: $white;
                    }
                }
            }
        }
    }
}

/* --- LIGHT --- */
.light {
    .toggle {
        >span {
            color: $gray03;
        }
        .switch {
            input {
                &:checked+.slider {
                    background-color: rgba($orange-bright, 0.5);

                    &:before {
                        background-color: $orange-bright;
                    }
                }
            }
        }
    }
}

/* AR LANG */
body.rtl {
    .toggle {
        text-align: right;
        > span {
            padding-left: 0;
            padding-right: $base-unit * 0.75;
        }

        .slider {
            &.round {
                &:before {
                    right: $base-unit * 0.75;
                }
            }
        }
    }
}
