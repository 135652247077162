.popover {
	// font-family: $font-family-base; removed because font-family is now defined separatelty for each lang.
	min-width: $base-unit*11.5;
	text-align: center;
	z-index: map-get($z-index, cmp-popover);

	.popover-header {
		padding: $base-unit $base-unit $base-unit/2;
		border-bottom: none;

		@include media-breakpoint-up(lg) {
			padding: $base-unit*2 $base-unit*1.5 $base-unit/2;
		}
	}

	.popover-body {
		padding: 0 $base-unit $base-unit;
		font-size: $body-xs-font-size;
		color: $gray03;

		@include media-breakpoint-up(lg) {
			padding: 0 $base-unit*1.5 $base-unit*2;
			font-size: $body-md-font-size;
		}
	}

	/* DARK VARIANT */
	&.popover-dark {
		background-color: $blue-dark;
		box-shadow: 0 10px 20px 0 rgba($blue-dark, .2);
		border: none;

		.arrow:before {
			display: none;
		}

		.popover-header {
			color: $white;
		}

		.popover-body {
			color: $gray06;
		}


		&.bs-popover-top .arrow:after {
			border-top-color: $blue-dark;
		}

		&.bs-popover-right .arrow:after {
			border-right-color: $blue-dark;
		}

		&.bs-popover-bottom .arrow:after {
			border-bottom-color: $blue-dark;
		}

		&.bs-popover-left .arrow:after {
			border-left-color: $blue-dark;
		}
	}
}

[data-toggle="popover"] {
	&:focus {outline: none;}
}
