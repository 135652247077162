.form-select {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	box-shadow: 0 10px 20px -10px rgba(12, 5, 62, .2);
	padding: 10px 48px 10px 20px;
	background-color: $white;
	color: $blue-dark;
	font-size: 12px;
	line-height: normal;
	font-weight: 400;
	border-radius: 20px;
	position: relative;
	max-width: 300px;
	border: 1px solid $gray06;
	cursor: pointer;
	// added ellipsis
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	
	@include media-breakpoint-up(lg) {
		font-size: 14px;
	}

	&:hover {
		border: 1px solid $gray05;
	}

	&:focus {
		border-color: $blue-bright;
		outline: 0;
		@if $enable-shadows {
			box-shadow: $custom-select-box-shadow, $custom-select-focus-box-shadow;
		} @else {
			box-shadow: $custom-select-focus-box-shadow;
		}

		&::-ms-value {
			// For visual consistency with other platforms/browsers,
			// suppress the default white text on blue background highlight given to
			// the selected option text when the (still closed) <select> receives focus
			// in IE and (under certain conditions) Edge.
			// See https://github.com/twbs/bootstrap/issues/19398.
			color: $input-color;
			background-color: $input-bg;
		}
  	}
}

.form-select-arrow {
	position: relative;
	display: inline-block;

	&:after {
		content: url('web/i/arrows/icon-arrow-down.svg');
		display: block;
		position: absolute;
		top: 12px;
		right: 16px;
		pointer-events: none;
	}

	// IE11
	select::-ms-expand {
		display: none;
	}
}
