$areas: ('top', 'headline', 'product', 'platforms', 'smb-bundle-icons', 'smb-pickers', 'label', 'installments', 'price', 'price-old', 'note-above-cta', 'cta', 'cta-note', 'cta-secondary', 'smb-slot-bottom', 'footer', 'bottom');
$rows: length($areas);

$areasTable: ('top', 'product', 'platforms', 'smb-bundle-icons', 'smb-pickers', 'blank', 'table-section', 'bottom');
$rowsTableMobile: length($areasTable);


@function createTemplateAreas($count, $list) {
	$areas: null;

	//rows
	@for $x from 1 through length($list) {
		$item: nth($list, $x);
		$result: '';

		//cols
		@for $y from 1 through $count {
			@if $y > 1 { $result: $result + ' '; }
			$result: $result + $item+$y ;
		}
		$areas: $areas $result;
	}
	@return $areas;
}

@function createTemplateAreasMobile($count, $list) {
	$areas: null;

	//rows
	@for $x from 1 through $count {
		@each $item in $list {
			$areas: $areas $item+$x;
		}
	}
	@return $areas;
}

@function createTemplateAreasMobileTable($count, $list) {
	$areas: null;

	// each box
	@for $x from 1 through $count {
		$result: nth($list, 1)+$x + ' ' + nth($list, 1)+$x;

		// each item in list (until table-section which should be second from the end of the list)
		@for $y from 2 through length($list)-2 {
			$item: nth($list, $y);

			$result: $result $item+$x + ' ' + 'table-section'+$x;
		}

		$areas: $areas $result nth($list,  length($list))+$x + ' ' + nth($list, length($list))+$x;
	}
	@return $areas;
}

@function repeatIeValue($count, $value) {
	$result: '';

	@for $x from 1 through $count {
		@if $x > 1 { $result: $result + ' '; }
		$result: $result + $value ;
	}
	@return $result;
}

// SMB Structure styles
.abox-wrap.abox-smb .abox-container {
	// SMB GRID Areas Assesment
	@for $i from 1 through 5 {
		&.boxcount-#{$i} {
			grid-template-rows: repeat($rows, auto);
			grid-template-columns: repeat($i, auto);
			grid-template-areas: createTemplateAreas($i, $areas);

			//IE
			display: -ms-grid;
			-ms-grid-rows: repeatIeValue($rows, auto);
			-ms-grid-columns: repeatIeValue($i, auto);
			// /IE

			// Assign grid area names to listed elements
			@each $area in $areas {
				@for $j from 1 through $i {
					.abox-#{$area}[abox="#{$j}"] {
						grid-area: #{$area}#{$j};
						-ms-grid-column: $j;
						-ms-grid-row: index($areas, $area);
					}
				}
			}

			@include media-breakpoint-down(md) {
				grid-template-rows: repeat($rows*$i, auto);
				grid-template-columns: 1fr;
				grid-template-areas: createTemplateAreasMobile($i, $areas);
			}
		}
	}


	/* PROMO SMB Overlay
	- Overalay div is rendered only on multibox and if in table
	- Single box has just orange border when promoted
	- VUE will check if promo column is above multibox and if this column is first or last multibox column and create proper class for overlay div */
	&[class*=promo-] {
		.promo-frame {
			grid-row-start: 1;
			grid-row-end: $rows + 1;
			-ms-grid-row: 1;
			-ms-grid-row-span: $rows;
		}

		// 'promo-x' classes individual styles
		@for $p from 1 through 5 {
			&.promo-#{$p} {
				.promo-frame {
					grid-column-start: $p;
					grid-column-end: $p + 1;
					-ms-grid-column: $p;

					@include media-breakpoint-down(md) {
						grid-column-start: 1;
						grid-column-end: 2;
						-ms-grid-column: 1;

						$m: $rows * ($p - 1); // vertical position = slots count * aboxu number

						grid-row-start: $m + 1;
						grid-row-end: $rows*$p + 1;
						-ms-grid-row: 1;
						-ms-grid-row-span: $rows;
					}
				}
			}
		}
	}
}

// Change of SMB GRID layout in the mobile table
.tables-common {
	.abox-wrap.abox-smb .abox-container {
		@for $m from 1 through 5 {
			&[class*=promo-].show-toggler {
				.abox-toggler {
					@include media-breakpoint-only(md) {
						&[span-start="#{$m}"] {
							grid-column-start: $m;
							-ms-grid-column: $m;

							grid-row-start:1;
							grid-row-end:2;
						}

						&[span-end="#{$m}"] {
							grid-column-end: span $m;
							-ms-grid-column-span: $m;

							&.nospan {
								grid-column-end: $m;
							}
						}
					}
					
					// give an area name to toggler so it can be positioned.
					@include media-breakpoint-down(sm) {
						@for $k from 1 through $m {
							&[set="#{$k}"] {
								grid-area: toggler#{$k};
							}
						}
					}
				}
			}

			// šířka sloupců, měla by být na nejvetších rozlišeních. Přetluče se to až na breakpointu podle toho, kolik má tabulka sloupců
			&.boxcount-#{$m} {
				grid-template-columns: repeat($m, 1fr);
				grid-template-areas: createTemplateAreas($m, $areas); // override of different breakpoint rule in normal abox

				@include media-breakpoint-down(sm) {
					grid-template-rows: repeat(($rowsTableMobile * $m), auto);
					grid-template-columns: 1fr 1fr;
					grid-template-areas: createTemplateAreasMobileTable($m, $areasTable);
					column-gap: 8px;

					// Assign grid area names to listed elements
					@each $area in $areasTable {
						@for $j from 1 through $m {
							.abox-#{$area}[abox="#{$j}"] {
								grid-area: #{$area}#{$j};
							}
						}
					}
				}
			}
		}
	}
}