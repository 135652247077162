/*******
Product Icons
*******/
.product-icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	&.box {
		background-color: $orange-bright;
		border-radius: 20%;

		&.light { //for color ASB icon and for A1 in future (WIP)
			background-color: $white;
			border: 1px solid $gray08;

			&.shadow { //shadow is set accordingly to hero banner usage
				box-shadow: 0 15px 30px -15px rgba($blue-dark, .3) !important;
			}
		}

		&.smb:not(.light) {
			background-color: $blue-bright;
		}
	}

	&.x-large {
		img {
			width: $base-unit*5;
			height: $base-unit*5;

			@include media-breakpoint-down(md) {
				width: $base-unit*3;
				height: $base-unit*3;
			}
		}

		&.box img {
			margin: $base-unit*.5625;
		}
	}

	&.large {
		img {
			width: $base-unit*3;
			height: $base-unit*3;

			@include media-breakpoint-down(md) {
				width: $base-unit*2.5;
				height: $base-unit*2.5;
			}
		}

		&.box img {
			margin: $base-unit*.375;
		}
	}

	&.medium {
		img {
			width: $base-unit*2.5;
			height: $base-unit*2.5;

			@include media-breakpoint-down(md) {
				width: $base-unit*2;
				height: $base-unit*2;
			}
		}

		&.box img {
			margin: $base-unit*.3125;
		}
	}

	&.small {
		img {
			width: $base-unit*2;
			height: $base-unit*2;
		}

		&.box img {
			margin: $base-unit/4;
		}
	}
}
