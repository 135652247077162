.cmp-collapse {
	cursor: pointer;
	
	img,
	.collapse-icon,
	.btn-icon { transition: all ease-in .1s; }

	&.button-circle {
		img,
		.collapse-icon,
		.btn-icon {
			position: relative;
			top: 0;
			left: 0;
			transform: none;
		}
	}

	.hide-label,
	.show-label {
		border: none;
		text-decoration: none;
		line-height: inherit;
		font-size: inherit;
	}

	&.collapsed {
		.hide-label { display: none; }
		
		.show-label { display: inline-block; }

		img,
		.collapse-icon,
		.btn-icon {
			transform: rotate(0deg);
		}
	}
	
	&:not(.collapsed) {
		.hide-label { display: inline-block; }
		
		.show-label { display: none; }

		&.rotate45 {
			img,
			.collapse-icon,
			.btn-icon { transform: rotate(45deg); }
		}

		&.rotate90 {
			img,
			.collapse-icon,
			.btn-icon { transform: rotate(90deg); }
		}

		&.rotate180 {
			img,
			.collapse-icon,
			.btn-icon { transform: rotate(180deg); }
		}
		
		&.rotate-45 {
			img,
			.collapse-icon,
			.btn-icon { transform: rotate(-45deg); }
		}
		
		&.rotate-90 {
			img,
			.collapse-icon,
			.btn-icon { transform: rotate(-90deg); }
		}
		
		&.rotate-180 {
			img,
			.collapse-icon,
			.btn-icon { transform: rotate(-180deg); }
		}
	}
}