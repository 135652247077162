/* Badge */
.badge {
	text-transform: uppercase;
	font-weight: 800;
	color: $blue-dark;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: $base-unit*.5;
	text-overflow: ellipsis;
	white-space: nowrap;

	&.bg-blue-bright,
	&.bg-blue-mid {
		color: $white;
	}

	// Override bootstrap and old classes (for SMB priceboxes)
	&-primary {
		background-color: $blue-bright;
		color: $white;
	}

	&-secondary {
		background-color: $orange-bright;
	}

	&-orange-pale,
	&-primary-alt {
		background-color: $orange-pale;
	}

	&-secondary-alt {
		background-color: $orange-light;
	}
	// *//

	&.bg-white {
		border: solid 1px $gray07;
	}

	&.bg-blue-mid {
		border: solid 1px $blue-mid-mid;
	}

	/* Size modifications */
	&.badge-lg {
		font-size: 10px;
		line-height: 10px;
		letter-spacing: 1.5px;
		padding: 0 $base-unit*.75;
		height: 28px;
		margin-bottom: $base-unit;

		@include media-breakpoint-up(lg) {
			font-size: 13px;
			line-height: 13px;
			letter-spacing: 2px;
			padding: 0 $base-unit;
			height: $base-unit*2;
		}
	}

	&.badge-sm,
	&.badge-xs {
		font-size: 9px;
		line-height: 9px;
		letter-spacing: 1.5px;
		padding: 0 $base-unit/2;
		height: 20px;

		@include media-breakpoint-up(lg) {
			font-size: 11px;
			line-height: 11px;
			letter-spacing: 1.5px;
			padding: 0 $base-unit*.75;
			height: $base-unit*1.5;
		}
	}
}


.tables-common,
.action-box__label {
	.badge {
		height: auto;
		padding-top: 4px;
		padding-bottom: 4px;
		white-space: initial;

		@include media-breakpoint-up(lg) {
			padding-top: 6px;
			padding-bottom: 6px;
		}
	}
}