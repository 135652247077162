.list-icons {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	div:last-child {
		margin-bottom: 0;
	}

	.list-icons__item {
		display: flex;
		align-items: flex-start;
		margin-bottom: $base-unit/2;

		&:not(.nofilter) .icon:not([class*='filter-']) {
			filter: $filter-orange-bright;
		}

		.icon {
			margin-right: $base-unit/2;
			flex-shrink: 0;
		}

		.list-icons__content {
			flex: 1 1 auto;
			text-align: left;
		}

		.list-icons__title {
			margin: 0;

			&:not(.h7) {
				font-weight: 700;
			}
		}

		.list-icons__text {
			margin: 0;
		}


		// multiple icons variant
		.list-icons__icons {
			display: flex;
			align-items: center;
		}
	}

	&.list-icons--large {
		.list-icons__content {
			padding-top: $base-unit/8;

			@include media-breakpoint-up(lg) {
				padding-top: $base-unit/4;
			}
		}
	}

	&.list-icons--small {
		.list-icons__item {
			margin-bottom: $base-unit/2;

			.icon {
				margin-right: $base-unit/2;
				margin-top: 1px;

				@include media-breakpoint-up(lg) {
					margin-right: $base-unit/2;
					margin-top: 4px;
				}
			}
		}
	}


	// Vertical Centered
	&.list-icons--center {
		align-items: center;
	}


	// Vertical Inline
	&.inline {
		@include media-breakpoint-up(md) {
			flex-direction: row;
			justify-content: center;

			.list-icons__item {
				margin: 0 $base-unit*2 $base-unit/2;
			}
		}
	}

	&.list-icons--horizontal {
		flex-direction: row;
		justify-content: center;

		.list-icons__item {
			justify-content: center;
			padding-top: 0;
			margin: 0 $base-unit*2 $base-unit $base-unit*2;

			@include media-breakpoint-down(md) {
				flex-direction: column;
				align-items: center;
				margin: 0 $base-unit/2 $base-unit $base-unit/2;

				.list-icons__content {
					text-align: center;
					padding-top: 0;
				}

				.icon {
					margin: 0 0 $base-unit/2 0;
				}
			}
		}

		&.list-icons--large {
			.list-icons__item {
				flex-direction: column;
				align-items: center;

				.list-icons__content {
					text-align: center;
					padding-top: 0;
					max-width: 316px;
				}

				@include media-breakpoint-down(md) {
					margin: 0 $base-unit*1.25 $base-unit $base-unit*1.25;
				}

				.icon {
					margin: 0 0 $base-unit/2 0;
				}
			}
		}
	}

	//	Multiple icons variant
	&.list-icons--multiple-icons {
		flex-direction: row;
		justify-content: center;

		.list-icons__item {
			flex-direction: column;
			align-items: center;
			margin: 0 $base-unit*2 $base-unit $base-unit*2;
			max-width: 218px;

			@include media-breakpoint-down(md) {
				flex-basis: 100%;
				max-width: 100%;
				margin: 0 0 $base-unit*1.5 0;
			}

			@media (min-width: $breakpoint-custom-lg1) {
				max-width: 316px;
				width: 100%;
				margin-left: $base-unit;
				margin-right: $base-unit;
			}

			.icon {
				margin: 0 0 $base-unit/4 0;

				@include media-breakpoint-up(lg) {
					margin: 0 $base-unit/4 $base-unit/2 $base-unit/4;
				}
			}

			.list-icons__content {
				max-width: 316px;
				text-align: center;
			}
		}
	}
}
