.modal {
	.modal-content {
		text-align: initial;
		box-shadow: none;
		border-radius: $base-unit * 1.5;

		video,
		iframe {
			max-width: 100%;
		}

		.modal-oops {
			text-align: center;

			&-title,
			&-desc {
				margin-bottom: $base-unit*1.5;
			}

			&-badge {
				display: flex;
				justify-content: center;

				img {
					width: 189px;
					max-width: 100%;
					margin: 0 auto $base-unit*1.5 auto;
				}
			}

			&-btn-requested,
			&-btn {
				margin-bottom: $base-unit*1.5;

				.storebadge-google,
				.storebadge-apple {
					height: 56px;
					max-width: 100%;
					margin: 0 auto;
				}
			}

			&-back {
				font-size: $base-unit*.75;
				line-height: $base-unit;
			}
		}
	}

	.modal-body {
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.modal-header {
		border: none;

		.modal-close {
			position: absolute;
			top: ($base-unit * 2.875);
			right: ($base-unit * 2);
			padding: 0;
			z-index: 9;
			opacity: 1;
			margin: 0;

			@include media-breakpoint-down(sm) {
				top: ($base-unit * 1);
				right: ($base-unit * 1);
			}
		}
	}

	&.modal--full {
		.modal-dialog {
			max-width: 100%;
			margin: 0;
			height: 100%;
		}

		.modal-content {
			border-radius: 0;
			padding: 0 8% ($base-unit * 8) 8%;
			height: 100%;

			@include media-breakpoint-down(sm) {
				padding: 0 ($base-unit * 2) ($base-unit * 2) ($base-unit * 2);
			}
		}

		.modal-header {
			padding: ($base-unit * 8) 0 0 0;

			.modal-close {
				top: ($base-unit * 2.5);
				right: ($base-unit * 2.5);

				@include media-breakpoint-down(sm) {
					top: ($base-unit * 1);
					right: ($base-unit * 1);
				}
			}
		}
	}

	&.modal--large {
		.modal-dialog {
			max-width: 1128px;
			margin: ($base-unit * 6) auto;

			@media (max-width: 1280px) {
				max-width: 90%;
			}
		}

		.modal-content {
			padding: 0 ($base-unit * 7.25) ($base-unit * 5) ($base-unit * 7.25);

			@include media-breakpoint-down(sm) {
				padding: 0 ($base-unit * 2) ($base-unit * 2) ($base-unit * 2);
			}
		}

		.modal-header {
			padding: ($base-unit * 3) 0 0 0;

			.modal-close {
				top: ($base-unit * 2.5);
				right: ($base-unit * 2);

				@include media-breakpoint-down(sm) {
					top: ($base-unit * 1);
					right: ($base-unit * 1);
				}
			}
		}
	}

	&.modal--medium {
		.modal-dialog {
			max-width: 663px;
			margin: ($base-unit * 6) auto;

			@include media-breakpoint-down(sm) {
				width: 90%;
			}
		}

		.modal-content {
			padding: 0 ($base-unit * 3) ($base-unit * 3) ($base-unit * 3);

			@include media-breakpoint-down(sm) {
				padding: 0 ($base-unit * 2) ($base-unit * 2) ($base-unit * 2);
			}
		}

		.modal-header {
			padding: ($base-unit * 3.5) 0 0 0;

			.modal-close {
				top: ($base-unit * 1.25);
				right: ($base-unit * 1.25);

				@include media-breakpoint-down(sm) {
					top: $base-unit;
					right: $base-unit;
				}
			}
		}
	}

	&.modal--small {
		.modal-dialog {
			max-width: 432px;
			margin: ($base-unit * 6) auto;

			@include media-breakpoint-down(sm) {
				width: 90%;
			}
		}

		.modal-content {
			padding: 0 ($base-unit * 3) ($base-unit * 3) ($base-unit * 3);

			@include media-breakpoint-down(sm) {
				padding: 0 ($base-unit * 2) ($base-unit * 2) ($base-unit * 2);
			}
		}

		.modal-header {
			padding: ($base-unit * 3.5) 0 0 0;

			.modal-close {
				top: ($base-unit * 1.25);
				right: ($base-unit * 1.25);

				@include media-breakpoint-down(sm) {
					top: $base-unit;
					right: $base-unit;
				}
			}
		}
	}
}

.modal-backdrop {
	background: linear-gradient(180deg, $blue-dark 2.37%, $blue-mid 100%);

	&.show {
		opacity: .8;
	}
}

.overlay {
	display: none;
	position: fixed;
	z-index: 99;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: linear-gradient(180deg, $blue-dark 2.37%, $blue-mid 100%);

	.overlay-open & {
		display: block;
	}
}

// Style for non href links, in hero for example
.modal-oops-hint-link {
	text-decoration: underline;
	font-weight: 700;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
