.unsupported-browser-message {
	display: none;
	background-color: $status-critical;
	color: $white;
	padding-top: $base-unit*2;
	padding-bottom: $base-unit*2;
	text-align: center;

	p {
		margin-bottom: 0;
	}

	a {
		color: $white;
		text-decoration: underline;
	}
}

.notSupportedBrowser {
	.unsupported-browser-message {
		display: block;
	}
}

// GLOWEB-2258
[lang='en'],
[class*="mod-fr-fr"],
[class*="mod-pt-br"] {
	.unsupported-browser-message {
		background-color: #E22D15;

		p {
			font-size: $base-unit*.875;

		}
	}
}
// /GLOWEB-2258
