.tables {
	width: 100%;

	th,
	td {
		padding: $base-unit $base-unit/2;
		vertical-align: middle;
		position: relative;
		background-clip: padding-box;
		border-bottom: 1px solid $table-border-color;

		.inverse & { border-bottom: 1px solid $gray02; }

		&.va-bottom { vertical-align: bottom; }

		&.va-top { vertical-align: top; }

		&.va-middle { vertical-align: middle; }


		@include media-breakpoint-up(lg) {
			&.highlight {
				border-left: 2px solid $orange-bright;
				border-right: 2px solid $orange-bright;

				&-top {
					border-top: 2px solid $orange-bright;

					.inverse & { border-top: 2px solid $orange-bright; }
				}

				&-bottom {
					border-bottom: 2px solid $orange-bright;

					.inverse & { border-bottom: 2px solid $orange-bright; }
				}
			}
			&.media-highlight {
				border-left: 2px solid $orange-pale;

				&-top {
					border-top: 2px solid $orange-pale;
				}

				&-bottom {
					border-bottom: 2px solid $orange-pale;
				}
			}
		}
	}

	tbody td {
		.icon { margin: 0 auto; }
	}

	&__title {
		margin-bottom: 0;
	}

	&__text {
		font-weight: normal;
		margin-bottom: 0;
		color: $table-color;

		.inverse & {
			color: $white;
		}

		&--lines {
			strong,
			span {
				display: block;
			}

			span {
				color: $table-color;
			}
		}
	}

	&--simplified {
		border-color: transparent;
		border-style: none;
		border-collapse: collapse;

		@include media-breakpoint-down(sm) {
			display: block;

			thead,
			tbody,
			tfoot {
				display: block;
			}
		}

		h6,
		.h6 {
			margin: 0;
			//color: $table-color;
			//font-size: .75rem;
			//text-transform: uppercase;
			//letter-spacing: 1px;
			//line-height: 1rem;

			//.inverse & { color: $white; }
		}

		th,
		td {
			text-align: center;

			&.noborder-left { border-left: transparent; }

			&.noborder-bottom { border-bottom: transparent; }

			&.noborder-top { border-top: transparent; }
		}

		thead {
			@include media-breakpoint-down(sm) {
				position: -webkit-sticky;
				position: sticky;
				top: 0;
				z-index: 3;
			}

			tr {
				@include media-breakpoint-down(sm) {
					display: flex;
					flex-wrap: wrap;
				}

				th,
				td {
					text-align: center;
					border-color: transparent;
					vertical-align: top;
					padding: ($base-unit*1.25) ($base-unit/2);

					@include media-breakpoint-down(sm) {
						background-color: $white;
						padding: 0;
						border-bottom-color: transparent;

						.inverse & {
							background-color: $blue-dark;
							border-bottom-color: transparent;
						}
					}
				}
				th {
					.media-table & {
						border-bottom-color: $white;
					}
				}
			}
		}

		tbody {
			tr {
				@include media-breakpoint-down(sm) {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
				}

				td,
				th {
					@include media-breakpoint-up(md) {
						min-height: 56px;
					}

					.inverse & {
						background-color: $blue-deep;

						&.bg-tinged {
							background-color: $blue-mid;
						}
					}
				}

				th {
					text-align: initial;
					.media-table & {
						border-bottom: 1px solid $orange-pale;
						@include media-breakpoint-down(sm) {
							border-color: $white;
						}
					}
					.inverse & {
						background-color: $blue-dark;
					}
				}

				td {
					min-width: 160px;

					@include media-breakpoint-down(md) {
						min-width: 120px;
					}
					@include media-breakpoint-down(sm) {
						flex: 1;
						min-width: 0;
					}
				}
			}
		}

		tfoot {
			tr {
				@include media-breakpoint-down(sm) {
					display: flex;
					flex-wrap: wrap;
				}

				td,
				th {
					padding: $base-unit*2 $base-unit;
					border-bottom-color: transparent;

					.inverse & { border-bottom-color: transparent; }

					@include media-breakpoint-down(sm) {
						padding: 0;
						position: relative;
						z-index: 9;
					}
				}
			}
		}

		.tables__headline {
			@include media-breakpoint-down(sm) {
				flex: 1;
				padding: ($base-unit*1.25) ($base-unit/2);
			}
		}

		.tables__footline {
			@include media-breakpoint-down(sm) {
				flex: 1;
				padding: ($base-unit*1.25) ($base-unit/2);
			}
		}

		.tables__desc {
			@include media-breakpoint-down(sm) {
				flex: 1 1 100%;
				border-bottom: none;
				text-align: center;
			}

			span {
				display: block;
			}
		}

		&.tables--col-2 {
			.tables__headline {
				flex-basis: 50%;
				max-width: 50%;
			}
		}

		&.tables--col-3 {
			.tables__headline {
				flex-basis: 33.3%;
				max-width: 33.3%;
			}
		}

		&.tables--col-4 {
			.tables__headline {
				flex-basis: 25%;
				max-width: 25%;
			}
		}
	}

	/* PRODUCT TABLE */
	&--product {
		border-color: transparent;
		border-style: none;

		@include media-breakpoint-down(sm) {
			display: block;

			thead,
			tbody,
			tfoot {
				display: block;
			}
		}

		h3,
		.h3 {
			margin: 0;
			color: $table-color;

			.inverse & {
				color: $white;
			}
		}

		th,
		td {
			text-align: left;

			&:nth-child(2) {
				border-left: 1px solid $table-border-color;

				.inverse & {
					border-left: 1px solid $gray02;
				}
			}

			@include media-breakpoint-down(md) {
				&:last-child.highlight {
					border-right: 1px solid $table-border-color;
				}
			}

			&:last-child:not(.highlight) {
				border-right: 1px solid $table-border-color;

				.inverse & {
					border-right: 1px solid $gray02;
				}
			}

			@include media-breakpoint-up(lg) {
				&:nth-child(2) {
					.inverse & {
						&.highlight {
							border-left: 2px solid $orange-bright;
						}
					}
				}

				&.noborder-left {
					border-left: transparent !important;
				}

				&.noborder-right {
					border-right: transparent !important;
				}

				&.noborder-bottom {
					border-bottom: transparent !important;
				}

				&.noborder-top {
					border-top: transparent !important;
				}
			}
		}

		thead {
			tr {
				@include media-breakpoint-down(md) {
					display: flex;
					flex-wrap: wrap;
					margin: 0 -1px;
				}

				th {
					vertical-align: top;
					border-top: 1px solid $table-border-color;
					border-bottom: 1px solid transparent;
					padding: $base-unit*1.25;
					background-color: $white;

					.inverse & {
						border-top: 1px solid $blue-mid;
						background-color: $blue-dark;
					}

					&:nth-child(2) {
						border-left: 1px solid $table-border-color;

						.inverse & {
							border-left: 1px solid $blue-mid;
						}
					}

					&:first-child {
						background-color: transparent;
						border-color: transparent;
						padding: $base-unit*1.25 $base-unit/2;

						.inverse & {
							border-bottom: 1px solid $gray02;
						}

						@include media-breakpoint-up(lg) {
							background-color: $white;

							//bg color for this th cell will be set by parent bg class
							@each $color, $value in $colors-map {
								[class*='bg-'] .bg-#{$color} &,
								.bg-#{$color} & {
									background-color: $value;
								}
							}
							@each $gray, $value in $grays-map {
								[class*='bg-'] .bg-#{$gray} &,
								.bg-#{$gray} & {
									background-color: $value;
								}
							}

							.inverse & {
								background-color: $blue-dark;
							}
						}
					}

					@include media-breakpoint-down(md) {
						background-color: $white;
						padding: 0;

						.inverse & {
							background-color: $blue-dark;
						}

						&.tables__desc {
							display: none;
						}
					}
				}

				&.emptyrow {
					th,
					td {
						padding: ($base-unit/2);

						&:first-child {
							border-color: transparent;
						}
					}
				}
			}
		}

		tbody {
			tr {
				@include media-breakpoint-down(md) {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					margin: 0 -1px;
				}

				td,
				th {
					@include media-breakpoint-up(lg) {
						min-height: 40px;

						.inverse & {
							border-bottom: 1px solid $gray02;
						}
					}
				}

				th {
					width: 33.3%;
					text-align: left;
					background-color: $gray10;

					@include media-breakpoint-up(lg) {
						background-color: transparent;
					}
					@include media-breakpoint-down(md) {
						text-align: center;
					}

					.inverse & {
						background-color: transparent;
					}
				}

				td {
					width: 66.6%;
					background-color: $white;
					text-align: center;

					.inverse & {
						background-color: $blue-deep;

						&.bg-tinged {
							background-color: $blue-mid;
						}
					}

					@include media-breakpoint-down(md) {
						flex: 1;
						min-width: 0;
					}
				}

				&:last-child {
					td,
					th {
						border-bottom: 1px solid $table-border-color;

						.inverse & {
							border-bottom: 1px solid $gray02;

							@include media-breakpoint-up(lg) {
								&.highlight-bottom {
									border-bottom: 2px solid $orange-bright;
								}
							}
						}
					}
				}

				&.emptyrow {
					th,
					td {
						padding: ($base-unit/2);

						&:first-child {
							border-color: transparent;
						}
					}
				}
			}
		}

		tfoot {
			tr {
				@include media-breakpoint-down(md) {
					display: flex;
					flex-wrap: wrap;
					margin: 0 -1px;
				}

				td,
				th {
					padding: $base-unit*2 $base-unit;
					vertical-align: top;
					background-color: $white;

					.inverse & { background-color: $blue-dark; }

					&:first-child {
						background-color: transparent;
						border-color: transparent;

						@include media-breakpoint-down(sm) {
							display: none;
						}
					}

					@include media-breakpoint-down(md) {
						padding: 0;

						&.tables__desc {
							background-color: $white;
							text-align: center;
							padding: ($base-unit*1.25) ($base-unit/2);
							border-left: 1px solid $table-border-color;
							border-right: 1px solid $table-border-color;

							.inverse & { background-color: $blue-dark; }
						}
					}
					@include media-breakpoint-down(sm) {
						border-bottom: 1px solid $table-border-color;
						border-left: 1px solid $table-border-color;
						border-right: 1px solid $table-border-color;

						&.noborder-bottom {
							border-bottom: 1px solid $table-border-color;

							.inverse & { background-color: $blue-dark; }
						}
					}
				}

				td {
					text-align: center;

					@include media-breakpoint-up(md) {
						border-left-color: transparent;
					}
				}

				th {
					@include media-breakpoint-down(sm) {
						border-bottom: 1px solid $table-border-color;
					}
				}

				&.emptyrow {
					@include media-breakpoint-down(sm) {
						display: none;
					}

					th,
					td {
						padding: ($base-unit/2);

						&:first-child {
							border-color: transparent;
						}

						@include media-breakpoint-down(md) {
							&.tables__desc {
								display: none;
							}
						}
					}
				}

				&:last-child {
					td:not(.highlight) {
						border-bottom: 1px solid $table-border-color;

						.inverse & {
							border-bottom-color: $gray02;
						}

						@include media-breakpoint-down(sm) {
							border-color: $white;
						}

						&:first-child {
							border-bottom: none;

							@include media-breakpoint-down(sm) {
								background-color: transparent;
							}
							@include media-breakpoint-up(lg) {
								background-color: transparent;
							}
						}
					}

					td.highlight {
						@include media-breakpoint-down(sm) {
							border: transparent;
						}
					}

					th {
						border-bottom: none;

						@include media-breakpoint-down(sm) {
							border: transparent;
							background-color: transparent;
						}
						@include media-breakpoint-up(lg) {
							border: transparent;
							background-color: transparent;
						}
					}
				}
			}
		}

		.tables__headline {
			padding: 24px;
			text-align: center;

			@include media-breakpoint-down(md) {
				flex: 1;
				padding: $base-unit*1.5 $base-unit*.2;
			}
		}

		.tables__footline {
			@include media-breakpoint-down(md) {
				flex: 1;
				padding: $base-unit*1.25 $base-unit/2;
			}
			@include media-breakpoint-down(sm) {
				padding: $base-unit $base-unit*1.25 0;
				flex: 1 1 100%;

				//mobile Actionbox has different toggler
				.action-box__toggler {
					left: 0;
					transform: none;

					.toggler-select .form-select-arrow .form-select {
						border: none;
						box-shadow: none;
						padding-left: 0;
					}
				}

				.tables-product__platform {
					margin-bottom: $base-unit/2;
					gap: $base-unit/2;
				}
			}

			.action-box-wrap,
			.try-box-wrap {
				@include media-breakpoint-down(sm) {
					padding-bottom: $base-unit*1.5;
				}
			}

			&:not(:last-child) .action-box-wrap,
			&:not(:last-child) .try-box-wrap {
				//separating line between A-boxes on mobile
				@include media-breakpoint-down(sm) {
					border-bottom: 1px solid $gray08;
				}
			}

			.action-box {
				border: none;
				padding-left: 0;
				padding-right: 0;
				width: 190px;

				@include media-breakpoint-up(lg) {
					width: 200px;
				}

				//mobile Actionbox has different alignment
				@include media-breakpoint-down(sm) {
					width: auto;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding-bottom: 0;
					margin-bottom: 0;

					// compatibility with Abox structure for v2 table component
					.table-mobile-section {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
					}

					.action-box__cta {
						padding: $base-unit/2 0;
					}

					.action-box__price {
						.currency,
						.integer,
						.decimal {
							font-size: $base-unit*1.25;
							line-height: $base-unit*1.125;
						}

						.price-prefix,
						.price-suffix {
							margin-top: 0;
							display: flex;
						}
					}

					.cta-note {
						text-align: initial;
					}

					.action-box-content {
						text-align: initial;

						&__platform {
							justify-content: flex-start;
						}
					}
				}
			}
			
			.show-toggler .action-box {
				//mobile Actionbox has different alignment
				@include media-breakpoint-down(sm) {
					padding-top: $base-unit*3; // space under toggler needs to be bigger
				}
			}

			//Try box for SMB
			.action-box-container {
				.inverse &:not(.light) {
					.action-box {
						background-color: transparent;
						border-color: transparent;
					}

					@include media-breakpoint-down(sm) {
						.action-box__toggler .toggler-select .form-select-arrow .form-select {
							background-color: transparent;
							border-color: transparent;
							box-shadow: none;
						}
					}
				}
			}

			.try-box {
				margin: $base-unit*6 auto 0;
				border: none;
				width: 190px;

				@include media-breakpoint-up(lg) {
					width: 200px;
				}

				.actionbox-container,
				.try-box-container {
					box-shadow: none;
					max-width: 100%;
					width: auto;
					padding-bottom: 0;

					@include media-breakpoint-down(sm) {
						padding-top: 0;
					}

					.box {
						width: auto;
						padding: 0;

						//mobile Try-box has different alignment
						@include media-breakpoint-down(sm) {
							align-items: flex-start;

							.content-instead-toggler {
								text-align: initial;
							}
						}
					}
				}

				//hotfix  FIXME - too much height
				.labelActive {
					flex: auto;
				}

				.try-box-container {
					padding: 0 0 37px 0;

					@include media-breakpoint-down(md) {
						padding: 0;
					}
				}

				.footer-wrap {
					//mobile Try-box has different alignment
					@include media-breakpoint-down(sm) {
						align-items: flex-start;
						flex-direction: column;

						.btn-wrapper {
							margin: $base-unit/2 0 0;
						}
					}
				}

				@include media-breakpoint-down(sm) {
					margin: 0;

					.form-select-container {
						position: relative;
						top: 0;
					}
				}

				//Try box for SMB
				.inverse &:not(.light) {
					background-color: transparent;
					border-color: transparent;
				}
			}
		}

		.tables__hidden {
			display: none;

			&.is-active {
				display: table-row;

				@include media-breakpoint-down(md) {
					display: flex;
				}
			}
		}

		.tables__desc {
			@include media-breakpoint-down(md) {
				flex: 1 1 100%;
				border-left: 1px solid $table-border-color;
				border-right: 1px solid $table-border-color;
				border-bottom: none;

				.inverse & {
					border-left-color: $gray02;
					border-right-color: $gray02;
					border-bottom: none;
				}
			}
		}

		.tables__productIcons {
			display: flex;
			align-items: center;

			img {
				margin-right: $base-unit/2;
			}

			.tables__text {
				flex: 1;

				strong,
				span {
					margin-right: $base-unit*.3;
				}
			}

			@include media-breakpoint-down(md) {
				justify-content: center;
				display: inline-flex;
				text-align: left;
			}
		}

		.tables__text {
			&.tables__text--tooltip {
				display: flex;
				align-items: center;

				@include media-breakpoint-down(md) {
					justify-content: center;
					display: inline-flex;
					text-align: left;
				}

				span,
				.text__tooltip {
					margin-right: $base-unit;
					flex: 1;
				}
			}
		}

		.tables__tooltip {
			@include sprite-image("icons", "info", 16);

			margin-left: auto;
			cursor: pointer;
			border: none;

			&:hover,
			&:focus {
				@include sprite-image("icons", "hover-info", 16);
				outline: none;
			}

			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		.tables__textWithTooltip {
			display: flex;
			align-items: center;

			.tables__text {
				flex: 1;
				margin-right: $base-unit;
			}

			@include media-breakpoint-down(md) {
				justify-content: center;
				display: inline-flex;
				text-align: left;
			}
		}



		&.tables-offset-icons {
			.tables__productIcons .product-icon {
				margin-left: -30px;
				margin-right: 10px;
			}
		}

		&.tables--col-2 {
			td {
				width: 33.3%;
			}
		}

		&.tables--col-3 {
			td {
				width: 22.2%;
			}
		}

		&.tables--col-4 {
			td {
				width: 16.6%;
			}

			tbody {
				th {
					width: 25%;
				}
			}
		}
	}

	&--sticky {
		thead {
			@include media-breakpoint-down(md) {
				position: -webkit-sticky;
				position: sticky;
				top: 0;
				z-index: 3;
			}

			tr {
				@include media-breakpoint-down(md) {
					position: -webkit-sticky;
					position: sticky;
					top: 0;
					z-index: 3;
				}

				th {
					position: -webkit-sticky;
					position: sticky;
					top: 0;
					z-index: 3;

					@include media-breakpoint-up(lg) {
						&.highlight-top:before {
							content: "";
							width: 100%;
							height: 2px;
							background-color: $orange-bright;
							position: absolute;
							top: -2px;
							left: 0;
						}
					}
				}
			}
		}

		tfoot {
			tr td {
				position: relative;
				z-index: 9;

				@include media-breakpoint-up(lg) {
					&.highlight-bottom:before {
						content: "";
						width: 100%;
						height: 1px;
						background-color: $orange-bright;
						position: absolute;
						bottom: -1px;
						left: 0;
					}
				}
			}
		}
	}

	&-foot {
		&__moneyback {
			margin-bottom: $base-unit*1.25;

			.money-back__item {
				padding-top: 0;
				margin: 0;
			}

			.money-back--large {
				.money-back__item {
					padding-top: 0;
					margin: 0;
				}
			}

			@include media-breakpoint-down(md) {
				.money-back {
					justify-content: center;
				}
			}
		}

		&__more {
			margin-bottom: $base-unit;

			@include media-breakpoint-up(lg) {
				margin-bottom: $base-unit*2.75;
			}
		}

		&__help {
			font-size: $base-unit*.85;
			line-height: 1.71;
			font-weight: 400;
			color: $blue-dark;

			.inverse & {
				color: $white;
			}
		}
	}

	&__afterTable {
		text-align: center;
		margin-top: $base-unit*2.75;

		.tables__moneyback {
			margin-bottom: $base-unit*1.25;

			.money-back__item {
				padding-top: 0;
				margin: 0;
			}

			@include media-breakpoint-down(md) {
				.money-back {
					justify-content: center;
				}
			}
		}
	}

	//Icon colours
	.icon {
		&.icon-false {
			filter: $filter-gray07;

			.inverse & { filter: $filter-gray02; }
		}

		&.icon-true {
			filter: $filter-blue-dark;

			.inverse & { filter: $filter-white; }
		}
	}

	&.theme {
		&-blue {
			.bg-tinged {
				background-color: $blue-faint;

				.icon {
					filter: $filter-blue-bright;
				}
			}
		}

		&-green {
			.bg-tinged {
				background-color: $green-faint;
			}
		}

		&-orange {
			.bg-tinged {
				background-color: $orange-faint;
			}
		}
	}
}


// Bootstrap Table override
.table {
	width: 100%;
	border-color: transparent;
	border-bottom: 1px solid $table-border-color;

	@include media-breakpoint-down(sm) {
		display: block;

		thead,
		tbody,
		tfoot {
			display: block;
		}
	}

	tbody td {
		.icon { margin: 0 auto; }
	}

	th,
	td {
		padding: $base-unit ($base-unit/2);
		vertical-align: middle;
		text-align: center;
		border-top: 1px solid $table-border-color;
		position: relative;
		background-clip: padding-box;
		font-size: .85rem;

		&.va-bottom {
			vertical-align: bottom;
		}

		&.va-top {
			vertical-align: top;
		}

		&.va-middle {
			vertical-align: middle;
		}

		&.noborder-left {
			border-left: transparent;
		}

		&.noborder-right {
			border-right: transparent;
		}

		&.noborder-bottom {
			border-bottom: transparent;
		}

		&.noborder-top {
			border-top: transparent;
		}
	}

	thead {
		@include media-breakpoint-down(sm) {
			position: -webkit-sticky;
			position: sticky;
			top: 0;
			z-index: 3;
		}

		tr {
			@include media-breakpoint-down(sm) {
				display: flex;
				flex-wrap: wrap;
			}

			th,
			td {
				text-align: center;
				border-color: transparent;
				border-bottom: hidden;
				padding: ($base-unit*1.25) ($base-unit/2);

				@include media-breakpoint-down(sm) {
					background-color: $white;
					flex: 1;
				}
			}
		}
	}

	tbody {
		tr {
			@include media-breakpoint-down(sm) {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}

			th {
				text-align: left;
				font-weight: normal;
				padding: $base-unit ($base-unit/2);

				@include media-breakpoint-down(sm) {
					flex: 1 1 100%;
					text-align: center;
				}
			}

			td {
				min-width: 160px;
				padding: $base-unit ($base-unit/2);

				@include media-breakpoint-down(sm) {
					flex: 1;
					min-width: 0;
					border-top: none;
				}
			}
		}
	}

	tfoot {
		tr {
			@include media-breakpoint-down(sm) {
				display: flex;
				flex-wrap: wrap;
			}

			td,
			th {
				padding: ($base-unit*2) ($base-unit);
				border-color: transparent;

				@include media-breakpoint-down(sm) {
					padding: 0;
					position: relative;
					z-index: 9;
					background-color: $white;
				}
			}
		}
	}
}