/* ❗ If you do some changes in this file, please inform in the #help-crm Slack channel about it. ❗ */

.navigation-for-mobile {
	// screen overlay when navigation is opened
	.navigation-closed .navigation-mobile-overlay {
		display: none;
	}

	&:not(.navigation-closed) .navigation-mobile-overlay {
		display: block;
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		background-color: $blue-dark;
		opacity: 0.8;
	}

	// active menu colors
	&.second-menu-products .second-menu .products,
	&.second-menu-security .second-menu .security,
	&.second-menu-privacy .second-menu .privacy,
	&.second-menu-performance .second-menu .performance,
	&.second-menu-business-partners .second-menu .business-partners,
	&.second-menu-resources .second-menu .resources,
	&.second-menu-press-center .second-menu .press-center,
	&.second-menu-about-pages .second-menu .about-pages,
	&.second-menu-privacy-pages .second-menu .privacy-pages,
	&.second-menu-family .second-menu .family,
	&.second-menu-bundles .second-menu .bundles,
	&.second-menu-investors .second-menu .investors,
	&.second-menu-diversity .second-menu .diversity {
		background-color: $white;

		&.arrow {
			&:before {
				transform: translateY(-50%) rotate(-90deg) !important;
			}

			&:after {
				display: none;
			}
		}
	}

	&.navigation-regions-0 .regions-0,
	&.navigation-regions-1 .regions-1,
	&.navigation-regions-2 .regions-2 {
		background-color: $white;

		.subcategory.arrow {
			background-image: url(../../i/v2/components/arrow-m-up-purple-mobile.svg) !important;
		}
	}

	// navigation closed state
	&.navigation-closed {
		.header {
			.first-menu,
			.second-menu {
				display: none !important;
			}

			.toggle-menu {
				border: none;
				height: $cmp-header__height--mobile;

				&:before {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					@include sprite-image("icons", "burger", 40);
				}
			}
		}
	}

	// first menu items closed by default
	&[class*="first-menu-"] .first-menu {
		display: none;
	}

	&.blogs-special-menu .first-menu {
		display: none;
	}

	&.first-menu-about-us .item.about-us {
		display: none;
	}

	&.blogs-special-menu .item.blogs {
		display: none;
	}

	// Reordering items
	// because for mobile navigation tree some items needs to be reordered
	.header {
		display: block; // shows the header after JS is loaded

		.first-menu,
		.first-menu .side {
			flex-direction: column;
			position: relative;
		}

		.second-menu.for-home {
			& > :nth-child(1) {
				order: 1;
			}

			& > :nth-child(2) {
				order: 3;
			}

			& > :nth-child(3) {
				order: 2;
			}
		}

		.second-menu.for-business {
			& > :nth-child(1) {
				order: 1;
			}

			& > :nth-child(2) {
				order: 3;
			}

			& > :nth-child(3) {
				order: 2;
			}
		}
	}

	/* Header styles */
	.header {
		// white stripe with logo (yes, its actually a pseudo element :) )
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: $cmp-header__height--mobile;
		}

		// variants
		&.default:before {
			background-color: $white;
		}

		&.inverse:before {
			background-color: $blue-dark;
		}

		.container {
			padding: 0; // removing default mobile container padding
			max-width: none; // removing default mobile max width
		}

		a {
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}
		}

		// logo
		.avast-logo {
			position: absolute;
			top: 13px;
			left: 16px;
			z-index: 1010;
		}

		// hamburger
		.toggle-menu {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			width: 64px;
			height: 65px;
			background-color: $white; // bg for a close icon
			cursor: pointer;
			border: none;
			padding: 0;
			border-left: 1px solid $gray08;
			border-top: 1px solid $gray08;
			border-radius: 16px 16px 0 0;

			&:before {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				@include sprite-image("icons", "cross", 40);
			}

			span {
				display: none;
			}
		}

		// navigation tree
		nav {
			position: absolute;
			top: $cmp-header__height--mobile;
			right: 0;
			overflow-y: auto;
			width: calc(100% - (#{$base-unit} * 1.5));
			max-width: $base-unit * 33; // reduce width - too wide navigation is decreasing orientability
			max-height: $cmp-header-mobile-navigation__max-height;
			margin-left: $base-unit * 1.5; // reserved space - to see the main content below the open navigation (it gives visitor a sence of context where he is on the page)
			border-radius: 0 0 $base-unit $base-unit;
		}

		.first-menu {
			border-radius: 0 0 $base-unit $base-unit;
		}

		// first menu
		.first-menu,
		.mobile-bottom-menu {
			ul {
				li {
					padding: 20px 60px 20px 20px;
					background-color: $white;
					background-repeat: no-repeat;
					cursor: pointer;
					position: relative;

					&:before {
						content: "";
						position: absolute;
						top: 50%;
						right: 20px;
						transform: translateY(-50%);
						@include sprite-image("icons", "next", 16);
					}

					// separator
					&:not(:last-child):after {
						display: block;
						content: "";
						position: absolute;
						left: 20px;
						bottom: 0;
						width: calc(100% - 40px);
						height: 1px;
						background-color: $gray09; // separator on lighter bg
					}

					&.for-partners:after {
						display: none;
					}

					&.blogs,
					&.about-us {
						&:after {
							background-color: $gray08; // separator on darker bg
						}
					}

					// darker items
					&.about-us,
					&.blogs,
					&.region {
						background-color: $gray09; // bg for bottom first level items
					}

					// category label
					.category {
						display: block;
						font-size: $base-unit * 0.937;
						line-height: $base-unit * 1.5;
						color: $blue-dark;
						font-weight: 800;
						padding-bottom: 5px;
					}

					.text {
						font-size: $body-xs-font-size;
					}
				}
			}
		}

		// second menu - bottom row of header
		.second-menu {
			flex-direction: column;
			background-color: $white;
			border-radius: 0 0 $base-unit $base-unit;

			// temporary, will be prolly replaced by other element

			// backlink
			.back {
				padding: 25px 34px 25px 58px;
				color: $blue-dark;
				font-size: $base-unit * 1.25;
				line-height: $base-unit * 1.875;
				font-weight: 800;
				cursor: pointer;
				position: relative;

				&:before {
					content: "";
					position: absolute;
					top: 50%;
					left: 25px;
					transform: translateY(-50%);

					@include sprite-image("icons", "back", 32);
				}
			}

			ul {
				li {
					cursor: pointer;
				}
			}

			ul:not(.mobile-links-top) {
				li {
					.subcategory {
						position: relative;
						display: block;
						padding: $base-unit * 1.5;
						padding-right: 60px;
						font-size: $base-unit * 0.875;
						line-height: $base-unit * 1.312;
						font-weight: bold;

						&:after {
							content: "";
							width: calc(100% - 3rem);
							height: 1px;
							background-color: $gray08;
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translateX(-50%);
						}

						&.no-line:after {
							display: none;
						}

						// this means that it can be toggled
						&.arrow {
							background-color: $gray10;
							cursor: pointer;
							position: relative;

							&:before {
								content: "";
								position: absolute;
								top: 50%;
								right: 20px;
								transform: translateY(-50%);

								@include sprite-image("icons", "next", 16);
							}
						}
					}
				}

				// removes the last line on the bottom of the "li" in all second menu items
				li:last-child .subcategory:after {
					display: none;
				}
			}

			// Top mobile links (Home, Support, Store, Account..)
			.mobile-links-top {
				position: relative;
				display: flex;
				flex-direction: row;
				padding: 0 $base-unit * 0.312 $base-unit * 0.625;
				background-color: $white;

				// All items should have equal size
				> li {
					flex: 1;
				}

				.subcategory {
					padding: 0 10px $base-unit * 1.5;
					text-align: center;
					font-size: $base-unit * 0.875;
					line-height: $base-unit * 1.312;
					font-weight: bold;

					.icon {
						display: block;
						margin: 0 auto $base-unit * 0.5;
					}
				}

				// Create space for login buttons
				&.isOpen {
					padding-bottom: $base-unit * 7;
				}

				// Account/Login section
				.login {
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					display: none;

					&.isOpen {
						display: block;

						// Login buttons
						.mobile-link {
							padding: $base-unit * 0.5 $base-unit;
						}
					}
				}
			}

			// blogs
			&.blogs {
				li:not(.back) {
					padding: $base-unit * 1.5;
					padding-right: 60px;
					position: relative;

					&:not(:last-child):after {
						content: "";
						display: block;
						position: absolute;
						left: $base-unit * 1.5;
						bottom: 0;
						background-color: $gray08;
						width: calc(100% - 48px);
						height: 1px;
					}

					&:last-child {
						border-bottom: none;
					}

					span {
						display: block;

						&.txt-blogs-title {
							font-size: $base-unit * 0.937;
							line-height: $base-unit * 1.5;
							font-weight: 800;
							padding-bottom: $base-unit * 0.5;
							color: $blue-dark;
						}

						&.txt-blogs-description {
							font-size: $base-unit * 0.75;
							line-height: $base-unit * 1.125;
						}
					}
				}
			}
		}

		// third menu
		.third-menu {
			.block-products {
				.product {
					padding: $base-unit * 1.5;
					padding-bottom: 0;
					font-size: $body-sm-font-size;

					.logo {
						margin-right: 7px;
						vertical-align: middle;
					}

					.name {
						display: flex;
						align-items: center;
						white-space: nowrap;
						font-size: $base-unit * 0.875;

						.product-name {
							margin-left: $base-unit * 1;
						}
					}

					.os {
						padding-bottom: $base-unit;

						.icon {
							margin: 0 4px;
							width: $base-unit;
						}
					}

					.category {
						margin: $base-unit 0;
						font-size: $base-unit * 0.75;
						line-height: $base-unit;
						color: $gray03;
						display: flex;
						align-items: center;

						&:before {
							content: "";
							margin-right: $base-unit/2;
							filter: $filter-gray03;
							display: inline-block;
							@include sprite-image("icons", "account", 16);
						}
					}

					.description {
						font-size: $base-unit * 0.75;
						line-height: $base-unit * 1.125;
						margin-bottom: $base-unit * 1.5;
					}

					.product-links {
						margin-bottom: $base-unit;

						a {
							display: block;
							padding: $base-unit 0;
							position: relative;

							/*
							// currently it should be removed
							&:before {
								content: '';
								position: absolute;
								top: 50%;
								right: 0;
								transform: translateY(-50%);
								filter: $filter-blue-dark;

								@include sprite-image('icons', 'next', 16);
							}
							*/
						}
					}
				}
			}

			// all items with this class has arrow
			.mobile-link {
				a {
					display: block;
				}

				/*
				// currently it should be removed
				&::after {
					content: url(../../i/navigation/arrow-item-lighter.svg);
					display: inline-block;
					margin-left: 10px;
					vertical-align: middle;
				}
				*/
			}

			// Section modifications

			&.security {
				// hint with platform selection
				.hint {
					display: block;
					position: relative;
					padding: 0 34px;

					&.hint-prompt {
						text-decoration: underline;
					}

					.hint-title {
						display: block;
					}

					// hidden until .show-hint
					.hint-content,
					.close-hint {
						display: none;
					}

					a {
						display: inline-block;
						margin: 10px;
						padding: 7px 20px;
						background-color: $gray09;
						border: 1px solid $gray07;
						border-radius: 3px;
					}

					.close-hint {
						position: absolute;
						top: 14px;
						right: 6px;
						height: 40px;
						width: 40px;
						background: transparent
							url(../../i/navigation/cross-small.svg) 50% 50%
							no-repeat;
					}

					&.show-hint {
						// show buttons and close
						.hint-content,
						.close-hint {
							display: block;
						}
					}
				}
			}
		}

		// Category modifications
		// for home
		.for-home .third-menu {
			.block-products {
				& > li {
					padding: $base-unit $base-unit * 3 0;

					&:first-child {
						padding-top: $base-unit * 1.5;
					}

					&:last-child {
						padding-bottom: $base-unit * 1.5;
					}

					.block-header,
					.os,
					.description,
					.bottom-link {
						display: none;
					}
				}
			}
		}

		// For business
		.for-business.second-menu {
			.mobile-links-top {
				li .subcategory {
					font-size: $base-unit * 0.65;
					font-weight: 600;
					padding: 0 $base-unit * 0.35;
					line-height: $base-unit;
				}
			}
		}

		.for-business .third-menu {
			&.products {
				// Line separator between items in SMB navigation
				.block-products {
					.product {
						&:not(:last-child):after {
							content: "";
							display: block;
							height: 1px;
							background-color: $gray08;
						}
						.product-name {
							font-size: $base-unit;
							line-height: $base-unit * 1.5;
							font-weight: bold;
						}

						.category {
							color: $gray03;
						}

						.description {
							a {
								text-decoration: underline;
							}
						}

						.name {
							font-size: $base-unit;
							font-weight: bold;
						}
					}

					.product.speciality-products {
						li {
							padding: $base-unit 0 $base-unit $base-unit * 1.5;

							&:first-child {
								padding-top: $base-unit * 1.5;
							}

							&:last-child {
								padding-bottom: $base-unit * 2;
							}

							a {
								text-decoration: underline;
							}
						}
					}
				}

				.hint {
					margin: $base-unit * 1.5;
					margin-top: 0;
					padding: $base-unit * 1.5;
					border-radius: $base-unit/2;
					border: 1px solid $gray08;
					background-color: $gray10;
					font-size: $base-unit * 0.875;
					font-weight: bold;
				}
			}

			&.business-partners {
				.product {
					&:after {
						content: "";
						display: block;
						height: 1px;
						background-color: $gray08;
					}

					.name {
						font-size: $base-unit * 0.875;
						font-weight: bold;
					}
				}

				.hint {
					background-color: $white;
					padding: $base-unit * 1.5;

					.hint-button:not(:last-child) {
						margin-bottom: $base-unit;
					}
				}

				.mobile-link {
					padding: $base-unit 0 $base-unit $base-unit * 1.5;

					&:first-child {
						padding-top: $base-unit * 1.5;
					}

					&:last-child {
						padding-bottom: $base-unit * 2;
					}

					a {
						text-decoration: underline;
					}
				}
			}
		}

		// about us
		.about-us .third-menu {
			.block-products {
				ul,
				li {
					padding: 0;
				}

				.mobile-link a {
					padding: $base-unit;
					padding-left: $base-unit * 3;
				}

				.name {
					font-size: $body-md-font-size;
					padding: $base-unit;
					padding-left: $base-unit * 2;
					display: flex;
					align-items: center;
				}
			}
		}
	}
}

// Dark version modifications
.navigation-for-mobile.inverse {
	.header {
		&:before {
			background-color: $blue-dark;
		}

		a {
			&:hover {
				color: $orange-bright;
			}
		}

		.toggle-menu {
			background-color: $blue-deep;
			border: 1px solid $blue-mid;
			border-bottom-color: $blue-deep;

			&:before {
				filter: $filter-white;
			}
		}

		.first-menu {
			ul li {
				background-color: $blue-deep;

				&.region {
					border-radius: 0 0 $base-unit $base-unit;
				}

				// darker items
				&.about-us,
				&.blogs,
				&.region {
					background-color: $blue-dark;
				}

				&:before {
					filter: $filter-white;
				}

				&:after {
					background-color: $blue-mid;
				}

				.category {
					color: $white;
				}
			}
			.side:nth-of-type(1) {
				border-top: none;
			}
		}

		.second-menu {
			background-color: $blue-deep;

			.back {
				color: $white;

				&:before {
					filter: $filter-white;
				}
			}

			&.blogs {
				li {
					&:not(.back) {
						span {
							&.txt-blogs-title {
								color: $white;
							}
						}

						&:not(:last-child):after {
							background-color: $blue-mid;
						}
					}
				}
			}

			ul:not(.mobile-links-top) {
				li {
					background-color: $blue-deep;

					.subcategory {
						color: $white;

						&.arrow {
							background-color: $blue-dark;
							color: $white;

							&:before {
								filter: $filter-white;
							}
						}

						&:after {
							background-color: $blue-mid;
						}
					}
				}
			}

			.back {
				background-color: $blue-deep;
				color: $white;
				font-size: $base-unit * 1.25;
				line-height: $base-unit * 1.875;
				font-weight: 800;

				&:before {
					filter: $filter-white;
				}
			}

			.mobile-links-top {
				background-color: $blue-deep;

				li .subcategory {
					color: $white;

					.icon {
						filter: $filter-white;
					}
				}
			}

			ul:not(.mobile-links-top) li .subcategory {
				color: $white;
			}

			.third-menu {
				background-color: $blue-deep;

				.block-products {
					.product {
						&:not(:last-child):after {
							background-color: $blue-mid;
						}

						.category {
							color: $gray06;

							&:before {
								filter: $filter-gray06;
							}
						}
					}
				}

				.hint {
					color: $white;
					background-color: $blue-mid;
					border: none;
				}

				&.business-partners {
					.block-products {
						.product {
							&:after {
								background-color: $blue-mid;
							}
						}
					}

					.hint {
						background-color: $blue-deep;
					}
				}
			}

			&.for-home .third-menu {
				.hint {
					background-color: $blue-deep;

					a {
						color: $white;

						&:active {
							color: $white;
						}
					}

					.close-hint {
						filter: $filter-white;
					}

					.hint-content a {
						color: $white;
						background-color: $blue-mid;
						border: none;

						&:active {
							color: $white;
						}
					}
				}
			}
		}
	}

	&.navigation-closed .header .toggle-menu {
		background-color: transparent;
		border: 1px solid $blue-dark;
	}
}

// Specific styles for opened navigation
body.navigation-for-mobile:not(.navigation-closed) {
	overflow: hidden;

	nav {
		border: 1px solid $gray08;
	}

	&.inverse nav {
		border: 1px solid $blue-mid;
	}
}

// localisation fixes
[lang="no"],
[lang="sv"],
[lang="da"],
[lang="es"],
[lang="de"],
[lang="fr"],
[lang="it"],
[lang="nl"],
[lang="cs"] {
	.navigation-for-mobile {
		.header {
			.second-menu {
				.mobile-links-top {
					flex-wrap: wrap;
					justify-content: space-around;
				}
			}
		}
	}
}
