.action-box {
	display: grid;
	grid-template-rows: repeat(3, auto);
	grid-template-areas:
		"title"
		"platforms"
		"instructions"
		"tableMobileSection";
	position: relative;
	background: $white;
	border: 1px solid $gray08;
	width: 316px;
	padding: $base-unit*1.5 $base-unit*2 $base-unit*1.5;
	align-content: start;
	border-radius: $base-unit;
	height: 100%;
	text-align: center;

	.table-mobile-section {
		grid-area: tableMobileSection;
		display: grid;
		grid-template-rows: repeat(8, auto);
		grid-template-areas:
			"label"
			"installments"
			"price"
			"priceOld"
			"cta"
			"ctaNote"
			"ctaSecondary"
			"footer";
	}

	@include media-breakpoint-up(lg) {
		padding: $base-unit*2 $base-unit*2 $base-unit*2;
	}

	&__title {
		grid-area: title;
		justify-content: center;
		padding-bottom: $base-unit/2;

		font-size: $tagline-lg-font-size-m;
		line-height: $tagline-lg-line-height-m;
		font-weight: $tagline-font-weight;
		letter-spacing: $tagline-lg-letter-spacing;
		display: inline-flex;
		align-items: center;
		text-transform: uppercase;

		@include media-breakpoint-up(lg) {
			font-size: $tagline-lg-font-size;
			line-height: $tagline-lg-line-height;
		}
	}

	&__toggler {
		position: absolute;
		top: 0;
		left: 50%;
		z-index: 3;
		max-width: 100%;
		transform: translate(-50%, -50%);

		.toggler-select {
			.form-select-arrow {
				&:after {
					content: url("../../i/v2/components/pricing-box/down-arrow.svg");
					top: 10px;
				}

				.form-select {
					border: 1px solid $gray08;

					&:hover {
						border-color: $gray05;
					}
				}
			}
		}

		.toggler-horizontal {
			background-color: $white;
			border-radius: 20px;
			display: flex;
			padding: 4px;
			box-shadow: 0 10px 20px -10px rgba($blue-dark, .2);
			border: 1px solid $gray08;
			//border: 1px solid $gray08;
			//box-shadow: 0 10px 20px -10px rgba($blue-dark, .2);
			//margin: 0 $base-unit;

			@include media-breakpoint-down(md) {
				max-width: 332px;
			}

			&.disabled-toggler {
				box-shadow: none;
			}

			.toggler-option {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $white;
				border-radius: 16px;
				//font-size: $body-sm-font-size-m; //12
				//line-height: $body-sm-line-height-m; //18
				cursor: pointer;
				height: 32px;
				// min-width: 104px;
				max-width: 240px;
				padding: 0 $base-unit;
				white-space: nowrap;
				overflow: hidden;
				margin: 0; 

				@include media-breakpoint-up(lg) {
					//font-size: $body-sm-font-size; //14
					//line-height: $body-sm-line-height; //24
					padding: 0 $base-unit*1.5;
				}

				&.disabled {
					cursor: initial;
					//opacity: .5;

					&:hover {
						background-color: $white;
					}
				}

				&:last-child {
					border-right: none;
				}

				&:hover,
				&:focus,
				&:active {
					background-color: $orange-faint;
					outline-offset: -1px;  // fix outline overlaping
				}

				&.selected {
					background-color: $orange-pale;
				}
			}
		}

		.custom-select {
			width: auto;
			max-width: 100%;
			margin-bottom: 0;
		}
	}

	&__platforms {
		grid-area: platforms;
		padding-bottom: $base-unit;

		@include media-breakpoint-up(lg) {
			padding-bottom: $base-unit*1.5;
		}

		&.platforms {
			align-items: center;
			display: flex;
			justify-content: center;
			min-height: 16px;

			img, .icon {
				margin: 0 4px;
			}
		}
	}

	&__instructions {
		grid-area: instructions;
		margin: 0;

		&.empty {
			height: 0;

			@include media-breakpoint-up(lg) {
				height: 24px;
			}
		}
	}

	&__label {
		grid-area: label;
		/* display: flex;
		justify-content: center; */
		padding-bottom: 8px;

		&.empty {
			height: 0;

			@include media-breakpoint-up(lg) {
				height: 32px;
			}
		}

		.badge-multiline & {
			//margin-bottom: $base-unit/2;
				height: 27px;

			@include media-breakpoint-up(lg) {
				//margin-top: -$base-unit/4;
				height: 46px;
			}

			.badge {
				height: auto;
				padding: $base-unit/4 $base-unit*.75;
				white-space: normal;
				//line-height: $base-unit*.75;
			}
		}
	}

	&__installments {
		grid-area: installments;
		font-weight: 700;
		//height: 24px;
	}

	&__price {
		grid-area: price;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		//height: 34px;

		/* @include media-breakpoint-up(lg) {
			height: 48px;
		} */

		.price-wrapper {
			/* @include media-breakpoint-up(lg) {
				.hide-desktop-period {
					display: none;
				}
			}

			&.price-row-medium {
				font-size: 36px;
			} */

			/* .integer {
				font-size: 34px;
				line-height: 34px;

				@include media-breakpoint-up(lg) {
					font-size: 46px;
					line-height: 46px;
				}
			} */

			/* .decimal {
				font-size: .391em;
				height: 1em;
				line-height: 1em;
			} */

			/* .price-prefix {
				display: flex;
				margin-right: 2px;
				margin-top: 4px;

				@include media-breakpoint-up(lg) {
					margin-top: 2px;
				}

				.currency {
					line-height: 14px;

					@include media-breakpoint-up(lg) {
						line-height: 18px;
					}
				}
			} */

			/* .price-suffix {
				margin-top: .05em;
			} */

			/* .period {
				align-self: flex-end;
				position: relative;
				min-width: 25px;
				text-align: left;
				margin-left: 5px;
				line-height: 1.4em;

				@include media-breakpoint-down(md) {
					margin-left: 2px;
				}
			} */
		}
	}

	&__priceOld {
		grid-area: priceOld;
		//height: $base-unit*1.5;
		color: $gray03;

		&.empty {
			height: 0;

			@include media-breakpoint-up(lg) {
				height: 24px;
			}
		}

		.price-old {
			/* font-size: $base-unit*.75;
			line-height: $base-unit*1.125; */
			text-decoration: line-through;

			/* @include media-breakpoint-up(lg) {
				font-size: $base-unit;
				line-height: $base-unit*1.5;
			} */
		}
	}

	// used for EN-GB region
	// - inherits some styles from .action-box__priceOld
	&__monthPrice {
		.month-price {
			margin-bottom: 0;	// reset margin set by .text-small (used on the HTML element)
		}

		/* .action-box-multibox & {
			@include media-breakpoint-up(lg) {
				height: $base-unit*2.5;	// in Multibox text wraps on two lines - adjust container's height

				.month-price {
					line-height: $base-unit*1.25;
				}
			}
		} */
	}

	&__cta {
		grid-area: cta;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		padding: $base-unit 0 $base-unit/2;

		@include media-breakpoint-up(lg) {
			padding: $base-unit*1.5 0 $base-unit/2;
		}

		.btn {
			margin-bottom: $base-unit/2;

			@include media-breakpoint-down(md) {
				margin-bottom: 0;
			}
		}
	}

	&__ctaNote {
		grid-area: ctaNote;

		.cta-note {
			color: $gray03;
			margin: 0 auto;
			text-align: center;
		}

		.subscription-link {
			cursor: pointer;
			white-space: nowrap;

			.sub-text {
				white-space: normal;
			}
			.sub-icon {
				@include sprite-image('icons', 'info', 16);
				flex: 0 0 auto;
				display: inline-block;
				cursor: pointer;
				vertical-align: top;

				.inverse & {
					filter: $filter-gray03;
				}

				&:hover {
					@include sprite-image('icons', 'hover-info', 16);
					flex: 0 0 auto;
				}
			}
		}
		.legal-note {
			cursor: pointer;

			span {
				@include sprite-image('icons', 'info', 16);
				margin-left: $base-unit*.2;
				flex: 0 0 auto;
				display: inline-block;
				cursor: pointer;
				vertical-align: top;

				.inverse & {
					filter: $filter-gray03;
				}

				&:hover {
					@include sprite-image('icons', 'hover-info', 16);
					flex: 0 0 auto;
				}
			}
		}
	}

	&__ctaSecondary {
		grid-area: ctaSecondary;
		padding-top: $base-unit*1.25;

		.cta-secondary {
			font-size: 9px;
			line-height: $base-unit;
			font-weight: 800;
			letter-spacing: .8px;
			color: $blue-dark;

			@include media-breakpoint-up(lg) {
				font-size: $base-unit*.75;
			}

			a:not(.btn) {
				border-bottom: 1px solid $blue-dark;
				padding: 0;
				text-transform: uppercase;
				text-decoration: none;

				&:hover {
					text-decoration: none;
					color: $blue-dark;
				}
			}
		}
	}

	&__footer {
		grid-area: footer;
		padding-top: $base-unit/2;

		@include media-breakpoint-up(lg) {
			padding-top: $base-unit;
		}

		.footer-text {
			color: $gray03;
			text-align: center;
		}
	}
}

.action-box-wrap {
	display: flex;
	justify-content: center;
	align-items: stretch;

	@include media-breakpoint-down(md) {
		flex-direction: column;
	}

	.action-box {
		margin: 0 auto $base-unit/2;

		@include media-breakpoint-up(lg) {
			margin: 0 $base-unit/2;
		}
	}

	:nth-child(2).show-toggler .action-box {
		margin-bottom: $base-unit*2;

		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}
	}
}

.action-box-multibox {
	display: flex;
	justify-content: center;
	border: 1px solid $gray08;
	//box-shadow: 0 20px 40px -20px rgba($blue-dark, .15);
	position: relative;
	padding: $base-unit;
	width: 100%;
	height: 100%;
	max-width: 750px;
	margin: 0 auto;
	border-radius: $base-unit;
	background: $white;

	@include elevation-box-shadow(1,"m");
	@include media-breakpoint-down(md) {
		max-width: 316px;
		flex-direction: column;
		padding: 0 $base-unit*2;
	}

	.action-box {
		margin: 0;
		padding: 0 calc(#{$base-unit}*2 - 2px); // minus the border
		border: 1px solid transparent;
		border-left: 1px solid $gray08;
		border-radius: 0;

		@include media-breakpoint-down(md) {
			width: 100%;
			padding: $base-unit*1.5 0;
			border-left: none;
			border-top: 1px solid $gray07;

			&:first-of-type {
				border-top: none;
			}
		}

		&:first-of-type {
			border-left: none;
		}

		&.promotion {
			border: 1px solid $orange-bright;
			border-radius: 8px;

			@include media-breakpoint-down(md) {
				margin: 0 -#{$base-unit} $base-unit -#{$base-unit};
				width: calc(100% + #{$base-unit*2});
				padding-bottom: $base-unit;

				&:before {
					content: "";
					width: calc(100% - #{$base-unit*2});
					height: 1px;
					background: $gray07;
					position: absolute;
					left: 50%;
					top: -#{$base-unit};
					transform: translateX(-50%);
				}
			}

			+ .action-box {
				border: 1px solid transparent;

				@include media-breakpoint-down(md) {
					border-top: 1px solid $gray07;
				}
			}

			.badge {
				background-color: $orange-pale;
			}

			.label-text {
				color: $orange-bright;
			}
		}
	}
}

.action-box-container {
	position: relative;
	background: none;

	&.show-toggler {
		> .action-box {
			padding-top: $base-unit*2.5;

			@include media-breakpoint-up(lg) {
				padding-top: $base-unit*3.25;
			}
		}

		> .action-box-multibox {
			padding-top: $base-unit;
			padding-bottom: $base-unit;

			@include media-breakpoint-up(lg) {
				padding-top: $base-unit*3.25;
				padding-bottom: $base-unit*2;
			}
		}
	}

	&.show-promotion {
		> .action-box-multibox {
			padding-top: $base-unit;
			padding-bottom: $base-unit;

			@include media-breakpoint-up(lg) {
				padding-top: $base-unit*2.25;
			}

			.action-box {
				padding-top: $base-unit*1.5;
				padding-bottom: $base-unit*1.5;
				//margin-bottom: $base-unit;

				@include media-breakpoint-up(lg) {
					padding-top: $base-unit*2;
					padding-bottom: $base-unit*2;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}

//styles for Mobile devices content
.action-box-content {
	display: flex;
	flex-direction: column;

	&__title {
		padding-bottom: $base-unit/2;
		justify-content: center;
		@extend .tagline-large;
		/* font-size: 12px;
		line-height: $base-unit*1.125;
		font-weight: 800;
		letter-spacing: 2.5px;
		text-transform: uppercase; */

		/* @include media-breakpoint-up(lg) {
			font-size: $base-unit*.9375;
			line-height: $base-unit*1.5;
		} */
	}

	&__platform {
		display: flex;
		justify-content: center;
		margin-bottom: $base-unit;

		@include media-breakpoint-up(lg) {
			margin-bottom: $base-unit*1.5;
		}
	}

	&__ctaNote {
		font-size: 11px;
		line-height: $base-unit*1.125;
		color: $gray03;
		margin-bottom: 14px;

		@include media-breakpoint-up(lg) {
			font-size: $base-unit*.875;
			line-height: $base-unit*1.5;
			margin-bottom: $base-unit*1.5;
		}
	}

	&__button {
		height: 40px;

		img[class*="storebadge-"] {
			width: auto;
			height: 40px;
		}
	}

	&__footer {
		font-size: 14px;
		margin-top: $base-unit;
	}
}

.inverse {
	.action-box-container:not(.light) {
		.action-box {
			background: $blue-deep;
			border-color: $blue-mid;

			&__toggler {
				.toggler-select {
					.form-select-arrow {
						&:after {
							content: url("../../i/v2/components/pricing-box/down-arrow-white.svg");
						}

						.form-select {
							background: $blue-deep;
							border-color: $blue-mid;
							color: $white;
							box-shadow: 0 10px 20px -10px rgba($black, .4);

							&:hover {
								border-color: $blue-deep;
							}
						}
					}
				}

				.toggler-horizontal {
					background: $blue-deep;
					border-color: $blue-mid;
					box-shadow: 0 10px 20px -10px rgba($black, .4);

					.toggler-option {
						background: $blue-deep;
						color: $white;

						&.disabled {
							&:hover {
								background: $blue-deep;
							}
						}

						&:hover {
							background-color: #37586c;
						}

						&.selected {
							background: $blue-mid;
						}
					}
				}
			}

			&__platforms.platforms {
				img {
					filter: $filter-gray06;
				}
			}

			&__label {
				.badge {
					background: $blue-mid;
					border-color: #37586c;
					color: $white;
				}
			}

			&__price {
				.price-wrapper {
					.period {
						color: $gray06;
					}
				}
			}

			&__priceOld {
				color: $gray06;
			}

			&__ctaNote {
				.cta-note {
					color: $gray06;
				}
			}

			&__ctaSecondary {
				.cta-secondary {
					a:not(.btn) {
						color: $white;
						border-color: 1px solid $white;

						&:hover {
							color: $white;
						}
					}
				}
			}

			&__footer {
				.footer-text {
					color: $gray06;
				}
			}
		}

		.action-box-multibox {
			background: $blue-deep;
			border-color: $blue-mid;
			border-radius: $base-unit;

			.action-box {
				border: 1px solid transparent;
				border-left: 1px solid $blue-mid;

				@include media-breakpoint-down(md) {
					border-left: none;
					border-top: 1px solid $blue-mid;

					&:first-of-type {
						border-top: none;
					}
				}

				&:first-of-type {
					border-left: none;
				}

				&.promotion {
					border: 1px solid $orange-bright;

					@include media-breakpoint-down(md) {
						&:before {
							background: $blue-mid;
						}
					}

					+ .action-box {
						border: 1px solid transparent;

						@include media-breakpoint-down(md) {
							border-top: 1px solid $blue-mid;
						}
					}

					.badge {
						background: $blue-mid;
						border-color: #37586c;
						color: $white;
					}
				}
			}
		}
	}
}
