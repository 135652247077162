/* Hero left image */

// left text
// right square image

// normal variant
// inverse variant

.hero-left-image {
	.container {
		@include media-breakpoint-up(lg) {
			min-height: 528px;
		}

		& > .row {
			align-items: flex-start;
		}
	}

	// headline icon
	.hero-headline {
		&.icon-top {
			display: flex;
			flex-flow: column;
			align-items: center;
			-ms-flex-align: center;

			@include media-breakpoint-up(lg) {
				align-items: start;
				-ms-flex-align: start;
			}
		}

		.headline-text {
			max-width: 100%; //IE11 fix
		}
	}

	// button group
	.hero-btn-group {
		@include media-breakpoint-up(lg) {
			// has right margin to separate multiple buttons
			.btn-wrapper {
				margin-right: $base-unit * 1.5;
			}
		}
	}

	// image
	.hero-image {
		text-align: center;

		img {
			width: 100%;
			border-radius: 0 $base-unit*2 $base-unit*2;

			@include media-breakpoint-up(lg) {
				border-radius: 0 $base-unit*3 $base-unit*3;
			}
		}
	}
}
