.tooltip {
	line-height: $base-unit;

	@include media-breakpoint-up(lg) {
		font-size: $tooltip-lg-font-size;
	}

	// Wrapper for the tooltip content
	.tooltip-inner {
		text-align: left;
		box-shadow: 0 10px 20px 0 rgba($blue-dark, .2);
	}

	//Secondary color setting
	&.tooltip-secondary {
		.tooltip-inner {
			background-color: $white;
			color: $gray03;
			box-shadow: 0 10px 20px -10px rgba($blue-dark, .1), inset 0 0 0 1px $gray08;
		}

		.arrow {
			&:after {
				position: absolute;
				content: "";
				border-color: transparent;
				border-style: solid;
			}
		}

		&.bs-tooltip-top {
			.arrow {
				&:before {
					border-top-color: $gray08;
				}

				&:after {
					top: 0;
					border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
					border-top-color: $white;
					transform: translateY(-1px);
				}
			}
		}

		&.bs-tooltip-right {
			.arrow {
				&:before {
					border-right-color: $gray08;
				}

				&:after {
					right: 0;
					border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
					border-right-color: $white;
					transform: translateX(1px);
				}
			}
		}

		&.bs-tooltip-bottom {
			.arrow {
				&:before {
					border-bottom-color: $gray08;
				}

				&:after {
					bottom: 0;
					border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
					border-bottom-color: $white;
					transform: translateY(1px);
				}
			}
		}

		&.bs-tooltip-left {
			.arrow {
				&:before {
					border-left-color: $gray08;
				}

				&:after {
					left: 0;
					border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
					border-left-color: $white;
					transform: translateX(-1px);
				}
			}
		}
	}

	//Arrow positions
	&.bs-tooltip-top,
	&.bs-tooltip-bottom {
		&.tooltip-arrow-start {
			.arrow {
				left: $base-unit !important;
			}
		}

		&.tooltip-arrow-end {
			.arrow {
				left: auto !important;
				right: $base-unit !important;
			}
		}
	}

	&.bs-tooltip-left,
	&.bs-tooltip-right {
		&.tooltip-arrow-start {
			.arrow {
				top: $base-unit/2 !important;
			}
		}

		&.tooltip-arrow-end {
			.arrow {
				top: auto !important;
				bottom: $base-unit/2 !important;
			}
		}
	}
}
