.bg-dark-blade {
	background: url("../../i/v2/components/bg/bg-blade-dark.svg") center center no-repeat;
	background-size: cover;
}

.bg-deep-blade {
	background: url("../../i/v2/components/bg/bg-deep-blade.svg") center center no-repeat;
	background-size: cover;
}

.bg-gradient-blade {
	background: url("../../i/v2/components/bg/bg-gradient-blade.svg") center center no-repeat;
	background-size: cover;
}

.bg-secure-browser {
	background: url("../../i/v2/components/bg/bg-secure-browser.svg") center center no-repeat;
	background-size: cover;
}

.bg-faint-orange {
	background: url("../../i/v2/components/bg/bg-faint-orange.svg") center center no-repeat;
	background-size: cover;
}
