//
//	import dependencies
//
@import "bootstrap/_mixins";
@import "avast/_variables";




//
//	💲 variables
//
$unit: 1rem;
$onePixel: $unit / 16;
$transition--color: color .1s ease-in-out;
$transition--transform: transform .1s ease-in-out;
$transition--opacity: opacity .1s ease-in-out;
$ds-nav__transition--mobile: transform .1s ease-in-out;
$ds-backdrop__blur: $unit / 2; // defines how much the Main content will be blurred
$box-shadow--focus: 0 0 0 ($unit / 8) $blue-bright-focus;
$dev--error: red;
$dev--warning: orange;
$dev--success: green;




//
//	🗺 Font styles - Headings
//
@mixin heading__h7--mobile {
	font-size: $unit * .875;		// 14px
	line-height: $unit * 1.3125; 	// 21px
	font-weight: 800;
}



//
//	🔷 components
//
@import "avast/components/ds-logo";
@import "avast/components/ds-icon";
@import "avast/components/ds-nav";



//
//	⏸ layout
//
@import "avast/layout/ds-backdrop";
@import "avast/layout/ds-header";
