/**************************
// CARD
// Wrapper component for Notices, Feature Items, Testiomonials, Awards, Metrics, etc.
// Generic styles only
**************************/

.card {
	height: 100%;
	padding: 0;

	.card-title {
		margin-bottom: $base-unit;
	}

	.card-body {
		padding: $base-unit*1.5;

		@include media-breakpoint-up(lg) {
			padding: $base-unit*2;
		}
	}

	.card-date,
	.card-author {
		text-transform: uppercase;
		font-weight: 700;
	}

	.card-date {
		margin-bottom: $base-unit/2;
	}

	.card-author,
	.card-timetoread {
		margin-bottom: 0;
		margin-top: $base-unit/2;

		@include media-breakpoint-up(lg) {
			margin-top: $base-unit;
		}
	}

	.card-timetoread-icon {
		filter: $filter-gray03;
		margin-right: $base-unit/2;
	}

	// BORDER RADIUSES
	&.card-large { @include radius($base-unit*2); }
	&.card-medium { @include radius($base-unit*1.5); }
	&.card-small { @include radius($base-unit); }
}


/*********
CARD HOVER
**********/
.card.hover {
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;

		// Cards
		.btn:not(:disabled):not(.disabled) {
			.btn-icon-hover { display: inline; }

			.btn-icon-normal { display: none; }

			&:before {
				height: calc(100% + (#{$base-unit}/4));
				width: calc(100% + (#{$base-unit}/4));
				border-radius: 30px;
			}

			&.btn-lg:before {
				height: calc(100% + (#{$base-unit}/2));
				width: calc(100% + (#{$base-unit}/2));
				border-radius: 40px;
			}

			&.btn-sm:before {
				border-radius: 22px;
			}

			&.btn-primary {
				@include btn-hover-primary ($btn-theme-primary);
			}

			&.btn-secondary {
				@include btn-hover-secondary ($btn-theme-secondary);
			}

			&.btn-link {
				@include btn-link-hover-primary ($btn-theme-secondary);
			}

			&.btn-link-secondary {
				@include btn-link-hover-secondary ($btn-theme-secondary);
			}
		}

		.inverse & {
			.btn:not(:disabled):not(.disabled) {
				&.btn-secondary {
					@include btn-hover-secondary ($btn-theme-secondary, $inverse:true);
				}

				&.btn-link {
					@include btn-link-hover-primary ($btn-theme-secondary, $inverse:true);
				}

				&.btn-link-secondary {
					@include btn-link-hover-secondary ($btn-theme-secondary, $inverse:true);
				}
			}
		}

		&.light {
			.btn:not(:disabled):not(.disabled) {
				&.btn-secondary {
					@include btn-hover-secondary ($btn-theme-secondary);
				}
	
				&.btn-link {
					@include btn-link-hover-primary ($btn-theme-secondary);
				}
	
				&.btn-link-secondary {
					@include btn-link-hover-secondary ($btn-theme-secondary);
				}
			}
		}
	}
}