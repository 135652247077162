.notification-overlay-for-wrong-download {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    bottom: 0;
    display: none;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9000;
    @media only screen and (max-width: 768px) {
        overflow-y: scroll;
    }
    &.show-popup {
        display: flex;
        @media only screen and (max-width: 768px) {
            display: block;
        }
    }
    .detected-windows,
    .detected-pc,
    .detected-android,
    .detected-mac,
    .detected-ios {
        display: none;
    }
    /* -------------------------------------------------------  */
    .show {
        display: inline;
    }
    /* -------------------------------------------------------  */
    .close-popup {
        background: url("../../i/components/wrong-download-popup/close.png") no-repeat 50% 50%;
        background-size: 100%;
        cursor: pointer;
        display: block;
        height: 18px;
        position: absolute;
        right: 35px;
        width: 18px;
        top: 33px;
        @media only screen and (max-width: 768px) {
            right: 29px;
            top: 29px;
        }
    }
    .platform-icon {
        margin-bottom: 1rem;
        max-height: 45px;
        @media only screen and (max-width: 768px) {
            margin-top: 5px;
        }
    }
    .content {
        align-items: center;
        background-color: #e7e7ef;
        color: #000000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 550px;
        padding: 46px 22px;
        position: relative;
    }
    .title {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 24px;
        text-align: center;
        @media only screen and (max-width: 768px) {
            max-width: 190px;
        }
    }
    .main {
        display: block;
        font-weight: 700;
        font-size: 20px;
        padding-bottom: 5px;
    }
    .button {
        width: 146px;
        height: 40px;
        margin-bottom: 15px;
        .text-large {
            width: 100%;
        }
        IMG {
            width: 146px;
        }
        &.logo-mac span::before {
            float: left;
            margin-right: 0px;
        }
    }
    .from-store {
        color: #5c707b;
        font-size: 14px;
        margin-bottom: 16px;
        margin-top: -20px;
        text-align: center;
    }
    .instead {
        font-family: "AVGSans_Light", Verdana, sans-serif;
        font-size: 16px;
        margin-bottom: 11px;
        margin-top: 11px;
        text-align: center;
    }
    .back {
        font-family: "AVGSans_Light", Verdana, sans-serif;
        font-size: 16px;
        text-decoration: underline;
        margin-top: 25px;
        color: black;
    }
    .custom-font {
        font-weight: bold;
    }
}

// DS version
.wrong-download-popup {
    .detected-windows,
    .detected-pc,
    .detected-android,
    .detected-mac,
    .detected-ios {
        display: none;
    }

    .show {
        display: block;
    }
}
