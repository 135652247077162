// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.input-group {
	> .form-control,
	> .form-control-plaintext,
	> .custom-select,
	> .custom-file,
	> .input-group-prepend,
	> .input-group-append {
		margin-bottom: $base-unit;
	}

	> .input-group-prepend > * {
		@include border-bottom-right-radius(0);
		@include border-top-right-radius(0);
		margin-bottom: 0;

		&:focus {
			z-index: 3;
		}
	}
}

.input-group-text {
	padding-top: 0;
	padding-bottom: 0;

	.inverse & {
		background-color: $blue-deep;
		border: $input-border-width solid $blue-mid;
		color: $white;
	}

	&,
	.light & {
		background-color: $white;
		border: $input-border-width solid $gray08;
		color: $blue-dark;
	}
}