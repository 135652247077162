/* ❗ If you do some changes in this file, please inform in the #help-crm Slack channel about it. ❗ */

// definitions
.bottom-links.inverse,
.footer {
	// links
	a:not(.btn) {
		color: $gray06;
		margin-bottom: 0;

		&:hover {
			color: $white;
			text-decoration: underline;
		}
	}
}

.bottom-links.inverse {
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
}

// bottom links
.bottom-links {
	.container {
		position: relative;
		padding-top: $base-unit*5;
		padding-bottom: $base-unit*2;

		@include media-breakpoint-up(lg) {
			padding-top: $base-unit*7.5;
			padding-bottom: $base-unit*3.5;
		}

		&:after {
			display: block;
			content: "";
			position: absolute;
			bottom: 0;
			inset-inline-start: $base-unit;
			height: 1px;
			width: calc(100% - #{$base-unit}*2);
			background-color: $blue-mid;
		}
	}

	// row with columns
	// needs to be relative to absolutely place social icons inside / desktop
	.internal {
		position: relative;
	}

	// basic settins for this column
	.select-region {
		// on small desktops is select-region in a separate row, not in a column
		@include media-breakpoint-only(md) {
			padding-bottom: $base-unit*3.5;
		}

		//SM :reordering items
		@include media-breakpoint-down(sm) {
			order: -2;
		}

		.logo-avast {
			margin-bottom: $base-unit*1.5;

			@include media-breakpoint-up(lg) {
				margin-bottom: $base-unit*2;
			}
		}

		.region {
			@include media-breakpoint-down(sm) {
				margin-bottom: $base-unit*2;
			}

			// region selector button
			.select-region-button {
				background-color: $transparent;
				// replacing button styles
				display: inline-flex;
				align-items: center;
				padding: 0 $base-unit 0 $base-unit/2;
				height: $base-unit*2.5;
				border: 1px solid $blue-mid;
				border-radius: $base-unit*1.25;
				text-decoration: none;
				font-size: 14px;
				box-shadow: 0 10px 20px -10px rgba($black, .4);
				cursor: pointer;

				// overflow fix
				max-width: 100%;

				&:hover {
					text-decoration: none;
				}

				span {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					display: inline-block;
					margin-right: $base-unit;
				}

				&:before {
					position: static;
					background-color: transparent;
					background-image: $region-selector-flags;
					background-repeat: no-repeat;
					content: "";
					display: inline-block;
					vertical-align: middle;
					height: $base-unit*1.5;
					width: $base-unit*1.5;
					margin-right: $base-unit/2;
				}

				.icon {
					filter: $filter-white;
				}
			}
		}
	}


	// links blocks
	.links {
		@include media-breakpoint-down(sm) {
			padding-top: $base-unit*2;
		}

		.h7 {
			margin-bottom: $base-unit;

			@include media-breakpoint-up(md) {
				min-height: $h7-line-height-m*2 + $base-unit/4; // this is a fix to prepare space for two line header
				margin-bottom: 0;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			@include media-breakpoint-up(lg) {
				min-height: $h7-line-height*2 + $base-unit/2 // this is a fix to prepare space for two line header
			}
		}

		ul {
			margin-bottom: 0;

			li:not(:last-child) {
				padding-bottom: 10px;
			}
		}
	}

	// social links
	.social {
		@include media-breakpoint-up(lg) {
			position: absolute;
			bottom: 0;
			left: $base-unit;
			display: inline-block;
		}
		// in smaller desktop social icons are on the top right position
		@include media-breakpoint-only(md) {
			position: absolute;
			top: $base-unit*5;
			right: $base-unit;
			display: inline-block;
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
			width: 100%;
			order: -1;
		}
	}
}

// footer
.footer {
	.container {
		padding-top: $base-unit*3;
		padding-bottom: $base-unit*3;

		@include media-breakpoint-down(sm) {
			padding-top: $base-unit*2;
			padding-bottom: $base-unit*2.5;
			text-align: center;
		}
	}

	.row {
		// put all items into a vertical center
		@include media-breakpoint-up(md) {
			align-items: center;
		}
	}

	// footer text
	.year {
		color: $gray06;
		margin-inline-end: $base-unit;

		/* span {
			display: flex;
			align-items: center;
			text-align: start;

			@include media-breakpoint-down(sm) {
				justify-content: center;
			}

			img {
				margin-inline-end: $base-unit/2;
			}
		} */

		// GEN Logo
		.copyright-company {
			display: inline-block;

			img {
				margin-inline-end: 4px;
				vertical-align: bottom;

				@include media-breakpoint-up(lg) {
					vertical-align: text-bottom;
				}
			}
		}

		.copyright-rights {
			white-space: nowrap;
		}
		

		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
		}
	}

	// footer link
	a:not(.btn) {
		margin-inline-end: $base-unit;

		// do not underline bullets
		&:before:hover {
			text-decoration: none;
		}

		// do not wrap links on bigger screens
		@include media-breakpoint-up(md) {
			white-space: nowrap;
		}
		@include media-breakpoint-down(sm) {
			display: block;
		}
	}

	// add bullets to all but first items
	.row > div > *:not(:first-child):before {
		content: "•";
		display: inline-block;
		margin-right: $base-unit;
		text-decoration: none;

		@include media-breakpoint-down(sm) { display: none; }
	}

	// termination
	.termination {
		@include media-breakpoint-down(sm) {
			margin-bottom: $base-unit*2.5;
		}
	}

	//SSVG for retentions
	.ssvg-disclaimer {
		a {
			display: inline;
			text-decoration: underline;
			margin: 0;
		}
	}

	// copyright
	.copyright {
		@include media-breakpoint-down(sm) {
			.year {
				margin-right: 0;
				margin-left: 0;
			}
		}
	}

	// middle block
	.middle {
		@include media-breakpoint-up(md) {
			margin-bottom: $base-unit;
			display: flex;
			flex-flow: wrap;
		}
		
		@include media-breakpoint-down(sm) {
			padding-bottom: $base-unit*2.5;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: center;

			a {
				margin-bottom: 10px;
				margin-right: 0;
				margin-left: 0;
			}
		}
	}

	//SM :reordering items
	@include media-breakpoint-down(sm) {
		.termination {order: 1;}
		.copyright {order: 4;}
		.middle {order: 3;}
		.right {order: 2;}
	}


	// LP SM :sorting items inside copyright block
	&.lp {
		.container {
			padding-bottom: $base-unit*2;

			@include media-breakpoint-down(sm) {
				padding-bottom: $base-unit*2;
			}
		}

		.year {
			margin-bottom: 0;
		}

		.copyright {
			display: flex;
			margin-bottom: $base-unit;
		}

		.right {
			margin-bottom: $base-unit;
		}
		

		@include media-breakpoint-up(md) {
			.copyright {
				flex-wrap: wrap;
			}
		}

		@include media-breakpoint-down(sm) {
			.right {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: center;
				margin-bottom: 0;

				a {
					display: inline;
					margin-bottom: 10px;
					margin-right: 0;
					margin-left: 0;
				}
			}

			// sorting items inside copyright
			.copyright {
				flex-direction: column;
				justify-content: center;

				:nth-child(1) {
					order: 3; // move copyright to last position
					padding-top: $base-unit*2.5;
					flex-basis: 100%;
				}

				a,
				#ot-sdk-btn {
					margin-right: 0;
					margin-left: 0;
				}

				a {
					margin-bottom: 10px;
				}
			}
		}
	}
}
