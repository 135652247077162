/* Hero */

// this is general hero component
// only styles that are common for all variants - so mostly content

.hero {
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;

	.container {
		padding-top: $base-unit*2;
		padding-bottom: 0;

		@include media-breakpoint-up(lg) {
			padding-top: $base-unit*4;
			padding-bottom: $base-unit*3;
		}

		@include media-breakpoint-only(lg) {
			display: flex;
			align-items: center;
			min-height: 560px;

			//fix for IE11 where is the problem with more display: flex inside each other
			> .row { flex-grow: 1; }
		}
		@include media-breakpoint-up(xl) {
			display: flex;
			align-items: center;
			min-height: 672px;

			//fix for IE11 where is the problem with more display: flex inside each other
			> .row { flex-grow: 1; }
		}

		> .row {
			align-items: center;
		}
	}

	@include media-breakpoint-down(md) {
		text-align: center;
	}

	// list icons
	.list-icons {
		margin: $base-unit*1.75 0;

		.list-icons__item .icon,
		.inverse & .list-icons__item .icon {
			filter: $filter-orange-bright ;
		}

		@include media-breakpoint-up(lg) {
			margin: $base-unit*2 0;
		}
	}


	// Elements inside hero content

	// Icon
	.hero-supheadline-icon-holder {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		width: $base-unit*4;
		height: $base-unit*4;
		border-radius: $base-unit*2;
		box-shadow: 0 15px 30px -15px rgba($blue-dark, .3);
		border: solid 1px $gray08;
		background-color: $white;
		margin-bottom: $base-unit/2;

		.hero-supheadline-icon {
			width: $base-unit*3;
		}
	}

	// text
	.hero-supheadline-text {
		text-transform: uppercase;
		margin-left: $base-unit/2;
	}


	// headline
	.hero-headline {
		word-break: break-word;

		// headline icon
		&.icon-top {
			display: flex;
			flex-flow: column;

			.product-icon {
				margin-bottom: $base-unit/2;
			}
		}

		&.icon-left {
			.product-icon {
				margin-right: $base-unit*1.5;
			}
		}
	}

	// image
	.hero-image {
		@include media-breakpoint-down(md) {
			margin-top: $base-unit*2;
		}
	}

	// platform
	.hero-platform {
		margin-bottom: $base-unit*1.5;

		img,
		.icon {
			margin-right: $base-unit;
			vertical-align: middle;
			position: relative;
			top: -.1rem; // alignment fine tuning
		}

		.icon {
			display: inline-block;
			filter: $filter-gray03;

			.inverse & {
				filter: $filter-gray06;
			}
		}
	}

	// subheadline
	.hero-subheadline {
		margin-bottom: 0;
	}

	// button group
	.hero-btn-group {
		.btn-wrapper {
			display: block;
			margin: ($base-unit*2) 0 ($base-unit*2);

			/* 	a:not([class]) {
				font-weight: $font-weight-bold;
				text-decoration: underline;
			} */
		}

		@include media-breakpoint-up(lg) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.btn-wrapper {
				display: inline-block;
			}
		}
		@include media-breakpoint-down(md) {
			display: inline-flex;
			flex-direction: column;

			.btn {
				justify-content: center;
				width: 100%;
			}
		}
	}

	// hint
	.hero-hint {
		margin: 0 auto;

		a {
			font-weight: 600;
			//text-decoration: none;
			//border-bottom: 1px solid;
		}

		p {
			padding: 0;
		}

		// layout block
		.links-blok {
			position: relative;
			margin: 0; // to reset paragraph
			padding: 0 1.5*$base-unit 0 0;
			display: inline-block; // to align them in a row

			&:first-of-type {
				margin-top: $base-unit*2;
			}

			&.last {
				padding: 0;

				@include media-breakpoint-up(lg) {
					margin-right: $base-unit*2.5;
				}

				&:before {
					position: absolute;
					left: -17px;
					top: 0;
					content: "\2022";
				}
			}
		}
	}
}
