/******************
// METRICS COMPONENT
******************/

.card.metrics {
	text-align: center;
	padding: $base-unit*2 $base-unit*1.5;

	.card-img-top {
		margin: 0 auto;
		width: auto;
		height: $base-unit*5;
		align-self: center;
	}

	.card-header {
		padding: 0;

		h1,
		h3.h1 {
			margin-bottom: 0;

			@media (min-width: 992px) and (max-width: 1279.9px) {
				font-size: $h2-font-size;
				line-height: $h2-line-height;
			}
		}

		.tagline-large {
			.inverse & { color: $orange-bright; }
			
			&,
			&.light { color: $blue-bright; }
		}

		&.header-circle {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
			margin-bottom: $base-unit;

			.image-circle {
				position: relative;
				margin: 0 auto;
				height: auto;
				max-width: $base-unit*14; //224px
				width: 100%;
				
				&-inverse { display: none; }

				.inverse & {
					display: none;

					&-inverse { display: block; }
				}
			}

			.header-circle-texts {
				position: absolute;
			}
		}
	}

	.card-body {
		padding: $base-unit/2 0 $base-unit;

		@include media-breakpoint-up(lg) {
			padding-top: $base-unit;
			padding-bottom: $base-unit*1.5;
		}
	}

	.card-footer {
		padding: 0;

		.text-small {
			margin: 0;
		}

		.btn-wrapper {
			margin-top: $base-unit;
		}
	}

	&.card-footer-empty {
		padding-bottom: $base-unit*1.5;
		@include media-breakpoint-down(lg) {
			margin-bottom: 1rem;
		}

		@include media-breakpoint-up(lg) {
			padding-bottom: $base-unit*2;
		}

		.card-body {
			padding-bottom: 0;
		}
	}
}