#e-capture-btn {
	.btn-wrapper {
		position: fixed;
		top: auto;
		right: $base-unit * 2;
		bottom: $base-unit * 3;
		z-index: 999; // lower than 1000 so its inder download popup, region selector and overlay
	}

	.btn-secondary {
		padding: 0 $base-unit;

		@include media-breakpoint-down(md) {
			padding: 0 .8rem;
		}

		span {
			display: none;
		}

		.btn-icon {
			margin: 0;
		}

		&:not(:disabled):not(.disabled) {
			&:hover,
			&:active,
			&.active,
			&:focus,
			&.focus,
			&:active:focus,
			&:hover:active,
			&:hover:focus {
				padding: 0 $base-unit*2;
				color: $blue-dark;
				box-shadow: none;

				span {
					display: block;
				}

				.btn-icon {
					margin: 0 $base-unit 0 0;
					filter: $filter-blue-dark;
				}

				&:before {
					height: 100%;
					width: 100%;
					border-radius: $btn-border-radius;
					background-color: $white;
				}
			}
		}
	}
}

#email-capture {
	.modal-content {
		text-align: left;

		@include media-breakpoint-up(lg) {
			padding-left: 7.5rem;
		}

		.headline {
			@include media-breakpoint-down(md) {
				margin-top: 40px;
			}
		}

		.modal-body {
			&:before {
				content: url("../../i/v2/components/icons/feature-icons/48x48/contact.svg");
				background-repeat: no-repeat;
				position: absolute;
				left: 0;
				top: -5px;

				@include media-breakpoint-up(lg) {
					left: -62px;
					top: 0;
				}
			}
		}

		.subheadline {
			font-size: .875rem;
		}

		.form-container {
			display: flex;
			flex-direction: column;

			.form-text {
				display: none;
			}

			#frm-email {
				@include media-breakpoint-up(lg) {
					width: 359px;
					height: 40px;
				}
				background-color: $white;
				border: 1px solid #E5E8EA;
				box-shadow: inset 0 2px 4px rgba(7, 29, 43, .12);
				border-radius: 40px;
				margin-right: 16px;
				padding: 8px 16px 8px 20px;
				width: 100%;
			}

			input.button {
				width: 121px;
				height: 40px;
				background: $blue-bright;
				box-shadow: 0 16px 32px -16px rgba(7, 29, 43, .15), 0 8px 30px -8px rgba(0, 112, 246, .32);
				border-radius: 40px;
				font-weight: 750;
				font-size: 12px;
				color: $white;
				border: none;
				text-transform: uppercase;
			}

			.captcha {
				display: flex;
				flex-direction: row;

				.form-control-wrapper {
					flex-direction: row-reverse;
					display: flex;
					margin-bottom: 20px;

					input#frm-captcha {
						background-color: $white;
						border: 1px solid #E5E8EA;
						box-shadow: inset 0 2px 4px rgba(7, 29, 43, .12);
						border-radius: 40px;
						margin-right: 16px;
						padding: 8px 16px 8px 20px;
						width: 141px;
						height: 40px;
					}

					img {
						width: 120px;
						height: 42px;
					}
				}
			}
		}

		#thank-page {
			.subheadline {
				margin-bottom: 16px;
			}

			#email-placeholder {
				font-weight: bold;
				display: block;
				margin-bottom: 32px;
			}
		}
	}
}
