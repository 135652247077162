.techcorner {
	padding: $base-unit*2 $base-unit*2 $base-unit*1.5;
	border-radius: 16px;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: $base-unit*1.5;
	}

	&-header {
		margin-bottom: $base-unit;

		.inverse & { color: $orange-bright; }
	}

	&-text {
		color: $gray03;
		position: relative;
		max-height: $base-unit*4.5;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-bottom: $base-unit*1.5;
		transition: max-height .5s ease;
	}

	&:not(.expanded) .techcorner-text:after {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 50;
		pointer-events: none;
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba($white, 0)), to($white));
		background: linear-gradient(to bottom, rgba($white, 0) 0%, $white 100%);
	}

	.inverse & {
		&:not(.expanded) .techcorner-text:after {
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba($blue-deep, 0)), to($blue-deep));
			background: linear-gradient(to bottom, rgba($blue-deep, 0) 0%, $blue-deep 100%);
		}

		.techcorner-text {
			color: $white;
		}

		.techcorner-toggler {
			color: $gray06;
		}
	}


	&-toggler {
		.btn-sm { height: $base-unit; }

		.techcorner-toggler-img {
			margin-left: $base-unit*.475;
			transform: rotate(45deg);
			transition: all .25s ease .25s;
			filter: $filter-blue-bright;

			.inverse & {
				filter: $filter-orange-bright;
			}

			&[img] {
				width: $base-unit*.8;
				height: $base-unit*.8;

				@include media-breakpoint-down(md) {
					width: $base-unit*.6;
					height: $base-unit*.6;
				}
			}
		}
	}

	&-less { display: none; }

	&.expanded {
		.techcorner-text { max-height: 500px; }

		.techcorner-more { display: none; }

		.techcorner-less {
			display: inline;
			white-space: nowrap;
		}

		.techcorner-toggler-img {
			transform: rotate(0);
		}
	}

	&.techcorner-right,
	&.techcorner-left {
		&:before,
		&:after {
			content: " ";
			display: block;
			background-color: $orange-bright;
			position: absolute;
			border-radius: 3px;

			@include media-breakpoint-down(md) {

			}
		}
	}

	&.techcorner-right {
		border-top-right-radius: 0;

		&:before {
			width: $base-unit*3;
			height: 3px;
			top: -2px;
			right: -2px;
		}

		&:after {
			width: 3px;
			height: $base-unit*3;
			top: -2px;
			right: -2px;
		}

	}

	&.techcorner-left {
		border-top-left-radius: 0;

		&:before {
			width: $base-unit*3;
			height: 3px;
			top: -2px;
			left: -2px;
		}

		&:after {
			width: 3px;
			height: $base-unit*3;
			top: -2px;
			left: -2px;
		}
	}
}
