// Main element imports
// @import "./layout/font-face"; removed from global style because it will be defined separately for each lang
@import "./layout/header";
@import "./layout/footer";
@import "./layout/region-flags";

// Body pseudo elements to display media info
body:after {

	@include media-breakpoint-down(sm) {
		content: 'mobile' !important;
		display: none !important;
	}
	@include media-breakpoint-only(md) {
		content: 'tablet' !important;
		display: none !important;
	}
	@include media-breakpoint-up(lg) {
		content: 'desktop' !important;
		display: none !important;
	}
}

// Smooth scroll
html {
	scroll-behavior: smooth;
}
