.button-circle {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 0;
	width: $base-unit*3;
	height: $base-unit*3;
	border-radius: 50%;
	transition: all .1s ease-in;

	// specific icons for hover states. Fixes IE11 issue.
	img {
		width: $base-unit*1.5;
		height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@include media-breakpoint-down(md) {
		width: $base-unit*2.5;
		height: $base-unit*2.5;

		img { width: $base-unit; }
	}

	.btn-icon-normal { display: block; }

	.btn-icon-hover { display: none;}


	/* small circle */
	&.button-circle-sm {
		width: $base-unit*2.5;
		height: $base-unit*2.5;

		img {
			width: $base-unit;
			height: auto;
		}

		@include media-breakpoint-down(md) {
			width: $base-unit*2.25;
			height: $base-unit*2.25;
		}
	}
	
	&:focus {
		outline: none;
	}

	&.disabled,
	&:disabled,
	&:hover:disabled,
	&:hover.disabled {
		pointer-events: none;

		&.alt-opacity {opacity: 1;}
	}

	&:hover:not(.disabled),
	&:hover:not(:disabled) {
		.btn-icon-normal { display: none; }

		.btn-icon-hover { display: block;}
	}
	
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):hover,
	&:not(:disabled):not(.disabled):active:focus,
	&:not(:disabled):not(.disabled):hover:focus {
		outline: none;
	}
}

// Since we need to assign different colors to certain classes (Marketing request based on testing GLOWEB-1727)
.button-circle-primary {
	@include button-circle-primary($btn-theme-primary);
}

.button-circle-secondary {
	@include button-circle-secondary($btn-theme-secondary);
}

.button-circle-secondary.monochrome {
	@include button-circle-secondary($btn-theme-monochrome);
}