// styles for cookie bar
.cookie-bar {
	left: 0;
	z-index: 1000;
	width: 100%;
	background-color: $blue-dark;
	padding-left: $base-unit * 2.5;
	padding-right: $base-unit * 2.5;

	@include media-breakpoint-down(lg) {
		position: fixed;
		bottom: 0;
		padding-bottom: $base-unit * 2;
		padding-top: $base-unit * 2;
	}
	@include media-breakpoint-up(lg) {
		position: relative;
		top: 0;
		padding-bottom: 0;
		padding-top: $base-unit;
		padding-bottom: $base-unit;
	}
	@include media-breakpoint-only(md) {
		padding-bottom: $base-unit;
	}

	.cookie-bar-inner {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}

		.js-close {
			span {
				white-space: nowrap;
			}
		}
	}

	p {
		margin-bottom: 0px;
		a {
			font-weight: 600;
			text-decoration: underline;
		}

		a:hover {
			text-decoration: none;
		}

		@include media-breakpoint-down(sm) {
			padding-bottom: $base-unit;
			text-align: center;
		}

		@include media-breakpoint-up(md) {
			margin-right: $base-unit * 4;
		}
	}

	.button-wrapper {
		@include media-breakpoint-down(sm) {
			justify-content: center;
		}
	}
}