.social-icons {
	display: inline-flex;
	align-items: center;

	.icon {
		filter: $filter-blue-dark;

		.inverse & {
			filter: $filter-gray06;
		}

		&:hover {
			filter: $filter-blue-deep;

			.inverse & {
				filter: $filter-white;
			}
		}
	}

	a:not(:last-of-type) {
		margin-right: $base-unit*1.5;

		@include media-breakpoint-only(md) { //better arangement in the footer
			margin-right: $base-unit*1.25;
		}
	}
}