/*	TO FIX:
		Some of RTL styles are here because of collision with mixin and borders used here. Might be probably solved better, be welcome to fix that, please.
*/

.tables-common {
	$border-frame: 1px solid;
	$border-highlight: 2px solid $orange-bright;
	$thWidth1: $base-unit*28; // 448px - row th width for 2-3 columns
	$thWidth2: $base-unit*21; // 336px - row th width for 4-5 columns

	margin: 0 auto;
	border-collapse: separate;
	border-spacing: 0;
	text-align: initial; // negates parent wrapper text alignment;

	&:not(.columns-2:not(.variant-simple)) {
		width: 100%;
		table-layout: fixed;
	}

	td,
	th {
		background-clip: padding-box;
	}


	// Columns width
	.thead-headline,
	.thead-badge-th,
	tbody th:first-of-type,
	tfoot th:first-of-type {
		width: $thWidth1;
		@media (max-width: $breakpoint-custom-lg1) { width: $thWidth2; }
	}

	&.columns-2:not(.variant-simple) {
		.thead-cell,
		.header-badge,
		tbody td,
		tfoot td:not([colspan]) {
			width: $base-unit*19; //304px
		}
	}

	&.columns-4,
	&.columns-5 {
		.thead-headline,
		.thead-badge-th,
		tbody th:first-of-type,
		tfoot th:first-of-type {
			width: $thWidth2;
		}
	}


	// Cells padding and alignment
	thead {
		th {vertical-align: top;}
		.thead-headline {padding: $base-unit $base-unit $base-unit $base-unit/2;}
		.thead-cell {padding: $base-unit*1.5 $base-unit*2;}
	}

	tbody {
		th, td {
			height: $base-unit*3.5;
			padding: $base-unit*.75 $base-unit/2 calc(#{$base-unit}*.75 - 1px);
			margin: 0; //for case the typo class is applied on cell
		}

		td>.icon {margin: 0 auto;}
	}

	.table-row-header-gold {
		th[scope='row'] {
			text-align: initial;
			padding: $base-unit*1.25 0 calc(#{$base-unit}*1.25 - 1px) $base-unit*2;
		}

		th[scope='col'] {
			.tagline-small { margin-top: $base-unit/2; }
			.h6 { margin: 0; }
		}
	}

	tfoot {
		th {
			vertical-align: top;
			padding: $base-unit*2 0 0;
		}

		td {
			vertical-align: top;
			padding: 0;

			& > .btn-wrapper {
				margin: $base-unit/2;

				@include media-breakpoint-up(md) {
					margin: $base-unit;
				}

				@include media-breakpoint-up(lg) {
					margin: $base-unit*2 $base-unit;
				}

			}
		}
	}

	&.variant-simple tfoot td:not([colspan]) {
		padding: $base-unit/2;

		@include media-breakpoint-up(md) {
			padding: $base-unit;
		}

		@include media-breakpoint-up(lg) {
			padding: $base-unit*2 $base-unit;
		}
	}

	&.headers-centered .thead-cell,
	tbody td,
	tbody th[scope='col'],
	tfoot td {
		text-align: center;
	}


	// Head Content
	thead {
		.header-badge {
			height: $base-unit;
			position: relative;

			.badge {
				position: absolute;
				bottom: -$base-unit*.75;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		.thead-headline {
			.headline-content {
				margin-bottom: 0;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.badge {
					margin-top: $base-unit/2;
				}
			}
		}
	}


	// Foot Content
	tfoot {
		// Abox and Tbox override
		.try-box {
			margin: 0;
			border: none;
			width: auto;

			.inverse & { background-color: transparent; }

			.try-box-container {
				width: auto;
				padding-left: $base-unit/4;
				padding-right: $base-unit/4;
			}
		}

		.action-box-container {
			.action-box {
				margin: 0;
				border: none;
				width: auto;
				padding-left: $base-unit/4;
				padding-right: $base-unit/4;

				&__platforms {
					display: none;
				}

				&.action-box--store {
					.platforms {
						display: none;
					}
				}


				.inverse & { background-color: transparent; }
			}


			@include media-breakpoint-up(md) {
				&.show-toggler {
					margin-top: $base-unit*2.5;
				}
			}
		}

		.smb-abox-wrapper {
			margin-top: $base-unit*2;
		}

		tr th {
			font-weight: normal;
		}

		.footer-headline {
			display: none;
			margin: 0;

			a { text-decoration: none; }
		}

		.footer-platforms {
			display: none;
			margin-top: $base-unit/2;
			margin-bottom: $base-unit;

			.icon { margin-right: $base-unit/2; }
		}
	}


	// FOOTER MOBILE PRICING ALTERATION
	@include media-breakpoint-down(sm) {
		&:not(.variant-simple) tfoot {
			tr {flex-direction: column;}

			th {
				.inverse & {background-color: $blue-dark;}

				&,
				.light & {
					background-color: $white;
				}
			}

			td {
				text-align: initial;
				border-bottom: $border-frame $gray08 !important;
				padding: 0 $base-unit;

				.inverse & {
					border-color: $gray02 !important;
				}

				.light & {
					border-color: $white;
				}
			}
		}

		&.variant-gold tfoot {
			td {
				border-color: $orange-pale !important;
			}
		}

		tfoot {
			.try-box,
			.try-box .try-box-container {
				padding: 0;

				.box {
					flex-direction: row;
					justify-content: flex-end;
				}

				.header-wrap,
				.slot,
				.discount-placeholder {
					display: none;
				}

				.tip {margin: 0;}
			}

			.action-box-container {
				.action-box__toggler {
					left: 0;
					top: $base-unit*2.5;
					transform: none;
					max-width: 50%;

					.form-select-arrow:after {
						right: $base-unit*.75;
						top: 9px;
					}

					.form-select {
						padding: $base-unit/2 $base-unit*2.75 $base-unit/2 $base-unit;
					}

					.toggler-horizontal {
						margin: 0;
					}
				}

				.action-box {
					padding: 0;
					text-align: initial;
					grid-template-areas:
						"title tableMobileSection"
						"platforms tableMobileSection";
					grid-template-columns: 1fr 1fr;
					grid-template-rows: auto 1fr;
					column-gap: 8px;

					.action-box__title {
						display: none;
					}

					.table-mobile-section {
						position: relative;
						top: -$base-unit;
						text-align: right;
					}

					&__price,
					&__cta {
						display: block;
					}

					&__price,
					&__priceOld {
						height: auto;
					}

					&__cta {
						padding-top: $base-unit;
					}

					&__label {
						height: auto;
						padding-bottom: $base-unit/2;
					}

					.action-box__cta {
						padding: $base-unit/2 0;
					}

					.cta-note {
						text-align: end;
					}

					.action-box__price {
						.currency,
						.integer,
						.decimal,
						.period {
							font-size: $base-unit*1.125;
							line-height: $base-unit*1.6875;
						}

						.price-prefix,
						.price-suffix {
							margin-top: 0;
							display: flex;
						}
					}

					.platforms {
						display: flex;
						justify-content: flex-start;
						align-items: flex-start;

						@include media-breakpoint-down(md) {
							margin-top: $base-unit/2;
						}

						img, .icon {
							display: inline-block;
							margin: 0 $base-unit/2 0 0;
						}
					}

					.action-box-content {
						text-align: initial;

						&__title,
						&__platform {
							display: none;
						}
					}

					&.action-box--store {
						grid-template-areas: "platforms tableMobileSection";

						.platforms {
							grid-area: platforms;
							display: block;
						}

						.action-box-content {
							grid-area: tableMobileSection;
							text-align: end;
							padding-bottom: $base-unit;
						}
					}
				}
			}

			.footer-headline {
				display: block;
			}

			.footer-platforms {
				display: flex;
			}
		}
	}


	// Borders
	tbody tr th {
		.inverse & {
			border-bottom: $border-frame $blue-mid;
		}

		&,
		.light & {
			border-bottom: $border-frame $gray08;
		}
	}

	tbody tr td {
		border-bottom: $border-frame $gray08;
	}

	&.variant-gold {
		tbody tr th,
		tbody tr td {
			border-bottom: $border-frame $orange-pale;
		}
	}

	// Frame
	&:not(.unframed) {
		thead th[scope='col'] {
			border-top: $border-frame $gray08;
		}

		tbody tr.table-row-header-gold:first-of-type th[scope='col'] {
			border-top: $border-frame $orange-pale;
		}

		thead, tbody {
			th[scope='col'] {
				border-left: $border-frame $gray08;

				& ~ th {
					border-left: none;
				}

				&:last-of-type {
					border-right: $border-frame $gray08;
				}
			}
		}

		tfoot td:not([colspan]) {
			border-bottom: $border-frame $gray08;
		}

		tbody td,
		tfoot td:not([colspan]) {
			&:first-of-type {
				border-left: $border-frame $gray08;
			}

			&:last-of-type {
				border-right: $border-frame $gray08;
			}
		}

		body.rtl & {
			thead, tbody {
				th[scope='col'] {
					border-left: none;
					border-right: $border-frame $gray08;

					& ~ th {
						border-right: none;
					}

					&:last-of-type {
						border-right: none;
						border-left: $border-frame $gray08;
					}
				}
			}

			tbody td,
			tfoot td:not([colspan]) {
				&:first-of-type {
					border-right: $border-frame $gray08;
					border-left: none;
				}

				&:last-of-type {
					border-right: none;
					border-left: $border-frame $gray08;
				}
			}
		}

		.inverse &,
		body.rtl .inverse & {
			thead th[scope='col'],
			tbody td,
			tfoot td:not([colspan]) {
				border-color: $blue-mid-mid;
			}
		}

		&,
		.light &,
		body.rtl &,
		body.rtl .light & {
			thead th[scope='col'],
			tbody td,
			tfoot td:not([colspan]) {
				border-color: $gray08;
			}
		}
	}

	&.variant-gold:not(.unframed) {
		thead th[scope='col'],
		tbody td,
		tbody th[scope='col'],
		tfoot td:not([colspan]) {
			border-color: $orange-pale;
		}
	}


	// Colors
	.inverse & {
		tbody td {
			background-color: $blue-deep;

			&.bg-tinged {
				background-color: $blue-mid;
			}
		}

		.thead-cell,
		tfoot td {
			background-color: $blue-deep;
		}
	}

	&,
	.light & {
		.thead-cell,
		tbody td,
		tfoot td {
			background-color: $white;
		}
	}

	// bg color for these cells is determined by parent's bg class
	.thead-headline,
	tfoot th,
	thead tr.head-row-badges {
		.inverse & {background-color: $blue-dark;}

		.light & {background-color: $white;}

		[class*='bg-'] .bg-orange-faint &,
		[class*='bg-'] .bg-faint-orange &,
		.bg-orange-faint &,
		.bg-faint-orange & {
			background-color: $orange-faint;
		}

		[class*='bg-'] .bg-orange-off-white &,
		.bg-orange-off-white & {
			background-color: $orange-off-white;
		}

		[class*='bg-'] .bg-white &,
		.bg-white & {
			background-color: $white;
		}

		@each $gray, $value in $grays-map {
			[class*='bg-'] .bg-#{$gray} &,
			.bg-#{$gray} & {
				background-color: $value;
			}
		}
	}


	// Icon colours
	tbody td {
		.inverse & {
			.icon-false {
				filter: $filter-gray02;
			}
			.icon-true {
				filter: $filter-white;
			}

			&.bg-tinged {
				.icon-false {
					filter: $filter-gray03;
				}
			}
		}

		&,
		.light & {
			.icon-false {
				filter: $filter-gray07;
			}

			.icon-true {
				filter: $filter-blue-dark;
			}
		}
	}

	// Themes
	&.theme {
		&,
		.light & {
			&-blue {
				.bg-tinged {
					background-color: $blue-faint;

					.icon-true {
						filter: $filter-blue-bright;
					}
				}
			}

			&-green {
				.bg-tinged {
					background-color: $green-faint;
				}
			}

			&-orange {
				.bg-tinged {
					background-color: $orange-faint;

					.icon-false {
						filter: $filter-orange-pale;
					}
				}
			}
		}
	}

	// Border Orange Theme
	&.borders-orange:not(.variant-gold) {
		$border-orange: $orange-pale;

		// FOOTER MOBILE PRICING ALTERATION
		@include media-breakpoint-down(sm) {
			&:not(.variant-simple) tfoot {
				td {
					border-color: $border-orange;
				}
			}
		}

		// Borders
		tbody tr th,
		tbody tr td {
			border-color: $border-orange;
		}

		// Frame
		&:not(.unframed) {
			thead th[scope='col'],
			tbody tr.table-row-header-gold:first-of-type th[scope='col'] {
				border-color: $border-orange;
			}

			&,
			body.rtl & {
				thead, tbody {
					th[scope='col'] {
						border-color: $border-orange;

						&:last-of-type {
							border-color: $border-orange;
						}
					}
				}

				tfoot td:not([colspan]) {
					border-color: $border-orange;
				}

				tbody td,
				tfoot td:not([colspan]) {
					&:first-of-type,
					&:last-of-type {
						border-color: $border-orange;
					}
				}
			}
		}
	}


	// Highlight
	@for $i from 0 through 5 {
		&.highlight-#{$i},
		body.rtl &.highlight-#{$i} {
			thead th[scope='col']:nth-child(#{$i + 1}),
			tbody tr.table-row-header-gold th[scope='col']:nth-child(#{$i + 1}) {
				border-top: $border-highlight !important;
			}

			tbody tr.table-row-header-gold:not(:first-of-type) th[scope='col']:nth-child(#{$i + 1}) {
				border-top-color: transparent !important;
			}

			thead th[scope='col'],
			tbody td,
			tbody tr.table-row-header-gold th[scope='col'],
			tfoot td:not([colspan]) {
				&:nth-child(#{$i + 1}) {
					border-left: $border-highlight !important;
					border-right: $border-highlight !important;
				}
			}

			tfoot td:nth-child(#{$i + 1}):not([colspan]) {
				border-bottom: $border-highlight !important;
			}
		}

		&.highlight-#{$i}.no-footer {
			tbody tr:last-child td:nth-child(#{$i + 1}) {
				border-bottom: $border-highlight !important;
			}
		}
	}





	// Sticky header
	&.sticky-header.variant-compare {
		thead,
		thead tr {
			position: -webkit-sticky;
			position: sticky;
			top: 0;
			//z-index: 3;
		}

		thead,
		thead tr.head-row-headers { z-index: 3; }

		thead tr.head-row-badges { z-index: 4; }

		tfoot {
			tr td,
			tr th {
				position: relative;
				z-index: 4;
				width: 100%; // for mobile resolution to cover whole width under table and overlap floating header properly

				.btn-wrapper[data-cmp-name='cmp-collapse-button'] {
					margin-bottom: $base-unit*2;
				}
			}
		}
	}

	&.sticky-header.variant-gold {
		tbody tr.table-row-header-gold {
			position: -webkit-sticky;
			position: sticky;
			top: 0;
			background-color: $white;
			z-index: 4;
		}
	}


	/* Mobile Modifications ///////////////////////////////////////////////////////////////////////
	Since the table is native element, it wont help to use mobile first approach here. Or does it? */
	@mixin table-mobile-structure($cols) {
		&.columns-#{$cols} {
			// Restructured cells
			&,
			thead,
			tbody,
			tfoot {
				display: block;

				tr {
					display: flex;
					flex-wrap: wrap;
					text-align: center;
				}
			}

			.thead-headline,
			tbody th,
			tbody th:first-of-type,
			tfoot th,
			tfoot td[colspan] {
				margin: 0 auto;
				width: 100%;
			}

			.thead-cell,
			tbody td,
			tbody th:not(:first-of-type),
			tfoot td:not([colspan]) {
				width: auto;
				flex-basis: calc(100%/#{$cols});
			}

			&:not(.variant-simple) {
				.thead-cell,
				.header-badge,
				tbody td,
				tfoot td:not([colspan]) {
					width: auto;
					flex-basis: calc(100%/#{$cols});
				}
			}

			tfoot th {
				order: 6;
				padding: $base-unit*1.5 0;

				.btn-wrapper[data-cmp-name='cmp-collapse-button'] {
					display: none;
				}

				&>div {
					display: inline-block;
				}
			}

			.thead-headline {
				padding: 0;

				.headline-content {
					display: none;
				}
			}

			thead tr.head-row-badges {
				display: none;
			}

			.thead-cell {
				padding: $base-unit;
			}

			tbody {
				th, th[scope='row'] { padding: $base-unit*1.5 $base-unit/2 0; }
				td { padding: $base-unit*.75 $base-unit/2 $base-unit*1.5; }
				th, td { height: auto; }

				.table-row-header-gold {
					th[scope='col'] {
						padding: $base-unit $base-unit/2 calc(#{$base-unit} - 1px);
						display: inline-flex;
						margin: 0;
						flex-direction: column;
						justify-content: center;
					}

					th[scope='row'] {
						text-align: center;

						&.h6 { // gold row headers are bigger on mobile
							font-size: $h4-font-size-m;
							line-height: $h4-line-height-m;
						}
					}
				}
			}

			// Row header
			.table-row-header {
				align-items: center;
				text-align: center;

				&.with-product-icon,
				.cell-tooltip {
					margin-left: 0;

					body.rtl & {
						margin-right: 0;
					}
				}

				&.with-tooltip .row-header-texts {
					text-align: center;
				}

				&.with-tooltip.with-product-icon .row-header-texts {
					text-align: initial;
				}

				&.with-product-icon,
				&.with-tooltip {
					flex-direction: row;
					justify-content: center;
				}
			}

			// Table header
			.table-header {
				flex-direction: column;
				align-items: center;
				text-align: center;

				.header-product-icon,
				.header-product-box {
					margin: 0 0 $base-unit*.75 0;
				}

				.header-platform {
					justify-content: center;
				}
			}

			// Colors
			tbody th, tbody td,
			&[class*='theme-'] td.bg-tinged {
				&,
				.inverse & {
					background-color: transparent;
				}

				.icon-true {
					.inverse & {filter: $filter-white;}

					&,
					.light & {filter: $filter-blue-dark;}
				}

				.icon-false {
					.inverse & {filter: $filter-gray02;}

					&,
					.light & {filter: $filter-gray07;}
				}
			}

			tbody tr {
				&:nth-of-type(even) {
					.inverse & {
						background-color: $blue-deep;
					}

					&,
					.light & {
						background-color: $white;
					}
				}

				th {
					border-bottom: none;
				}

				.inverse & {
					th {
						border-color: $blue-dark;
						border-bottom-color: transparent;
					}

					td.bg-tinged {
						border-bottom: $border-frame $blue-mid;
					}
				}


				.light & {
					th {
						border-color: $gray08;
						border-bottom-color: transparent;
					}

					td.bg-tinged {
						border-bottom: $border-frame $gray08;
					}
				}
			}

			// cells have different padding when the table is without description column
			&.no-row-headers tbody {
				td { padding: $base-unit*1.5 $base-unit/2; }
			}

			// Remove Frame and Highlights
			&:not(.unframed),
			&[class*='highlight-'],
			&[class*='highlight-'].no-footer {
				&,
				body.rtl & {
					thead th[scope='col'],
					tbody tr:last-child td,
					tfoot td:not([colspan]){
						border: none !important;
					}

					tbody td,
					tbody tr.table-row-header-gold th[scope='col'] {
						border-top: none !important;
						border-right: none !important;
						border-left: none !important;
					}
				}
			}

			// Sticky Header
			&.sticky-header.variant-compare {
				thead {
					th:not(:first-of-type) {
						position: -webkit-sticky;
						position: sticky;
						top: 0;
					}
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		@include table-mobile-structure(2);
		@include table-mobile-structure(3);
	}
	@media (max-width: $breakpoint-custom-lg1) {
		@include table-mobile-structure(4);
		@include table-mobile-structure(5);
	}

	&.columns-5 {
		.thead-cell {
			padding: $base-unit*1.5 $base-unit;

			@include media-breakpoint-down(md) {
				padding: $base-unit $base-unit/4;
			}

			@media (max-width: 440px) {
				padding: $base-unit 0;
			}
		}
	}
}


// Body Row Header Component
.table-row-header {
	font-weight: 400;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0 auto;
	text-align: initial;
	/* color: $blue-dark;

	.inverse & {
		color: $white;
	} */

	&.with-product-icon,
	&.with-tooltip {
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;

		.row-header-texts {
			text-align: initial;
		}
	}

	&.with-product-icon {
		margin-left: -$base-unit/2;
	}

	// Product Icon
	.product-icon {
		margin-right: $base-unit/2;
	}

	.text-small {
		margin: 0;
	}

	// text
	.row-header-texts {
		display: flex;
		flex-direction: column;
		padding-right: $base-unit;
		text-align: center;
	}

	&.with-product-icon .row-header-texts {
		text-align: initial;
	}

	// Tooltip
	.cell-tooltip {
		@include sprite-image("icons", "info", 16);
		cursor: pointer;
		outline: none;
		flex: 0 0 auto;
		margin-left: auto;

		&:hover,
		&:focus {
			@include sprite-image("icons", "hover-info", 16);
		}

		.inverse & {
			filter: $filter-white;
		}

		&,
		.light & {
			filter: $filter-blue-dark;
		}
	}
}




// Table Header component
.table-header {
	thead & { // makes sure this will apply only on tables header in thead element
		display: inline-flex;
		flex-direction: row;
		align-items: initial;
		text-align: initial;
		/* color: $blue-dark;

		.inverse & {
			color: $white;
		} */

		.header-product-icon,
		.header-product-box {
			margin: 0 $base-unit $base-unit*.75 0;
		}

		.header-content {
			flex: 1 1 auto;

			.header-name.h6 {
				margin-bottom: $base-unit/2;

				@include media-breakpoint-down(md) {
					.tables--col-4 & { //needs to be removed once the old type of table is gone
						font-size: $body-sm-font-size-m; //12
						line-height: $body-sm-line-height-m; //18
					}

					.tables--col-5 & { //needs to be removed once the old type of table is gone {
						font-size: $caption-font-size-m;
						line-height: $caption-line-height;
					}
				}

				@include media-breakpoint-only(xs) {
					.tables-common.columns-5 & {
						font-size: $h7-font-size-m;
						line-height: $h7-line-height-m;
					}
				}
			}

			.header-info {
				margin-bottom: $base-unit/2;
				display: block;
				min-height: $base-unit;
				font-weight: initial;
			}

			.header-platform {
				display: flex;
				align-items: center;
				margin-bottom: $base-unit;

				.icon,
				img {
					width: $base-unit;
					margin-right: $base-unit/2;

					.inverse & {
						filter: $filter-gray06;
					}

					&,
					.light & {
						filter: $filter-gray03;
					}
				}
			}

			.header-more {
				font-size: $base-unit*.75;
				line-height: 1.33;
				font-weight: 800;
				letter-spacing: .8px;
				text-transform: uppercase;
				transition: all ease-out .2s;
				display: inline-block;

				.inverse & {
					//color: $gray06;
					border-bottom: 1px solid $gray06;
				}

				&,
				.light & {
					border-bottom: 1px solid $blue-dark;
				}
			}

			@include media-breakpoint-down(sm) {
				.header-info,
				.header-platform,
				.header-more,
				.header-more-button {
					display: none;
				}
			}
		}

		&:hover {
			text-decoration: none;

			.header-more {
				.inverse & {
					color: $white;
					border-bottom: 1px solid $white;
				}

				&,
				.light & {
					color: $blue-bright;
					border-bottom: 1px solid $blue-bright;
				}
			}
		}

		.tables.tables--centered &, //needs to be removed once the old type of table is gone
		.tables-common.headers-centered & {
			flex-direction: column;
			align-items: center;
			text-align: center;

			.header-product-box {
				margin: 0 0 $base-unit*.75;
			}

			.header-product-icon {
				margin: 0 0 $base-unit*.5;
			}

			.header-platform {
				justify-content: center;
			}
		}

		.tables:not(.tables--centered) & { //needs to be removed once the old type of table is gone
			@include media-breakpoint-down(md) {
				flex-direction: column;
				align-items: center;
				text-align: center;

				.header-product-icon,
				.header-product-box {
					margin: 0 0 $base-unit*.75;
				}

				.header-platform {
					justify-content: center;
				}
			}
		}
	}
}


// Headline visible only on mobiles since the headline in thead should ot be visible on sticky headers
.tables-common-mobile-headline {
	display: none;
	margin-bottom: $base-unit*2;
	flex-direction: column;
	align-items: center;
	text-align: center;

	.badge {
		margin-top: $base-unit/2;
	}


	@include media-breakpoint-down(md) {
		&.columns-1,
		&.columns-2,
		&.columns-3 {
			display: flex;
			margin: 0 auto  $base-unit*2;
		}
	}

	@media (max-width: $breakpoint-custom-lg1) {
		&.columns-4,
		&.columns-5 {
			display: flex;
			margin: 0 auto  $base-unit*2;
		}
	}
}
