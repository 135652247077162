/* Hero centered */

// centered text
// background image with gradient overlay

// inverse variant only (because photo background is always considered dark)

.hero-centered {
	position: relative; // needed for absolute positioning of the overlay

	// styles for bg image
	background-size: cover;
	background-repeat: no-repeat;
	background-position-x: 85%;

	@include media-breakpoint-up(sm) {
		background-position-x: 95%;
	}
	@include media-breakpoint-up(md) {
		background-position-x: center;
	}

	// overlay container :before
	// gradient container :after
	&:before,
	&:after {
		display: block;
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	// in normal variant they are empty by default, but can be customized if needed

	// inverse variant / overlay and gradient
	&.inverse {

		// dark overlay
		&:before {
			background-color: $blue-dark;
			opacity: .6;
		}
		// dark gradient
		&:after {
			background-image: linear-gradient(to top, $blue-dark, rgba($blue-dark, 0) 30%);
		}
	}

	.container {
		position: relative;
		z-index: 3; // moving it above overlay and gradient pseudo elements
		min-height: auto;
		padding-bottom: $base-unit*4;
		justify-content: center;
		text-align: center;

		& > .row {
			justify-content: center;
			text-align: center;
		}

		@include media-breakpoint-up(lg) {
			padding-bottom: $base-unit*8;
		}
	}

	// headline icon
	.hero-headline {
		&.icon-top {
			align-items: center;
			-ms-flex-align: center;
		}

		&.icon-left {
			align-items: start;
			-ms-flex-align: start;

			@include media-breakpoint-down(sm) {
				display: flex;
				flex-flow: column;
				align-items: center;
				-ms-flex-align: center;

				.product-icon {
					margin-right: 0;
				}
			}
		}

		.headline-text {
			max-width: 100%; //IE11 fix
		}
	}

	// button group
	.hero-btn-group {
		justify-content: center;

		@include media-breakpoint-up(lg) {

			// has margin to separate multiple buttons
			.btn-wrapper {
				margin-left: $base-unit;
				margin-right: $base-unit;
			}
		}
	}

	.hero-headline,
	.hero-platform,
	.hero-subheadline {
		@include media-breakpoint-up(lg) {
			max-width: 1133px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	// hint - text under buttons
	.hero-hint {
		margin-bottom: 0;

		.money-back .money-back__item {
			padding-top: 0;
			margin-bottom: 0;
		}

		.links-blok {
			&.last {
				margin-right: 0;

				&:first-of-type:before {
					display: none;
				}
			}
		}
	}

	.award-badges-wrap {
		justify-content: center;
	}
}
