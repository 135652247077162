/******************
// AWARDS LOGO COMPONENT
******************/

.award-badges-wrap {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: column;

	@include media-breakpoint-up(sm) {
		flex-direction: row;
	}

	// alignment in the Hero component
	@include media-breakpoint-down(md) {
		.hero & {
			justify-content: center;
		}
	}

	.award-badges {
		display: flex;
		align-items: center;
		margin-bottom: $base-unit*1.5;
		margin-right: 0;

		.badge-text {
			text-align: initial;
			margin: 0;
			font-weight: 400;

			&.line-break strong,
			&.line-break b {
				display: block;
			}
		}

		.rating {
			margin-top: $base-unit/2;

			.stars {
				display: block;
				margin: 0;

				.inverse & {
					filter: $filter-orange-bright;
				}
			}
		}

		
		// Size S
		&.size-s {
			.badge-image { margin-right: $base-unit/2; }

			.badge-text {
				min-width: $base-unit*6;
			}
			
			@include media-breakpoint-up(sm) {
				&:not(.no-margin) {
					margin-right: $base-unit*1.5;
				}

				.badge-text {
					max-width: $base-unit*8.5;
				}
			}
		}


		// Size M
		&.size-m {
			.badge-image { margin-right: $base-unit*2; }
			.badge-text { line-height: $base-unit; }

			@media (max-width: 359.98px) {
				flex-direction: column;

				.badge-image { 
					margin-right: 0;
					margin-bottom: $base-unit/2;
				}

				.badge-text { text-align: center; }
				
				.rating .stars {
					margin: 0 auto;
				}
			}

			@include media-breakpoint-up(md) {
				&:not(.no-margin) {
					margin-right: $base-unit*4;
				}
			}

			@include media-breakpoint-up(lg) {
				.badge-text {
					line-height: $base-unit*1.5;
					max-width: $base-unit*10;
				}
			}
		}


		// Size L
		&.size-l {
			.badge-image {
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				
				img {
					max-width: $base-unit*7.5;
					margin: 0 auto;
				}
			}

			.badge-text {
				max-width: $base-unit*21;
				margin-left: $base-unit*1.5;
			}

			@media (max-width: 359.98px) {
				flex-direction: column;

				.badge-text {
					margin-left: 0;
					margin-top: $base-unit/2;
					text-align: center;
				}
			}
			
			@include media-breakpoint-up(sm) {
				&:not(.no-margin) {
					margin-right: $base-unit*1.5;
				}
			}

			@include media-breakpoint-up(lg) {
				&:not(.no-margin) {
					margin-right: $base-unit*3;
				}
			}
		}
	}
}