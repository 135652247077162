/******************
// NOTICE COMPONENT
******************/

.notice {
	.card-img-top {
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	// Lazy load background images
	div.card-img-top {
		&.js-background-hidden {
			background-image: none !important;	// overwrite inline-style defined background-image
			opacity: 0;
		}

		&.js-background-visible {
			opacity: 1;
			transition: opacity 150ms ease-in;
		}
	}

	.card-title {
		margin-bottom: $base-unit/2;

		@include media-breakpoint-up(lg) {
			margin-bottom: $base-unit;
		}
	}

	/***********
	NOTICE in PRIMARY CARD
	***********/
	&.card-primary {
		text-align: center;

		@include media-breakpoint-down(md) {
			margin-bottom: $base-unit;
		}

		.card-img-wrapper {
			padding: $base-unit*2 $base-unit*1.5 $base-unit*1.5;

			.card-img-top {
				margin: 0 auto;
				border-radius: 50%;
				max-width: 128px;
				height: auto;
			}

			@include media-breakpoint-up(lg) {
				padding: $base-unit*3 $base-unit*2.5 $base-unit*2.5;

				.card-img-top { max-width: 264px; }
			}
		}

		.card-body {
			padding: 0 $base-unit*1.5;
			margin-bottom: $base-unit*1.5;

			@include media-breakpoint-up(lg) {
				padding: 0 $base-unit*2.5;
				margin-bottom: $base-unit*2.5;
			}
		}

		.badge {
			position: relative;
			margin-bottom: $base-unit/2;

			@include media-breakpoint-up(lg) {
				margin-bottom: $base-unit;
			}
		}

		.card-footer {
			padding: 0 $base-unit*1.5;
			margin-bottom: $base-unit*2;

			@include media-breakpoint-up(lg) {
				padding: 0 $base-unit*2.5;
				margin-bottom: $base-unit*3;
			}
		}
	}


	/**************
	NOTICE in SECONDARY CARD
	*************/
	&.card-secondary {
		.badge {
			position: absolute;
		}

		&.card-large {
			.card-img-top {
				padding-top: 62.037%;
				border-radius: 0 $base-unit*2 0 0;
			}

			.card-body {
				padding: $base-unit*1.5;

				@include media-breakpoint-up(lg) {
					padding: $base-unit*1.5 $base-unit*2;
				}

				.card-text { margin-bottom: 0; }
			}

			.badge-position-middle {
				margin-top: -$base-unit*2.125;

				@include media-breakpoint-up(lg) {
					margin-top: -$base-unit*2.25;
				}
			}

			.card-footer {
				text-align: right;
				padding: 0 $base-unit*1.5 $base-unit*1.5;

				@include media-breakpoint-up(lg) {
					padding: 0 $base-unit*2 $base-unit*2.5;
				}
			}
		}

		&.card-medium {
			.card-img-top {
				padding-top: 66.456%;
				border-radius: 0 $base-unit*1.5 0 0;
			}

			.card-body {
				padding: $base-unit*1.5 $base-unit*1.5 $base-unit;

				@include media-breakpoint-up(lg) {
					padding: $base-unit*1.5 $base-unit*2 $base-unit;
				}

				.card-text { margin-bottom: 0; }
			}

			.badge-position-middle {
				margin-top: -$base-unit*2.125;

				@include media-breakpoint-up(lg) {
					margin-top: -$base-unit*2.25;
				}
			}

			.card-footer {
				text-align: right;
				padding: 0 $base-unit*1.5 $base-unit*1.5;

				@include media-breakpoint-up(lg) {
					padding: 0 $base-unit*2 $base-unit*2;
				}
			}
		}

		//Articles
		&.card-small {
			padding: $base-unit*1.5 $base-unit*1.5 0;
			@include radius($base-unit*1.5);

			@include media-breakpoint-up(lg) {
				padding: $base-unit*2 $base-unit*2 0;
			}

			.card-img-top {
				padding-top: 66.666%;

				@include radius($base-unit*.75);
			}

			.card-body {
				padding: $base-unit 0 $base-unit/2;

				@include media-breakpoint-up(lg) {
					padding: $base-unit 0 $base-unit;
				}
			}

			.badge {
				&.badge-position-top {
					margin-top: -$base-unit*2.25;
					left: 50%;
					transform: translateX(-50%);

					@include media-breakpoint-up(lg) {
						margin-top: -$base-unit*2.75;
					}
				}
			}

			&.hover {
				.card-title {
					-webkit-line-clamp: 2;
					max-height: $h6-line-height*2;
				}

				.card-text {
					-webkit-line-clamp: 3;
					max-height: $body-md-line-height*3;
				}

				.card-title,
				.card-text {
					overflow: hidden;
					position: relative;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					text-overflow: -o-ellipsis-lastline;
					text-overflow: ellipsis;
				}
			}

			.card-footer {
				text-align: right;
				padding: 0 0 $base-unit*1.5;

				@include media-breakpoint-up(lg) {
					padding: 0 0 $base-unit*2;
				}
			}
		}

		&:not(.hover) {
			.card-body {
				padding-bottom: $base-unit*2;
			}
		}
	}
}
