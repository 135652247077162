/*************************/
/* STAR RATING Component */
/*************************/

// definitions
$starSize: 24px; // dimension of one star

@mixin stars {
	display: inline-block;
	height: $starSize;
	background-color: transparent;
	background-position: 0 0;
	background-repeat: repeat;
}

// rating
.rating {
	width: 100%;

	.stars {
		@include stars;
		width: $starSize*5;
		position: relative;
		margin: 0 auto;

		&:before {
			@include stars;
			content: "";
			width: 0%;
			position: absolute;
			top: 0;
			left: 0;
		}

		&.stars-orange {
			background-image: url(../../i/v2/components/star-muted.svg);

			&:before {
				background-image: url(../../i/v2/components/star.svg);
			}
		}


		// ratings ---> 5 stars is 50, 4.5 stars is 45 etc.
		&.value50:before { width: 100%; }
		&.value45:before { width: 90%; }
		&.value40:before { width: 80%; }
		&.value35:before { width: 70%; }
		&.value30:before { width: 60%; }
		&.value25:before { width: 50%; }
		&.value20:before { width: 40%; }
		&.value15:before { width: 30%; }
		&.value10:before { width: 20%; }
		&.value05:before { width: 10%; }
		&.value00:before { width: 0%; }
	}

	&:not(.only-stars) {
		text-align: center;
	}

	&.footer-right {
		text-align: left;
		display: inline-block;
	}

	&.footer-bottom:not(.only-stars) {
		.stars {
			display: block;
		}
	}

	.rating-header {
		margin-bottom: $base-unit/2;
	}

	.text-footer {
		margin-top: $base-unit;

		a {
			text-decoration: dotted;
			text-decoration-line: underline;
			text-decoration-thickness: 1px;
		}
	}

	@include media-breakpoint-up(lg) {
		&.footer-right {
			.text-footer {
				margin-top: 0;
				margin-left: $base-unit;
				display: inline;
				position: relative;
				top: -5px;
			}
		}
	}

	// itemprop data
	[itemprop="ratingValue"] {
		display: none;
	}
}

// trustpilot-widget
.trustpilot-widget {
	&--md {
		iframe {
			width: 240px;
			height: 120px;
		}
	}

	&--sm {
		iframe {
			width: 100%;
			height: 60px;

			@include media-breakpoint-up(lg) {
				width: 420px;
			}
		}
	}

	&--two-lines {
		iframe {
			width: 130px;
			height: 90px;
		}
	}
}

