.list {
	font-size: $base-unit*.75;
	line-height: $base-unit*1.125;
	padding: 0;
	margin: $base-unit*.75 0;

	@include media-breakpoint-up(lg) {
		font-size: $base-unit;
		line-height: $base-unit*1.5;
	}

	li {
		margin: $base-unit/2 0 0 $base-unit*1.5;
	}

	&.list-ordered {
		margin-left: 0;
		list-style-type: none;
		position: relative;

		counter-reset: number;

		> li {
			counter-increment: number;

			&:before {
				content: counter(number) ".";
				font-weight: 700;
				position: absolute;
				left: 0;
			}
		}
	}
}

.list-withIcons {
	padding-left: 0;
	list-style-type: none;

	li {
		display: flex;
		align-items: center;

		&:before {
			display: none;
		}

		.icon {
			margin-right: $base-unit;
		}
	}
}

.list-locations {
	list-style-type: none;
	padding: 0;
	margin: 0;

	li {
		margin: 0 0 $base-unit*1.75 0;
		padding-left: 24px;
		background: url('../../i/v2/components/pointer.svg') left top 3px no-repeat;

		span {
			font-size: $base-unit*1.125;
			font-weight: 700;
			line-height: 1.33;
			margin-bottom: 4px;
			color: $white;
			display: block;
		}

		em {
			font-size: $base-unit * .6875;
			font-weight: 700;
			line-height: 1.45;
			letter-spacing: 1.5px;
			color: $blue-dark;
			display: block;
			font-style: normal;
			text-transform: uppercase;
		}
	}
}
