// Sticker Component

.sticker-holder {
	width: $base-unit*6;
	height: $base-unit*6;
	padding-top: $base-unit*2.25;

	border-radius: $base-unit*3;
	box-shadow: 0 20px 40px -20px $purple;

	font-size: $base-unit * 1.125;
	line-height: $base-unit * 1.5;
	font-weight: 600;
	color: $white;
	text-align: center;
	text-transform: uppercase;
}
