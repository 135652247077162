/*

	@todo:


		🐞 BUG
		------
		Navigation is cropped from below)
		Happens when height of the mobile Navigation
		is larger than available height of the viewport


		👨‍🦯 ACCESSIBILITY
		----------------

		Define :focus styles on interactive elements (buttons, links)


*/

$ds-nav__mobile--height: $unit * 4.5;


.ds-nav {
	//...

	//	@mobile only
	@include media-breakpoint-down(md) {
		position: absolute;
		top: 100%;
		right: $unit / 2;
		z-index: map-get($z-index, ds-nav);
		min-width: $unit * 15;
		margin-top: -( $unit * .625);	// adjust position

		background: $white;
		border: $onePixel solid $gray08;
		border-radius: $unit 0 $unit $unit;

		transform: scale(0); // hide navigation
		transform-origin: top right;
		transition: $ds-nav__transition--mobile;

		// show navigation
		&.is--open {
			transform: scale(1);
		}
	}

	//	@desktop only
	@include media-breakpoint-up(lg) {
		display: flex;
	}


	ul {
		margin: 0;
		padding: 0;

		//	@desktop only
		@include media-breakpoint-up(lg) {
			display: flex;
			align-items: center;
		}
	}

	li {
		list-style-type: none;
		position: relative;

		//	@desktop only
		@include media-breakpoint-up(lg) {
			&:not(:last-child) {
				margin-right: $unit * 2.5;
			}
		}

		//	@mobile only
		@include media-breakpoint-down(md) {
			position: relative;
			width: 100%;

			// bottom horizontal separator
			&:not(:last-child)::after {
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: $unit * 1.5;
				right: $unit * 1.5;
				height: $onePixel;
				background: $gray08;
			}
		}

		/*

			TODO
			----
			- replace <#snippet.ds-icon({ "name": "next--16", "class": "d-lg-none" })#> defined in ds-nav.html
			by following CSS:

				&::after {
					content: "";
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);

					width: $unit;
					height: $unit;
		background: RED;
					//background-image: url(https://static3.avast.com/web/i/v2/components/icons/sprites/icons-16.svg?v3);
					//background-position: -($unit*10) 0;
				}

		*/
	}

	a {
		display: flex;
		text-decoration: none;
		transition: $transition--color;

		//
		//	UX & Accessibility
		//
		//&:focus,
		&:hover {
			color: $blue-bright-hover;

			.ds-icon {
				filter: $filter-blue-bright;
			}
		}
		&:focus {
			//box-shadow: $box-shadow--focus;
		}


		//	@mobile only
		@include media-breakpoint-down(md) {
			padding: $unit * 1.5;
			@include heading__h7--mobile;

			.ds-icon.next--16 {
				margin-left: auto;	// align icon to the right
			}
		}

		//	@desktop only
		@include media-breakpoint-up(lg) {
			//...
		}
	}

	// Trigger button - open / close navigation (@mobile only)
	&__toggle {
		position: absolute;
		top: $unit / 2;
		right: $unit / 2;
		z-index: map-get($z-index, ds-nav__toggle);

		width: $unit * 3.5;
		height: $unit * 3.5;
		line-height: 0;	// fix: button's height

		background: $white;
		border: $onePixel solid transparent;
		border-radius: $unit $unit 0 0;

		transition: $transition--transform;

		//
		//	UX & Accessibility
		//
		&:active {
			transform: scale(.8);
		}
		&:focus {
			//box-shadow: $box-shadow--focus;
		}
		&:hover {
			.ds-icon {
				filter: $filter-blue-bright;
			}
		}

		.ds-icon {
			position: absolute;
			top: $unit * .625;
			left: $unit * .625;
			transition: $transition--opacity;
		}

		.ds-icon.cross--32 {
			opacity: 0;
		}


		//
		//	open navigation
		//
		&.is--open {
			border-color: $gray08;
			border-bottom-color: $white;

			.ds-icon.burger--32 { opacity: 0; }
			.ds-icon.cross--32 { opacity: 1; }
		}
	}
}




//
//	nested components - local overrides
//
.ds-backdrop {
	&.is--open.opened-by--ds-nav {
		//	@desktop only
		@include media-breakpoint-up(lg) {
			display: none;	// hide Backdrop when resized to @desktop
		}
	}
}

body.ds-nav--is-open {
	@include media-breakpoint-down(md) {
		overflow: hidden;	// disable on page scroll when Navigation is open
		height: 100vh;	// fix: Safari scrolls the whole page with mobile Navigation
	}
}
