@import "./components/grid";
@import "./components/type";
@import "./components/elevation";
@import "./components/badge";
@import "./components/button";
@import "./components/button-circle";
@import "./components/card";
@import "./components/notice";
@import "./components/award";
@import "./components/metrics";
@import "./components/articles";
@import "./components/illustration";
@import "./components/carousel-slider";
@import "./components/accordion";
@import "./components/combined-actionbox";
@import "./components/sys-req-card";
@import "./components/system-req";
@import "./components/store-badge";
@import "./components/nav";
@import "./components/try-box";
@import "./components/breadcrumb";
@import "./components/list";
@import "./components/tooltip";
@import "./components/popover";
@import "./components/media";
@import "./components/hero";
@import "./components/hero-left-image";
@import "./components/hero-left-universal";
@import "./components/hero-left-amoeba";
@import "./components/hero-centered";
@import "./components/sticker";
@import "./components/cookie-bar";
@import "./components/unsupported-browser-message";
@import "./components/bft";
@import "./components/feature-item";
@import "./components/colors";
@import "./components/platform-switcher";
@import "./components/sticky-bar";
@import "./components/forms";
@import "./components/form-select";
@import "./components/custom-forms";
@import "./components/input-group";
@import "./components/dropdown";
@import "./components/rating";
@import "./components/list-icons";
@import "./components/product-box";
@import "./components/product-icon";
@import "./components/money-back";
@import "./components/testimonial";
@import "./components/tables-common";
@import "./components/tables";
@import "./components/notification-wrong-download";// delete once OOPS are properly implemented everywhere
@import "./components/modal";
@import "./components/collapse";
@import "./components/social-icons";
@import "./components/chrome-banner";
@import "./components/tech-corner";
@import "./components/message-bar";
@import "./components/dtyp";
@import "./components/action-box";
@import "./components/abox";
@import "./components/abox-smb";
@import "./components/award-badges";
@import "./components/backgrounds";
@import "./components/icon";
@import "./components/product-tile";
@import "./components/timeline";
@import "./components/email-capture";
