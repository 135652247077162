.ds-backdrop {
	display: none;
	position: fixed;
	z-index: map-get($z-index, ds-backdrop);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;


	//
	//	fallback - semi transparency
	//
	background-color: $blue-dark;
	opacity: .8;


	//
	// 	blurred backdrop - apply it if browser supports it
	//
	@supports( backdrop-filter: blur(#{$ds-backdrop__blur}) ) {
		background-color: rgba($blue-dark, .8);
		opacity: 1;
		backdrop-filter: blur(#{$ds-backdrop__blur});
	}



	//
	//	show Backdrop
	//
	&.is--open {
		display: block;
	}
}
