// custom breakpoints
.container {
	@media (min-width: 1280px) {
		max-width: 1200px;
	}

	@media (min-width: 1366px) {
		max-width: 1286px;
	}

	@media (min-width: 1600px) {
		max-width: 1360px;
	}
}

/* Testing class for highlighting components on page.
To see what element on page is a component, add to the console this script:

document.querySelectorAll('[data-cmp-name]').forEach(function(el){
	el.classList.add('hi-lite-cmp');
});

*/
.hi-lite-cmp {
	position: relative;

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-image: repeating-linear-gradient(45deg, rgba(74, 122, 255, 0.4) 0, rgba(74, 122, 255, 0.4) 6px, rgba(255, 255, 255, 0.4) 5px, rgba(255, 255, 255, 0.4) 11px);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 3;
		pointer-events: none;
		box-shadow: inset 0 0 0 1px #38ff00, inset 0 0 5px 5px #123456;
	}
}