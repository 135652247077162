@import "_abox-smb-grid.scss";
@import "../smb/components/_smb-toggle.scss";

.abox-wrap.abox-smb {
	flex-direction: column;
	align-items: center;

	.abox-container {
		// SLOTS STYLES:
		.abox-smb-subtitle {
			padding-top: 4px;
		}

		.abox-smb-bundle-icons {
			padding-bottom: $base-unit;

			@include media-breakpoint-up(lg) {
				padding-bottom: $base-unit*1.5;
			}

			.product-icons {
				margin: 0 4px;
			}
		}

		.abox-smb-pickers {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-bottom:  $base-unit/8;

			.abox-smb-input-wrap {
				padding: 0 $base-unit*2.5;
				width: 100%;

				label {
					font-size: 12px;
					line-height: 16px;
				}
			}

			// Product toggle is in separate css - _smb-toggle.scss
			.toggle {
				margin: $base-unit*.75 auto $base-unit*1.25;
			}
		}

		.abox-smb-price-device-year {
			margin-bottom: 0;
		}

		.abox-smb-error {
			font-size: $base-unit*.75;
			line-height: 46px; //same height as price
			color: $status-critical;
			text-decoration: underline;
		}

		// Any custom HTML slot (e.g. SECONDARY CTA 2)
		.abox-smb-slot-bottom {
			padding-top: $base-unit;

			@include media-breakpoint-up(lg) {
				padding-top: $base-unit*1.25;
			}
		}
	}

	.abox-smb-under-box {
		&>* {
			margin: $base-unit*.5 0 0;
			
			@include media-breakpoint-up(lg) {
				margin: $base-unit*1.5 0 0;
			}
		}
	}
}

// Change of layout in the mobile table
.tables-common {
	.abox-wrap .abox-container {
		// Reordered structure style
		@include media-breakpoint-down(sm) {
			.abox-smb-pickers {
				align-items: start;

				.abox-smb-input-wrap {
					padding: 0;
					max-width: $base-unit*8.25;
				}

				.form-control {
					margin-bottom: $base-unit/2;
				}

				.toggle {
					display: none;
				}
			}

			.abox-smb-error {
				line-height: $base-unit*1.6875;
			}
		}
	}
}