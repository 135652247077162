/* Hero Left Amoeba */

// left text
// right absolutely positioned amoeba

// normal variant only

$amoeba-size-md: 480px;
$amoeba-size-lg: 700px;
$amoeba-size-xl: 800px;

.hero-left-amoeba {
	position: relative; // needed for absolute positioning of the amoeba

	.container {
		@include media-breakpoint-up(lg) {
			min-height: 513px;
		}

		& > .row {
			align-items: center;
		}
	}

	// button group
	.hero-btn-group {
		@include media-breakpoint-up(lg) {

			// has right margin to separate multiple buttons
			.btn-wrapper {
				margin-right: $base-unit * 1.5;
			}
		}
	}

	// amoeba
	.hero-amoeba {
		overflow-x: hidden;
		bottom: 0;

		// global for desktop
		@include media-breakpoint-up(lg) {
			position: absolute;
			z-index: 0; // not to cover text in following element
			right: 0;
			padding-bottom: 0;
			width: 42.6%; // container takes 5/12 of the page (section) width
		}

		//amoeba sizes for desktop
		@include media-breakpoint-up(lg) {
			top: 0; // amoeba is from top to bottom
			height: 100%;
		}

		// here are mobile resolutions / on 480px image should overflow the container
		@include media-breakpoint-down(md) {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			overflow-x: hidden; // because image will overflow
			max-width: $amoeba-size-md;
			width: 100%;
			margin: 0 auto;
		}


		// behavior of image inside container
		img {
			position: relative;

			@include media-breakpoint-up(lg) {
				width: auto;
				height: 100%;
			}

			// for mobile it should be 480px, on width < 480px it should overflow the container
			@include media-breakpoint-down(md) {
				width: $amoeba-size-md;
				min-width: $amoeba-size-md; //IE11 fix
			}
		}
	}
}
