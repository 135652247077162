.bft {
	padding: $base-unit*6 0;
	background: url("../../i/v2/components/bg/bg-blade-dark.svg") center center no-repeat;
	background-size: cover;
	text-align: center;

	@include media-breakpoint-up(lg) {
		padding: $base-unit*8 0;
	}

	// content section

	// adjusting elements inside content
	h2 {
		margin: 0 0 $base-unit 0;
		word-break: break-word;
	}

	:not(.action-box-wrap),
	:not(.abox-wrap),
	:not(.smb-abox-wrapper) {
		.tagline-large {
			text-transform: uppercase;
			margin: 0 0 $base-unit*1.5 0;
			color: $orange-bright;

			@include media-breakpoint-down(sm) {
				margin: 0 0 $base-unit 0;
			}
		}
	}

	// buttons section
	&__buttons {
		display: flex;
		align-items: baseline;
		justify-content: center;
		margin: ($base-unit * 2.75) 0 0 0;

		@include media-breakpoint-down(md) {
			display: inline-flex;
		}

		+ .money-back {
			margin-top: ($base-unit * 2.75);

			@include media-breakpoint-down(sm) {
				margin-top: ($base-unit * 2);
			}
		}

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: center;
			margin: ($base-unit * 2) 0 0 0;
		}

		.btn-wrapper {
			margin: 0 ($base-unit * 1.25);

			@include media-breakpoint-down(sm) {
				width: 100%;
				margin: 0 0 ($base-unit * 2) 0;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.btn {
			@include media-breakpoint-down(sm) {
				width: 100%;
				justify-content: center;
			}
		}
	}
}
