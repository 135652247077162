.nav-container {
	max-width: 1360px;
	margin-left: auto;
	margin-right: auto;
	//text-align: center;
	padding-top: 12px; //badges overflow
	margin-top: -12px; //badges overflow
	overflow: auto;

	&.elevated {
		padding-bottom: 40px;//shadow
		margin-bottom: -40px;//shadow
	}

	.nav-container-center {
		text-align: center;
		//overflow-x: auto;
		//overflow: visible;

		//Badges expand - elevated variant
		&.container-expanded {
			//padding-top: 12px;
			//margin-top: -12px;
			//overflow: visible;
		}

		//Badges expand - flat variant
		.container-expanded {
			padding-top: 12px;
			margin-top: -12px;
			//overflow: visible;

			.nav-link {
				position: relative;
			}
		}

		//elevated variant needs expand to sides too for shadows
		&.container-expanded-sides {
			@include media-breakpoint-down(md) {
				padding-left: $base-unit;
				padding-right: $base-unit;
			}
		}

		.nav-container-block {
			display: inline-block;

			//proportional variant
			&.container-full-width {
				width: 100%;
			}

			&.container-shadow {
				position: relative;
				z-index: 25;
				border-radius: $base-unit/2;

				@include media-breakpoint-up(lg) { border-radius: $base-unit; }
			}
		}
	}
}


.nav {
	display: flex;
	flex-wrap: nowrap;
	overflow-y: hidden; //hidden bottom borders on tabs
	justify-content: flex-start;
	width: 100%;

	@include media-breakpoint-up(lg) {
		display: flex;
		width: auto;
		text-align: initial;

		.nav-link {
			flex-basis: auto;
			flex-grow: 0;

			&:not(.text-with-icon) {
				text-align: center;
			}
		}
	}
}


.nav-tabs {
	border: none;

	//tab item
	.nav-link {
		display: flex;
		flex-grow: 1;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		max-width: 100%;
		min-height: $base-unit*5.25;
		padding: $nav-link-padding-y-sm $nav-link-padding-x-sm;
		font-size: 11px;
		line-height: 18px;
		letter-spacing: 1.5px;
		font-weight: 700;
		color: $gray03;
		text-transform: uppercase;
		border: none;
		border-bottom: 1px solid $gray08;
		background-color: $white;

		.inverse & {
			background-color: $blue-deep;
		}

		.nav-link-icon {
			height: $base-unit*1.5;
			width: $base-unit*1.5;
		}

		.nav-link-icon,
		.icon {
			filter: $filter-gray03;
			flex-shrink: 0;

			+.nav-link-text {
				margin-top: $base-unit/2;
			}
		}

		// Badges
		.badge {
			position: absolute;
			z-index: 10;
			top: -12px;
		}

		.is-sticky & {
			.badge,
			.nav-link-icon,
			.icon {
				display: none;
			}
		}

		&.active,
		&:hover {
			border-bottom: 3px solid $blue-bright;
			padding-bottom: calc(#{$nav-link-padding-y-sm} - 2px);
			
			.nav-link-icon,
			.icon {
				filter: $filter-orange-bright;
			}

			.nav-link-text {
				font-weight: 700;
				color: $blue-dark;
			}

			.inverse & {
				background-color: $blue-deep;
			}
		}

		@include media-breakpoint-up(md) {
			max-width: $nav-link-max-width-md;
			padding: $nav-link-padding-y-sm $nav-link-padding-x-md;
		}
		@include media-breakpoint-up(lg) {
			display: inline-flex;
			flex-flow: row;
			padding: $nav-link-padding-y-sm $nav-link-padding-x-lg;
			max-width: $nav-link-max-width-lg;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 2.22px;

			.nav-link-icon {
				height: $base-unit*2;
				width: $base-unit*2;
			}

			.nav-link-icon,
			.icon {
				&+.nav-link-text {
					margin-top: 0;
					margin-left: $base-unit;
				}
			}
		}
		@include media-breakpoint-up(xl) {
			padding: $nav-link-padding-y $nav-link-padding-x-xl;
			font-size: 16px;
			line-height: 24px;
			max-width: $nav-link-max-width-xl;
			min-height: $base-unit*6;
			
			&.active,
			&:hover { padding-bottom: calc(#{$nav-link-padding-y} - 2px); }
		}

		// FOCUS - default styles
		&:focus {
			outline: none;
			box-shadow: inset 0 0 0 1px $blue-bright;
		}
		// Undo all the above focus styles if the browser support :focus-visible
		&:focus:not(:focus-visible) { box-shadow: none; }
		// Focus only for keyboard
		&:focus-visible { box-shadow: inset 0 0 0 1px $blue-bright; }
	}


	//different flow for vertical version
	&.tabs-icon-top {
		.nav-link {
			flex-flow: column;
			text-align: center;

			.nav-link-icon,
			.icon {
				&+.nav-link-text {
					margin-left: 0;
					margin-right: 0;
					margin-top: $base-unit/2;
				}
			}

			@include media-breakpoint-up(lg) {
				font-size: 14px;
				line-height: 24px;
				letter-spacing: 1.5px;

				.nav-link-icon {
					height: $base-unit*1.5;
					width: $base-unit*1.5;
				}
			}
		}
	}


	&.tabs-icon-large {
		.nav-link {
			padding: $nav-link-padding-y $base-unit;
			
			&.active,
			&:hover { padding-bottom: calc(#{$nav-link-padding-y} - 2px); }

			@include media-breakpoint-up(lg) {
				padding: $base-unit*2 $base-unit*2;
			
				&.active,
				&:hover { padding-bottom: 30px; }
			}
			@include media-breakpoint-up(xl) {
				padding: $base-unit*2 $base-unit*3;
			}

			.nav-link-icon {
				height: $base-unit*3;
				width: $base-unit*3;

				@include media-breakpoint-up(lg) {
					height: $base-unit*5;
					width: $base-unit*5;
				}
			}
		}
	}


	/* smaller tabs in horizontal variant */
	&.tabs-smaller {
		.nav-link {
			@include media-breakpoint-up(lg) {
				font-size: 14px;
				line-height: 24px;
				padding: $base-unit*1.5;
				min-height: $base-unit*4.5;

				.nav-link-icon {
					height: $base-unit*1.5;
					width: $base-unit*1.5;
				}

				&.active,
				&:hover {
					padding-bottom: calc(#{$base-unit} * 1.5 - 2px);
				}
			}
		}
	}
	
	.inverse & {
		.nav-link {
			border-bottom: 1px solid $blue-mid;

			.nav-link-text { color: $gray06; }

			.nav-link-icon,
			.icon {
				filter: $filter-gray06;
			}

			&.active,
			&:hover {
				border-bottom: 3px solid $orange-bright;

				.nav-link-text { color: $white; }

				.nav-link-icon,
				.icon {
					filter: $filter-orange-bright;
				}
			}
			
			// FOCUS - default styles
			&:focus {
				box-shadow: inset 0 0 0 1px $orange-bright;
			}
			// Undo all the above focus styles if the browser support :focus-visible
			&:focus:not(:focus-visible) {
				box-shadow: none;
			}
			// Focus only for keyboard
			&:focus-visible {
				box-shadow: inset 0 0 0 1px $orange-bright;
			}
		}
	}
}


//tabs have equal width
.nav-justified {
	@include media-breakpoint-up(md) {
		width: 100%;

		.nav-link {
			flex-basis: 0;
			flex-grow: 1;
			max-width: initial;
		}
	}
}

//container has border and shadow
.tabs-elevated {
	display: flex;
	border-radius: $base-unit/2;

	@include media-breakpoint-up(lg) {
		display: inline-flex;
		border-radius: $base-unit;
	}

	.nav-link {
		box-shadow: none;
		border-bottom: 1px solid transparent;

		.nav-link-text { color: $gray03; }

		
		// FOCUS - default styles
		&:focus {
			&:first-of-type { border-radius: $base-unit/2 0 0 $base-unit/2; }
			&:last-of-type { border-radius: 0 $base-unit/2 $base-unit/2 0; }
		
			@include media-breakpoint-up(lg) {
				&:first-of-type { border-radius: $base-unit 0 0 $base-unit; }
				&:last-of-type { border-radius: 0 $base-unit $base-unit 0; }
			}
		}
		// Undo all the above focus styles if the browser support :focus-visible
		&:focus:not(:focus-visible) {
			&:first-of-type,
			&:last-of-type { border-radius: 0;}
		}
		// Focus only for keyboard
		&:focus-visible {
			&:first-of-type { border-radius: $base-unit/2 0 0 $base-unit/2; }
			&:last-of-type { border-radius: 0 $base-unit/2 $base-unit/2 0; }
		
			@include media-breakpoint-up(lg) {
				&:first-of-type { border-radius: $base-unit 0 0 $base-unit; }
				&:last-of-type { border-radius: 0 $base-unit $base-unit 0; }
			}
		}
	}

	.inverse & {
		.nav-link {
			border-bottom: 1px solid transparent;
		}
	}
}