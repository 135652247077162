/************************
// ILLUSTRATION COMPONENT
************************/

.illustration {
	flex-flow: row-reverse;
	align-items: center;
	overflow: hidden;

	.card-img-top {
		display: flex;
		align-items: center;
		width: 39%;
		height: 100%;

		img {
			width: 100%;
			height: auto;
			margin: $base-unit/2 auto;
		}
	}

	.card-body {
		padding-right: $base-unit/2;
		margin: 0 0 auto;

		h5 {
			margin-bottom: 0;
		}

		.card-text {
			margin-top: $base-unit/2;
		}
		
		.btn-wrapper {
			margin-top: $base-unit*1.5;
		}
	}
}