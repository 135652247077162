.combined-actionbox {
	display: inline-flex;
	justify-content: center;
	margin: 0 auto;
	background-color: transparent;

	&.list-sm {
		.actionboxes-list {
			width: 316px;

			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
	}

	&.list-md {
		.actionboxes-list {
			width: 380px;
			
			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
	}

	&.list-xl {
		width: 100%;
			
		@include media-breakpoint-down(md) {
			width: 320px;
		}
		
		.actionboxes-list {
			width: 100%;
		}
	}

	@include media-breakpoint-down(md) {
		padding: 0;
		flex-direction: column;
		align-items: center;
		width: 320px;
	}

	/* List aside containers
	==============
	*/
	.actionboxes-list {
		background-color: $gray09;
		text-align: initial;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: $base-unit*2 $base-unit*2 $base-unit*3;
		border-radius: $base-unit $base-unit 0 0;
		border-top: 1px solid $gray08;
		border-right: 1px solid $gray08;
		border-left: 1px solid $gray08;

		@include media-breakpoint-up(lg) {
			padding: $base-unit*2;
			border-radius: $base-unit 0 0 $base-unit;
			border-top: 1px solid $gray08;
			border-right: none;
			border-bottom: 1px solid $gray08;
			border-left: 1px solid $gray08;
		}

		.h7 {
			margin-bottom: $base-unit/2;

			@include media-breakpoint-up(lg) {
				margin-bottom: $base-unit;
			}
		}

		&__item {
			position: relative;
		}

		.btn-wrapper {
			margin-top: 0;

			@include media-breakpoint-up(lg) {
				margin-top: $base-unit*1.5;
			}
		}

		&:not(.no-btn-format) {
			.btn-link-secondary {
				text-transform: none;
				font-weight: $font-weight-normal;
				letter-spacing: normal;
			}
		}
	}

	&.elevation-orange {
		.actionboxes-list {
			background-color: $orange-off-white;
			border-color: $orange-pale;
		}
		
		.actionboxes-wrapper,
		.actionboxes-list + .actionboxes-wrapper {
			border-color: $orange-pale;
		}
	}

	/* Actionboxes
	==============
	*/
	.actionboxes-wrapper {
		display: flex;
		flex-direction: column;
		background-color: $white;
		border-radius: $base-unit;
		border: 1px solid $gray08;

		@include media-breakpoint-down(md) {
			width: 100%;
		}

		.actionboxes-boxes {
			display: flex;
			flex: 1;

			@include media-breakpoint-down(md) {
				flex-direction: column;
			}
		}

		.try-box {
			border: none;

			@include media-breakpoint-down(md) {
				.try-box-container {
					width: 100%;
				}
			}
		}

		.action-box-wrap {
			.action-box-container.boxCount-1 {
				.action-box {
					max-width: 100%;
					border-width: 0 0 0 1px;
					border-color: $gray08;
					border-radius: 0;
					padding-top: 0;
					padding-bottom: 0;
					margin-top: $base-unit * 3.25;
					margin-bottom: $base-unit * 2;

					@include media-breakpoint-down(md) {
						width: 100%;
						border-width: 1px 0 0 0;
						padding: $base-unit * 1.5;
						margin: 0;
					}

					&.promotion {
						border: 1px solid $orange-bright;
						border-radius: 8px;
						padding-top: $base-unit;
						padding-bottom: $base-unit;
						margin-top: calc(2.25rem - 1px);
					}
				}

				&:first-child {
					.action-box {
						border: none;

						&.promotion {
							border: 1px solid $orange-bright;
						}


						@include media-breakpoint-down(md) {
							margin-top: $base-unit;
						}
					}
				}
			}
		}
	}

	// override radius and borders when next to list
	.actionboxes-list + .actionboxes-wrapper {
		border-radius: 0 0 $base-unit $base-unit;
		border-top: none;
		border-right: 1px solid $gray08;
		border-bottom: 1px solid $gray08;
		border-left: 1px solid $gray08;

		@include media-breakpoint-up(lg) {
			border-radius: 0 $base-unit $base-unit 0;
			border-top: 1px solid $gray08;
			border-right: 1px solid $gray08;
			border-bottom: 1px solid $gray08;
			border-left: none;
		}
	}

	.action-box-wrap {
		margin: 0;
		position: relative;
		align-items: flex-start;

		@include media-breakpoint-down(md) {
			align-items: center;
		}

		.action-box-container {
			box-shadow: none !important;

			.action-box-multibox {
				box-shadow: none !important;
				border: none;

				@include media-breakpoint-down(md) {
					padding: $base-unit 0 0 0;
				}

				.action-box {
					@include media-breakpoint-down(md) {
						padding: $base-unit * 1.5;
					}
				}
			}

			@include media-breakpoint-down(md) {
				width: 100%;
				padding: 0 $base-unit * 2;
			}
		}

		.action-box {
			@include media-breakpoint-down(md) {
				padding: $base-unit * 1.5;
			}

			.badge {
				+ div .badge-placeholder {
					display: none;
				}
			}
		}
	}

	/* try-box horizontal
	==============
	*/
	.actionboxes-footer {
		position: relative;
		padding: 0 $base-unit;
		background-color: transparent;
		width: 100%;
		flex: 1; // hotfix of the hotfix for older Safari

		@include media-breakpoint-down(md) {
			padding: 0 $base-unit * 2;
		}

		&.horizontal {
			.box {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: $base-unit 0;
				margin: 0;
				border-top: 1px solid $gray07;
				height: 100%;

				@include media-breakpoint-down(md) {
					flex-direction: column;
				}

				.money-back__item {
					padding: 0;
					margin: 0;

					span { color: $gray03; }

					.icon { filter: $filter-gray03; }
				}
			}

			.btn-wrapper {
				margin: 0 $base-unit;

				@include media-breakpoint-down(md) {
					margin: 0 0 $base-unit * .5 0;
				}
			}
		}

		.text-medium {
			margin: 0;
		}
	}

	// REVERSED BLOCKS ORDER ON MOBILE
	&.reversed-mobile {
		@include media-breakpoint-down(md) {
			flex-direction: column-reverse;

			.actionboxes-list {
				padding: $base-unit*2 $base-unit*2 $base-unit*3;
				border-radius: 0 0 $base-unit $base-unit;
				border-top: none;
				border-bottom: 1px solid $gray08;

				&+.actionboxes-wrapper {
					border-radius: $base-unit $base-unit 0 0;
					border-bottom: none;
					border-top: 1px solid $gray08;
				}
			}

			&.elevation-orange {
				.actionboxes-list {
					border-color: $orange-pale;
					
					&+.actionboxes-wrapper {
						border-color: $orange-pale;
					}
				}
			}
		}
	}
}

.inverse {
	.combined-actionbox.dark {
		@include media-breakpoint-down(md) {
			border-color: $blue-mid;
		}

		.actionboxes-list {
			background-color: $blue-mid;
			border-color: $blue-mid;
		}

		.actionboxes-wrapper {
			background-color: $blue-deep;
			border-color: $blue-mid;

			.action-box-wrap {
				.action-box-container.boxCount-1 {
					.action-box {
						border-color: $blue-mid;
					}
				}
			}
		}

		.actionboxes-footer {
			color: $gray06;

			&.horizontal {
				.box {
					border-color: $blue-mid;
					color: $white;
				}
			}
		}
	}
}
