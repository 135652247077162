.storebadge-hint {
	display: block;
	margin-top: $base-unit;
	
	a {
		font-weight: 600;
		border-bottom: 1px solid;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}

		.inverse & {
			border-bottom-color: $white;
		}

		&,
		.light & {
			border-bottom-color: $blue-dark;
		}
	}
}