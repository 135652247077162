// region selector

// mixin for icon
@mixin region-selector-flag {
	background-color: transparent;
	background-image: $region-selector-flags;
	background-repeat: no-repeat;
	content: "";
	display: inline-block;
	vertical-align: middle;
	height: 24px;
	width: 24px;
}

// icon
.region-selector {
	li {
		list-style-type: none;

		a:before {
			margin-right: 10px;

			@include region-selector-flag;
		}
	}

	.region {
		font-family: Helvetica, Arial, sans-serif;
	}
}

// displayed region in header and footer
.region {
	.category:before {
		position: relative;
		top: -3px;
		margin-right: 10px;

		@include region-selector-flag;
	}
}

// when region selector is opened
.navigation-for-desktop.js-language-selector-open {
	overflow: hidden;

	.region-selector {
		display: flex;
	}
}

.navigation-for-mobile.js-language-selector-open {
	.region-selector {
		display: block;
	}
}

// common
.region-selector {
	display: none;
	position: fixed;
	//z-index: 1001;	// fix: <ds-message-bar> overlaping <dc-region-selector>
	z-index: map-get($z-index, cmp-region-selector);
	left: 0;
	top: 0;
	width: 100%;
	padding-top: $base-unit*5;
	padding-bottom: $base-unit*5;
	background-color: $white;

	.inverse & {
		background-color: $blue-dark;
	}

	ul,
	li {
		margin: 0;
		padding: 0;
	}

	a {
		color: $blue-deep;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	// close
	.region-selector-close {
		position: absolute;
		right: $base-unit*2;
		top: $base-unit*2;
		width: $base-unit*3;
		height: $base-unit*3;
		background: transparent url(../../i/v2/components/cross-l-plum.svg) center center no-repeat;
		cursor: pointer;
		border: none; // because it is button

		.inverse & {
			filter: $filter-white;
		}
	}
}

// desktop
.navigation-for-desktop .region-selector {
	right: 0;
	bottom: 0;
	min-height: 100vh;
	overflow: auto;

	align-items: center;
	justify-content: center;

	@media screen and (max-height: 800px) {
		align-items: flex-start;
	}

	& > li {
		width: 100%;
	}

	// main regions - used only on mobile
	.main-regions {
		display: none;
	}

	// custom regions
	.custom-regions {
		position: relative;
		display: flex;
		justify-content: space-around;

		// turn on scrolling below 700px height
		@media screen and (max-height: 700px) {
			position: absolute;
			// padding-top: $base-unit*3;
			overflow-y: auto;
		}

		&:after {
			display: block;
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 65px;
			border-top: 1px solid $header-box-shadow-color;
		}

		// flag before menu item
		li {
			font-size: 14px;
			white-space: nowrap;
			height: $body-md-line-height; // to overcome some weird bottom space that does not exist in Safari but exists everywhere else
			margin-bottom: $base-unit*.4;
		}

		.area {
			position: relative;
			padding-bottom: 90px;

			&:not(:last-child) {
				padding-right: 50px;
			}

			.subcategory {
				padding-right: $base-unit/2;
				padding-bottom: $base-unit*2;
				height: auto;
				color: $gray03;
			}

			ul {
				float: left;
				padding: 0 50px 0 0;

				@media (max-width: 1200px) {
					padding-right: 10px;
				}

				// main region at the bottom
				&.other {
					float: none;
					position: absolute;
					left: 0;
					bottom: 7px;
					z-index: 2000;
				}

				a {
					display: inline-block;
					overflow: hidden;

					// to fit links into small viewport
					@media (max-width: 1100px) {
						// max-width: 140px;
						text-overflow: ellipsis;
					}
				}
			}
		}


		& > div:after {
			display: block;
			content: "";
			clear: both;
		}
	}
}


// mobile
@mixin mobile-item {
	padding-bottom: $base-unit;
}

.navigation-for-mobile .region-selector {
	position: fixed;
	padding-left: $base-unit*1.5;
	padding-right: $base-unit*1.5;
	bottom: 0;
	right: 0;
	overflow: auto;
	min-height: 100vh;

	.custom-regions {
		display: flex;
		flex-direction: column;
	}

	// subcategory
	.subcategory {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: $base-unit*1.5 0;
		cursor: pointer;
		color: $gray03;

		// this means that it can be toggled
		&.arrow:after {
			content: '';
			display: inline-block;
			cursor: pointer;
			@include sprite-image('icons', 'down', 24);
			transition: transform 250ms ease-in;
			filter: $filter-blue-dark;
		}
	}

	// link styles
	.main-regions,
	.area {
		a {
			display: inline-block;
			padding-top: $base-unit;
			padding-bottom: $base-unit;
		}

	}

	// main regions on the top
	.main-regions {
		padding-bottom: $base-unit;
		order: 1;
		margin-top: $base-unit*3;
	}

	// area
	.area {
		border-bottom: 1px solid $gray06;

		ul {
			padding: 0;
			display: none;

			&:last-child {
				padding-bottom: 20px;
			}
		}

		&.isOpen {
			.subcategory:after {
				transform: rotate(180deg);
			}

			ul {
				display: block;

				// make two rows on bigger mobiles
				@include media-breakpoint-between(sm, md) {
					display: flex;
					flex-wrap: wrap;

					li {
						flex: 1 0 50%;
					}
				}
			}
		}
	}

	// dropdown on mobile
	.hide-regions {
		display: none;
	}
}

.navigation-for-mobile.inverse .region-selector {
	.subcategory {
		color: $white;

		&.arrow:after {
			filter: $filter-white;
		}
	}
}

.navigation-for-desktop.inverse .region-selector {
	.subcategory {
		color: $white;
	}
}