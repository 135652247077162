/* ❗ If you do some changes in this file, please inform @Martin Prokop about it. ❗ */

/* mixins */
// desktop - third menu style

@mixin third-menu-style {
	background-color: $white;
	box-shadow: $box-shadow;
	border: 1px solid $cmp-header__border-color;
}
@mixin third-menu-style-inverse {
	background-color: $blue-deep;
	box-shadow: $box-shadow;
	border: 1px solid $blue-mid;
}
// desktop - basics for navigation icon pseudo element
@mixin navigation-icon {
	display: inline-block;
	width: 16px;
	height: 16px;
}

// variables
$desktop-near-breakpoint: 1180px;
$hover-delay: 350ms;
$hover-animation: ease-in;

// mobile overlay
.navigation-mobile-overlay {
	display: none;
}

/* Logic */
.navigation-for-desktop {
	// first level active state
	&.first-menu-for-home a.for-home,
	&.first-menu-for-business a.for-business,
	&.first-menu-for-partners a.for-partners,
	&.first-menu-about-us a.about-us,
	&.first-menu-regions .item.region {
		color: $blue-bright !important; // after the test will add .navigation to the path
		text-decoration: underline;
	}

	// hover and active state over blog and regions - inverse
	&.blogs-special-menu .item.blogs,
	&.first-menu-regions .item.region {
		.category {
			color: $blue-bright; // after the test will add .navigation to the path
		}
	}

	&.blogs-special-menu {
		.header {
			.first-menu ul li.blogs {
				color: $blue-bright; // after the test will add .navigation to the path
				border: 1px solid $cmp-header__border-color;
				border-bottom: none;
				z-index: 21;
				background: $white;
				border-radius: $base-unit $base-unit 0 0;

				&:after {
					filter: $filter-blue-bright;
					transform: rotate(180deg);
				}
			}

			.second-menu.blogs {
				visibility: visible; // Used for readability by screen readers
				opacity: 1; // Used for the fade out animation effect
				border-radius: $base-unit;

				.txt-blogs-description {
					color: $blue-dark;
				}
			}
		}

		&.blogs-special-menu-isOpen {
			.first-menu ul li.blogs,
			.second-menu.blogs {
				transition: none;
			}
		}
	}

	/* Navigation styles */
	.header {
		padding: 0 40px;

		// types
		&.web,
		&.lp-rs {
			box-shadow: 0 1px 0 0 rgba($blue-dark, .1);
		}

		.header-wrap {
			display: flex;
			justify-content: space-between;
		}

		a {
			text-decoration: none;
			display: inline-block; // needed to properly display focus
			text-align: left;
		}

		// Avast logo
		.avast-logo {
			flex: 0 0 150px;
			padding: 30px 0 0;

			a {
				&:focus {
					outline-offset: $base-unit/4;
				}
			}
		}

		// navigation menus
		nav {
			flex: 0 1 100%;
		}

		.side {
			display: flex;
			padding-bottom: 0;
		}

		.item {
			display: inline-block;
			display: block;
			padding: 10px;
		}

		.first-menu,
		.second-menu {
			display: flex;
			justify-content: space-between;

			// about-us should have all items on the left (the only exception)
			&.about-us {
				justify-content: flex-start;
			}

			// change icon color
			a:hover {
				.subcategory img,
				.subcategory .icon {
					filter: $filter-blue-bright;
				}
			}

			ul {
				li {
					display: inline-block;
					display: block;
					position: relative; // for absolute positioning of third menu
				}
			}
		}

		/* ### first menu */
		// top row of header
		.first-menu {
			ul {
				li {
					padding: 16px 16px 0 24px;
					border: 1px solid transparent;
					position: relative;
					transition: $hover-delay color $hover-animation, $hover-delay background $hover-animation;
					border-radius: 12px 12px 0 0;

					a,
					.category {
						font-size: $base-unit * .875;
						line-height: 24px;

						&:focus {
							outline-offset: $base-unit/4;
						}
					}

					&:hover {
						.category {
							a {
								color: $orange-bright;
							}
						}

					}

					&:not(.region) {
						// decrease T0 padding close to breakpoint - to better fit longer texts
						@media (max-width: $desktop-near-breakpoint) {
							padding-left: 5px;
							padding-right: 5px;
						}
						// first level links are dark grey and orange on hover
						.category {
							&:hover {
								text-decoration: none;
								color: $blue-bright;
							}
						}
					}

					&.blogs {
						.blogs-second-menu & {
							border: 1px solid $cmp-header__border-color;
							border-bottom: none;
							z-index: 21;
							background: $white;

							.category {
								color: $blue-bright; // after the test will add .navigation to the path
							}

							&:after {
								content: url(../../i/v2/components/arrow-m-down-blue-dark.svg) /
								"";
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}

		/* ### second menu */
		// bottom row of header
		// items that opens third level has arrow and active state
		.second-menu {
			&.about-us ul:not(.mobile-links-top) li {
				@media (max-width: 1100px) {
					flex: 1 auto;
				}
			}

			&.about-us ul[role="menu"] li:nth-child(n + 2) .subcategory,
			&.about-us ul[role="menubar"] li .subcategory {
				@media (max-width: 1450px) {
					padding: $base-unit $base-unit * .5;
				}
			}

			// temporary, will be prolly replaced by other element
			.subcategory {
				font-size: $base-unit;
				font-weight: 400;
				border: 1px solid transparent; // to be replaced with color border when hovered
				border-bottom: none;
				padding: $base-unit;
				transition: $hover-delay background $hover-animation;
				position: relative;
				border-radius: 12px 12px 0 0;

				&:after {
					transition: $hover-delay transform $hover-animation;
				}

				&.arrow {
					display: flex;
					align-items: flex-start;
					height: 100%;
				}

				// decrease T1 padding close to breakpoint - to better fit longer texts
				@media (max-width: $desktop-near-breakpoint) {
					padding-left: 5px;
					padding-right: 5px;
				}

				// shrinking navigation items on a smaller viewport
				@media (max-width: $breakpoint-custom-lg2) {
					font-size: $base-unit * .875;
				}

				&.shop {
					padding-left: 24px;
					display: flex;

					&:before {
						content: "";
						display: inline-block;
						width: 1px;
						height: 24px;
						background: $gray08;
						position: absolute;
						top: 14px;
						left: 0;
					}
				}
			}

			.isActive {
				.subcategory {
					color: $blue-bright;
				}
			}

			li[data-second-menu] {
				&:hover,
				&.isOpen {
					text-decoration: none;

					.subcategory {
						position: relative;
						color: $blue-bright; // after the test will add .navigation to the path
						border: 1px solid $cmp-header__border-color;
						border-radius: 12px 12px 0 0;
						border-bottom: none;
						z-index: 21;
						background: $white;
						text-decoration: underline;

						&:after {
							filter: $filter-blue-bright;
							transform: rotate(180deg);
						}
					}

					.third-menu {
						visibility: visible; // Used for readability by screen readers
						opacity: 1; // Used for the fade out animation effect
					}
				}

				&.isOpen {
					.third-menu,
					.subcategory {
						transition: none;
					}
				}
			}

			// special kind of second menu that looks like 3rd menu products
			&.blogs {
				display: flex;
				position: absolute;
				z-index: 20;
				right: 100px;
				top: 43px;
				padding: 34px 0;
				visibility: hidden; // Used for readability by screen readers
				opacity: 0; // Used for the fade out animation effect
				transition: $hover-delay opacity $hover-animation,
				$hover-delay visibility;

				@media (max-width: $desktop-near-breakpoint) {
					width: 500px;
					flex-wrap: wrap;
					padding: 10px 0;
				}

				& > li {
					padding: 0 $base-unit * 2;
					max-width: 260px; // otherwise text makes them too wide
					min-width: 180px;
					text-align: center;

					[lang="uk"] & {
						max-width: 320px;
					}

					@media (max-width: $desktop-near-breakpoint) {
						position: relative;
						flex: 1;
						padding: $base-unit * 2;

						&:not(:last-child):before {
							position: absolute;
							right: -1px;
							top: 34px;
							content: "";
							display: block;
							width: 1px;
							height: calc(100% - #{$cmp-header__height--mobile});
							background-color: $cmp-header__border-color;
						}

						&:nth-child(2) {
							border-right: none;
						}

						&:nth-child(-n+3):after {
							content: "";
							display: block;
							height: 1px;
							width: 100%;
							position: absolute;
							left: 0;
							bottom: 0;
							background-color: $cmp-header__border-color;
						}
					}

					&:not(:last-child) {
						border-right: 1px solid $cmp-header__border-color;
						@media (max-width: $desktop-near-breakpoint) {
							border-right: none;
						}
					}

					a:hover {
						.txt-blogs-title {
							text-decoration: underline;
						}
					}

					.txt-blogs-title {
						display: block;
						font-size: $base-unit * 1.25;
						line-height: $base-unit * 2;
						font-weight: bold;
						scroll-padding-bottom: 20px;
					}

					.txt-blogs-description {
						display: block;
						font-size: $body-sm-font-size;
						padding-top: $base-unit;
					}
				}
			}
		}

		/* ### third menu */
		.third-menu {
			position: absolute;
			left: 0;
			top: 100%;
			z-index: 20;
			margin-top: -1px;
			visibility: hidden; // Used for readability by screen readers
			opacity: 0; // Used for the fade out animation effect
			transition: $hover-delay opacity $hover-animation,
			$hover-delay visibility;
			min-width: calc(100% + 100px);

			@include radius(12px);

			// do not underline links on home products
			&.security,
			&.privacy,
			&.performance,
			&.family,
			&.bundles {
				a:hover {
					text-decoration: none;

					.product-name {
						text-decoration: underline;
					}
				}
			}

			// login must be aligned to right
			&.login {
				left: auto;
				right: 0;
			}

			// third menu - block products
			// used to display consumer and business product boxes
			.block-products {
				display: flex;
				padding: 0;
				margin: 0;
				width: 100%;

				& > li {
					padding: 0 32px;
					font-size: .875rem;

					// list inside the main sections (used on business)
					& > ul {
						// rewriting to flex to let them have lenght based on content (because of arrows at the end
						// arrows has been removed
						display: flex;
						flex-direction: column;
						align-items: stretch; // can ve on he whole lenght because arrows removed

						li {
							text-align: left;
							white-space: nowrap;

							&:not(:last-child) {
								padding-bottom: 20px;
							}

							a {
								display: block;

								&:hover {
									color: $blue-bright;
								}
							}
						}
					}
				}

				// product items
				.product {
					min-width: 282px;
					max-width: 300px;
					text-align: left;
					padding: $base-unit * 2;
					font-size: $body-sm-font-size;
					position: relative;

					&:not(:last-child):before {
						position: absolute;
						right: -1px;
						top: 34px;
						content: "";
						display: block;
						width: 1px;
						height: calc(100% - #{$cmp-header__height--mobile});
						background-color: $cmp-header__border-color;
					}

					.name {
						font-size: $base-unit*1.25;
						line-height: $base-unit*2;
						font-weight: bold;
						height: 62px;
						display: flex;
						align-items: center;
						padding-bottom: $base-unit*1.375;
						white-space: nowrap;

						.product-icon {
							margin-right: $base-unit;
						}
					}

					// Secure Browser PRO, Online Security & Privacy menu item text must wrap
					&.secure-browser-pro,
					&.header-online-privacy {
						.name {
							white-space: normal;
						}
					}

					.os {
						padding-bottom: $base-unit;

						.icon {
							margin: 0 4px;
							width: $base-unit;
						}

					}

					.category {
						margin-bottom: $base-unit;
						font-size: $base-unit*.75;
						line-height: $base-unit;
						color: $gray06;
						display: flex;
						align-items: center;

						&:before {
							content: '';
							margin-right: $base-unit/2;
							filter: $filter-gray06;
							display: inline-block;
							@include sprite-image('icons', 'account', 16);
						}
				}

					.description {
						font-size: $body-sm-font-size;
						color: $blue-dark;

						a {
							text-decoration: underline;
						}
					}
				}
			}

			.hint {
				margin-bottom: 0; // removing paragraph default
				padding: $base-unit*1.5;
				border-top: 1px solid $cmp-header__border-color;
				font-size: $body-sm-font-size;
				cursor: default;
				text-align: center;

				a:not(.btn),
				a:not(.btn):hover {
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}

		// For business modifications
		.for-business {
			.block-products {
				color: $white;

				.divider-horizontal {
					display: block;
				    margin: $base-unit * 2 0;
				    height: 1px;
				    width: 100%;
				    background: $blue-mid;
				}

				.product {
					&:not(:last-child):before,
					&:not(:last-child):after {
						background-color: $blue-mid;
					}
				}
			}

			.hint {
				color: $white;
				background-color: $blue-mid;
				font-size: $base-unit;
				font-weight: 600;
				line-height: $base-unit*1.5;
				border: none;
				border-radius: 0 0 $base-unit $base-unit;
				display: flex;
				justify-content: center;
				align-items: center;

				p {
					margin: 0 $base-unit*.75 0 0;
				}

				.hint-button {
					margin: 0 $base-unit*.75;

					a {
						display: inline-flex;
					}
				}
			}
		}

		.for-business .third-menu.products.solutions .block-products>li {
			&:nth-child(1),
			&:nth-child(2) {
				&:after {
					background-color: $blue-mid;
				}
			}
		}

		// About us modifications
		.about-us .third-menu {
			.block-products {
				& > li {
					padding-top: $base-unit * 1.25;
					padding-bottom: $base-unit * 1.25;

					.name {
						font-size: 1rem;
						font-weight: bold;
						line-height: 28px;
						display: block;
						white-space: nowrap;
						padding-bottom: 1rem;
					}
				}
			}
		}


		/* icons */
		// collapsible item
		.arrow:after {
			content: "";
			transition: $hover-delay transform $hover-animation;
			vertical-align: middle;
			display: inline-block;
			margin-left: 7px;
			margin-top: 5px;
			flex-shrink: 0;

			@include sprite-image("icons", "down-s", 16);
		}

		.bi-nav-menu.arrow:after {
			margin-top: 0;
		}

		// subcategory icons
		.subcategory {
			display: flex;
			align-items: center;

			img,
			.icon {
				position: relative;
				margin-right: 5px;
				vertical-align: middle;
			}
		}

		// region - last item in the top menu
		.region {
			position: relative;
			cursor: pointer;

			&:before {
				display: block;
				content: "";
				position: absolute;
				left: 0;
				width: 1px;
				height: 24px;
				background-color: $gray08;
			}

			a:hover {
				text-decoration: none;
			}

			&:hover {
				a {
					color: $blue-bright;
				}

				&:after {
					filter: $filter-blue-bright;
				}
			}
		}

		// for .lp-rs variant
		&.lp-rs .region {
			top: 20px;

			&:before {
				display: none;
			}
		}

		// variants
		&.default {
			background-color: $white;

			.second-menu.blogs,
			.third-menu {
				@include third-menu-style;
			}

			.second-menu.blogs {
				border-radius: $base-unit;
			}
		}
	}
}

// Inverse version
.navigation-for-desktop.inverse {
	// first level active state - inverse
	&.first-menu-for-home header a.for-home,
	&.first-menu-for-home header a.for-home,
	&.first-menu-for-business header a.for-business,
	&.first-menu-for-partners header a.for-partners,
	&.first-menu-about-us header a.about-us,
	&.first-menu-regions header .item.region {
		color: $orange-bright !important; // after the test will add .navigation to the path
		text-decoration: underline;
	}

	// hover and active state over blog and regions
	&.blogs-special-menu header .item.blogs,
	&.first-menu-regions header .item.region {
		.category {
			color: $orange-bright; // after the test will add .navigation to the path
		}
	}

	.header {
		background-color: $blue-dark;
		border-bottom: 1px solid $blue-mid;

		&.transparent {
			background-color: transparent;
			border-color: transparent;
		}

		.region:before,
		.second-menu .subcategory.shop:before {
			background-color: $gray02;
		}

		a:not(.btn) {
			&:hover {
				color: $orange-bright;
			}
		}

		.arrow:after {
			filter: $filter-white;

			&:hover {
				filter: $filter-orange-bright;
				transform: rotate(180deg);
			}
		}

		.first-menu {
			ul {
				li {
					&:hover {
						.category {
							color: $orange-bright;
						}
					}

					&.region {
						&:hover {
							color: $orange-bright;

							&.arrow:after {
								filter: $filter-orange-bright;
							}
						}
					}
				}
			}
		}

		.second-menu.blogs,
		.third-menu {
			@include third-menu-style-inverse;

			&.login {
				border-radius: 12px 0 12px 12px;
			}
		}

		.second-menu.blogs {
			border-radius: $base-unit;

			li {
				.txt-blogs-description {
					color: $white;
				}

				&:not(:last-child) {
					border-color: $blue-mid;
				}

				&:after,
				&:before {
					background-color: $blue-mid;
				}

				a:hover {
					.txt-blogs-title {
						color: $orange-bright;
						text-decoration: underline;
					}
				}
			}
		}
	}

	&.blogs-special-menu {
		.header {
			.first-menu ul li.blogs {
				color: $orange-bright;
				border: 1px solid $blue-mid;
				border-bottom: none;
				border-radius: 12px 12px 0 0;
				background: $blue-deep;

				&:after {
					filter: $filter-orange-bright;
					transform: rotate(180deg);
				}
			}
		}
	}

	.for-business .third-menu {
		&.products {
			.block-products {
				.product {

					& > a{
						display: block;
					}

					& > a:hover {
						.product-name {
							color: $orange-bright;
							text-decoration: underline;
						}
					}

					.os {
						img {
							filter: $filter-gray06;
						}
					}

					.name {
						color: $white;
						line-height: $base-unit * 1.5;
					}

					.description {
						color: $white;
					}
				}
			}
		}

		&.business-partners,
		&.products {
			.block-products {
				.mobile-link {
					padding: $base-unit/2 0 0 0;
					white-space: nowrap;

					&:first-child {
						padding-top: 0;
					}

					a {
						text-decoration: underline;
						&:hover {
							color: $orange-bright;
						}
					}
				}
			}
		}

		&.business-partners {
			.block-products {
				.name {
					padding-bottom: $base-unit*.75;
					height: auto;
					font-size: $base-unit*1.125;
				}
			}
		}

		&.login {
			.block-products {
				flex-direction: column;
				padding: $base-unit*2;

				li {
					padding: $base-unit/2 0 0 0;
					white-space: nowrap;
					text-align: right;

					&:first-child {
						padding-top: 0;
					}
				}
			}
		}
	}

	/* ### second menu */
	// bottom row of the header
	// items that opens third level have arrow and active state
	.second-menu {
		.isActive {
			.subcategory {
				color: $orange-bright;
			}
		}

		li {
			.subcategory {
				img,
				.icon {
					filter: $filter-white;
				}

				&:hover {
					img,
					.icon {
						filter: $filter-orange-bright;
					}
				}

				&.arrow {
					// align-items: center; disabled because:
					// locales which have long Menu item names [FR, ES, da-dk] are not aligned correctly
					//align-items: center;
				}

				&.account {
					.icon-16-account {
						margin-top: 4px; // fix icon alignment
					}
				}
			}

			&[data-second-menu] {
				&:hover,
				&.isOpen {
					.subcategory {
						color: $orange-bright; // after the test will add .navigation to the path
						border: 1px solid $blue-mid;
						border-bottom: 0;
						border-radius: 12px 12px 0 0;
						background-color: $blue-deep;

						&:after {
							filter: $filter-orange-bright;
						}

					}
				}
			}
		}
	}
}

/* Bugfixes */

// GLOWEB-5357 - bug fix for overlapping text in header submenu in uk-ua region
html[lang="uk-ua"] .navigation-for-desktop .header .second-menu.blogs>li {
	max-width: 320px
}

//GLOWEB-6305 - bug fix overlaping text in third menu product tiles in ar regions
.mod-ar-ww,
.mod-ar-sa {
	&.navigation-for-desktop .header .third-menu .block-products .product {
		max-width: 400px;
	}

	&.navigation-for-desktop .header .third-menu#navigation-security .block-products .product:nth-of-type(2) {
		min-width: 335px;
	}
}

// GLOWEB-2226 - ja-jp menu text overlapping fix 
.mod-ja-jp {
	&.navigation-for-desktop.inverse .for-business .third-menu.business-partners .block-products .mobile-link {
		white-space: wrap;
	}
}

