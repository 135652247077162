/* BODY
=================
*/
body {
	font-size: $body-md-font-size-m;
	line-height: $body-md-line-height-m;

	@include media-breakpoint-up(lg) {
		font-size: $body-md-font-size;
		line-height: $body-md-line-height;
	}
}


/* ACCESSIBILITY
=================
*/
// focus - default styles
*:focus {outline: 1px solid $blue-bright;}

// Undo all the above focus styles if the browser support :focus-visible
*:focus:not(:focus-visible) { outline: none; }

// Focus only for keyboard
*:focus-visible { outline: 1px solid $blue-bright; }

.content-holder * {
	&:focus {outline-offset: $base-unit/8;}

	&:focus:not(:focus-visible) {outline-offset: $base-unit/8;}

	&:focus-visible {outline-offset: $base-unit/8;}
}


// reader only content - hidden from viewport
.reader-only {
	position: absolute;
	left: -99999px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}


/* LINKS
=================
*/

a {
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}

	&.btn,
	&.card,
	&.nav-link,
	&.btn-hint,
	&.tables-product,
	.footer &,
	.bottom-links & {
		text-decoration: none;
	}
}


/*  BUTTON
=================
*/
button {
	border: none;
	padding: 0;
	background-color: transparent;

	//accessibility
	&:focus {
		outline: 1px solid $blue-bright;
		outline-offset: 0;
	}
}


/* Color themes
=================
*/
// inverse backgrounds
.inverse {
	color: $body-color-light;
	background-color: $body-bg-inverse;

	// link - excluding buttons
	a:not(.btn) {
		color: $link-primary-color-light;

		&.light { color: $blue-dark; }
	}
}

// light backgrounds
// to be used for light boxes inside inverse theme
.light {
	color: $body-color;
	background-color: $body-bg;

	// link - excluding buttons
	a:not(.btn) {
		color: $link-color;

		&.link-secondary { color: $link-secondary-color;}
	}
}

.text-avast {
	.inverse & { color: $body-color-light; }
	&,
	.light & { color: $orange-bright; }
}

.text-muted {
	.inverse & { color: $text-muted-inverse !important; }
	&,
	.light & { color: $text-muted !important; }
}

.text-avast-hover {
	.inverse & { color: $body-color-light; }
	&,
	.light & { color: $orange-light; }
}


/* Headings
=================
*/
h1,
.h1 {
	font-size: $h1-font-size-m;
	line-height: $h1-line-height-m;
	font-weight: $h1-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h1-font-size;
		line-height: $h1-line-height;
	}
}

h2,
.h2 {
	font-size: $h2-font-size-m;
	line-height: $h2-line-height-m;
	font-weight: $h2-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h2-font-size;
		line-height: $h2-line-height;
	}
}

h3,
.h3 {
	font-size: $h3-font-size-m;
	line-height: $h3-line-height-m;
	font-weight: $h3-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h3-font-size;
		line-height: $h3-line-height;
	}
}

h4,
.h4 {
	font-size: $h4-font-size-m;
	line-height: $h4-line-height-m;
	font-weight: $h4-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h4-font-size;
		line-height: $h4-line-height;
	}
}

h5,
.h5 {
	font-size: $h5-font-size-m;
	line-height: $h5-line-height-m;
	font-weight: $h5-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h5-font-size;
		line-height: $h5-line-height;
	}
}

h6,
.h6 {
	font-size: $h6-font-size-m;
	line-height: $h6-line-height-m;
	font-weight: $h6-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h6-font-size;
		line-height: $h6-line-height;
	}
}

.h7 {
	font-size: $h7-font-size-m;
	line-height: $h7-line-height-m;
	font-weight: $h7-font-weight;

	@include media-breakpoint-up(lg) {
		font-size: $h7-font-size;
		line-height: $h7-line-height;
	}
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin-top: 0;
	border-bottom: none;
}

h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
	margin-bottom: $headings-margin-bottom;
}

h5, h6,
.h5, .h6 {
	margin-bottom: $headings-margin-bottom-small;
}

// Header stripe
.header-stripe {
	display: flex;
	justify-content: center;
	position: relative;
	width: fit-content;
	z-index: 0;

	@include media-breakpoint-up(lg) {
		display: inline-block;
		white-space: nowrap;
	}

	&:before {
		position: absolute;
		bottom: -2px;
		left: -8px;
		right: -8px;
		background: url('../../i/v2/components/header-stripe/header-stripe-normal.svg') no-repeat;
		background-position: center bottom;
		background-size: 100% 100%;
		z-index: -1;

		h1 &,
		.h1 & {
			content: '';
			height: 26px;

			@include media-breakpoint-up(lg) {
				height: 44px;
			}
		}

		h2 &,
		.h2 & {
			content: '';
			height: 21px;

			@include media-breakpoint-up(lg) {
				height: 36px;
			}
		}
	}

	.hero-centered &,
	h1.text-center &,
	.h1.text-center &,
	h2.text-center &,
	.h2.text-center & {
		margin: 0 auto;
	}

	&.green:before { filter: brightness(0) saturate(100%) invert(86%) sepia(55%) saturate(495%) hue-rotate(21deg) brightness(96%) contrast(93%);}

	&.red:before { filter: brightness(0) saturate(100%) invert(69%) sepia(52%) saturate(559%) hue-rotate(316deg) brightness(94%) contrast(116%);}

	&.yellow:before { filter: $filter-yellow;}
}


/* Texts
=================
*/

small,
.small {
	font-size: $body-sm-font-size;
	font-weight: normal;
}

.text-orange,
.text-orange-all * {
	color: $orange-bright;
}

// body text 1
.text-x-large {
	font-size: $body-xl-font-size-m;
	line-height: $body-xl-line-height-m;
	margin-top: 0;
	margin-bottom: $base-unit * 2;

	@include media-breakpoint-up(lg) {
		font-size: $body-xl-font-size;
		line-height: $body-xl-line-height;
	}
}

// body  text2
.text-large {
	font-size: $body-lg-font-size-m;
	line-height: $body-lg-line-height-m;
	margin-top: 0;
	margin-bottom: $base-unit * 1.5;

	@include media-breakpoint-up(lg) {
		font-size: $body-lg-font-size;
		line-height: $body-lg-line-height;
	}
}
// body text 3 - default text
.text-medium {
	font-size: $body-md-font-size-m;
	line-height: $body-md-line-height-m;
	margin-top: 0;
	margin-bottom: $base-unit * 1.5;

	@include media-breakpoint-up(lg) {
		font-size: $body-md-font-size;
		line-height: $body-md-line-height;
	}
}
// body text 4
.text-small {
	font-size: $body-sm-font-size-m; //12
	line-height: $body-sm-line-height-m; //18
	margin-top: 0;
	margin-bottom: $base-unit;

	@include media-breakpoint-up(lg) {
		font-size: $body-sm-font-size; //14
		line-height: $body-sm-line-height; //24
	}
}

/* Links
=================
*/

.link-primary,
.link-primary-dotted,
.link-secondary,
.link-secondary-dotted {
	color: $body-color;
}

.academy-link {
	color: $body-color;
	text-decoration: dotted underline 1px;

	&:hover {
		text-decoration: none;
	}
}

/* Taglines
=================
*/
.tagline-small {
	font-size: $tagline-sm-font-size-m;
	line-height: $tagline-sm-line-height-m;
	font-weight: $tagline-font-weight;
	letter-spacing: $tagline-sm-letter-spacing;
	text-transform: uppercase;

	@include media-breakpoint-up(lg) {
		font-size: $tagline-sm-font-size;
		line-height: $tagline-sm-line-height;
	}
}

.tagline-medium {
	font-size: $tagline-md-font-size-m;
	line-height: $tagline-md-line-height-m;
	font-weight: $tagline-font-weight;
	letter-spacing: $tagline-md-letter-spacing;
	text-transform: uppercase;

	@include media-breakpoint-up(lg) {
		font-size: $tagline-md-font-size;
		line-height: $tagline-md-line-height;
	}
}

.tagline-large {
	font-size: $tagline-lg-font-size-m;
	line-height: $tagline-lg-line-height-m;
	font-weight: $tagline-font-weight;
	letter-spacing: $tagline-lg-letter-spacing;
	display: inline-flex;
	align-items: center;
	text-transform: uppercase;

	@include media-breakpoint-up(lg) {
		font-size: $tagline-lg-font-size;
		line-height: $tagline-lg-line-height;
	}
}

.tagline-medium,
.tagline-large {
	// icon
	.product-icon {
		vertical-align: middle;
		margin-right: $base-unit/2;

		&.box { margin-right: $base-unit; }
	}
}

/* Caption
=================
*/
.caption {
	font-size: $caption-font-size-m;
	line-height: $caption-line-height;
	display: inline-block; // without it text-center won't work

	@include media-breakpoint-up(lg) {
		font-size: $caption-font-size;
	}
}

.caption-1 {
	@extend .caption;
	font-weight: 600;
}

.caption-2 {
	@extend .caption;
}

.caption-3 {
	@extend .caption;
	@extend .text-muted;
}

/* Pricing
========================
*/

// only price without period
.price-wrapper {
	display: inline-flex;
	align-items: flex-start;
	justify-content: center;
	position: relative;
	vertical-align: bottom; // to be specified

	.integer {
		font-size: 34px;
		line-height: 34px;
		font-weight: 800;

		@include media-breakpoint-up(lg) {
			font-size: 46px;
			line-height: 46px;
		}
	}

	.currency,
	.decimal {
		@extend .h7;
	}

	.decimal {
		text-align: left;

		&.empty {
			height: 24px;
		}
	}

	.price-prefix {
		margin-right: 2px;
	}

	.price-suffix {
		margin-left: 2px;
	}

	/*
		Installments are currently hidden from this component.
		PT-BR will have exception - TBD.
		Installments in Pricing Component should have own style rules in its stylesheet.
	*/
	:first-child.installments {
		margin-right: $base-unit*.5;
	}

	:last-child.installments {
		margin-left: $base-unit*.5;
	}
}

// price displayed individually, with period
.period-wrap {
	display: inline-flex;
	align-items: flex-end;
	justify-content: center;
	position: relative;
	vertical-align: bottom; // to be specified

	.price-wrapper :last-child.installments {
		margin-left: $base-unit*.2;
		align-self: flex-end;
	}
}

.period {
	align-self: flex-end;
	position: relative;
	text-align: left;
	font-size: $body-md-font-size-m;
	line-height: $body-md-line-height-m;
	margin-top: -5px;

	@include media-breakpoint-up(lg) {
		font-size: $body-md-font-size;
		line-height: $body-md-line-height;
		margin-top: -4px;
	}
	
	.inverse & { color: $gray06; }
	&,
	.light & { color: $gray03; }

	&.period-precision {
		.inverse & { color: $body-color-light; }
		&,
		.light & { color: $body-color; }
	}
}
