.feature-item {
	text-align: initial;

	@include media-breakpoint-down(md) {
		margin-bottom: $base-unit;
		height: auto;
	}

	.feature-item-right {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
		word-break: break-word;

	}

	&__desc {
		margin: 0;
	}

	&__label {
		position: absolute;
		top: 0;
		transform: translateY(-50%);
	}

	&__buttons {
		margin-top: auto;

		.btn {
			margin-right: $base-unit/2;

			&:last-child {
				margin-right: 0;
			}
		}

		.btn-link {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
			height: auto;
		}
	}

	&.card-small {
		padding: $base-unit*1.5 $base-unit*2 $base-unit*2;

		.feature-item__img {margin-bottom: $base-unit;}

		.feature-item__title {margin: 0 0 $base-unit/2 0;}

		.feature-item__buttons {padding-top: $base-unit;}

		@include media-breakpoint-down(md) {
			padding: $base-unit*1.5;

			.feature-item__desc.text-small {
				font-size: $body-md-font-size-m;
				line-height: $body-md-line-height-m;
			}

			.feature-item__title.h7 {
				font-size: $h6-font-size-m;
				line-height: $h6-line-height-m;
			}
		}
	}

	&.card-medium {
		padding: $base-unit*2;

		.feature-item__img {margin-bottom: $base-unit*1.5;}

		.feature-item__title {margin: 0 0 $base-unit/2 0;}

		.feature-item__buttons {padding-top: $base-unit*1.5;}

		@include media-breakpoint-down(md) {
			padding: $base-unit*1.5;

			.feature-item__img {margin-bottom: $base-unit;}

			.feature-item__buttons {padding-top: $base-unit;}
		}
	}

	&.card-large {
		padding: $base-unit*3;

		.feature-item__img {margin-bottom: $base-unit*1.5;}

		.feature-item__title {margin: 0 0 $base-unit 0;}

		.feature-item__buttons {padding-top: $base-unit*1.5;}

		@include media-breakpoint-down(md) {
			padding: $base-unit*2;

			.feature-item__img {margin-bottom: $base-unit;}

			.feature-item__buttons {padding-top: $base-unit;}
		}
	}


	&.card-secondary {
		flex-direction: row;

		.feature-item-right {
			position: relative;
			flex: 1;
		}

		.feature-item__img {
			margin: 0;
		}


		&.card-small {
			padding: $base-unit*2 $base-unit*2 $base-unit*2 $base-unit*1.5;

			.feature-item-left {
				margin-right: $base-unit;
			}

			.feature-item__label {
				top: -$base-unit*2;

				@include media-breakpoint-down(md) {
					top: -$base-unit*1.5;
				}
			}

			@include media-breakpoint-down(md) {
				padding: $base-unit*1.5 $base-unit*1.5 $base-unit*1.5 $base-unit;

				.feature-item__buttons {
					padding-top: $base-unit*1.5;
				}
			}
		}


		&.card-medium {
			padding: $base-unit*2 $base-unit*2 $base-unit*2 $base-unit*1.5;

			.feature-item-left {
				margin-right: $base-unit*1.5;

				@include media-breakpoint-down(md) {
					margin-right: $base-unit;
				}
			}

			.feature-item__label {
				top: -$base-unit*2;

				@include media-breakpoint-down(md) {
					top: -$base-unit*1.5;
				}
			}

			@include media-breakpoint-down(md) {
				padding: $base-unit*1.5 $base-unit*1.5 $base-unit*1.5 $base-unit;
			}
		}


		&.card-large {
			padding: $base-unit*3 $base-unit*3 $base-unit*3 $base-unit*2;

			.feature-item-left {
				margin-right: $base-unit*1.5;

				@include media-breakpoint-down(md) {
					margin-right: $base-unit;
				}
			}

			.feature-item__label {
				top: -$base-unit*3;

				@include media-breakpoint-down(md) {
					top: -$base-unit*2;
				}
			}

			.feature-item__buttons {
				padding-top: $base-unit*2;
			}

			@include media-breakpoint-down(md) {
				padding: $base-unit*2 $base-unit*2 $base-unit*2 $base-unit*1.5;
			}
		}
	}

	&.card-centered {
		text-align: center;

		.feature-item__label {
			left: 50%;
			transform: translate(-50%, -50%);
		}

		@include media-breakpoint-down(md) {
			&.card-primary-mobile,
			&.card-secondary-mobile {
				text-align: left;
				left: 0;

				.feature-item__label {
					left: auto;
				}
			}
		}
	}

	&.hover {
		&:hover {
			text-decoration: none;

			.inverse & {
				background-color: $blue-dark;
				box-shadow: 0 40px 60px -20px $black !important;
			}

			.feature-item__title {
				color: $blue-dark;

				.inverse & {
					color: $white;
				}
			}

			&.light {
				.feature-item__title {
					.inverse & {
						color: $blue-dark;
					}
				}
			}
		}
	}

	
	@include media-breakpoint-down(md) {
		// PRIMARY ON MOBILE
		&.card-primary-mobile {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			.feature-item-right {
				position: static;
			}
			
			.feature-item-left {
				margin: 0;
			}

			.feature-item__img {
				margin-bottom: $base-unit;
			}

			&.card-small,
			&.card-medium,
			&.card-large {
				.feature-item__label {
					top: 0;
				}
			}
		}

		// SECONDARY ON MOBILE
		&.card-secondary-mobile {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			text-align: left;

			.feature-item-left {
				position: relative;

				.feature-item__img {
					margin-bottom: 0;
				}
			}

			&.card-small {
				padding: $base-unit*1.5 $base-unit*1.5 $base-unit*1.5 $base-unit;

				.feature-item-left {
					margin-right: $base-unit;
				}

				.feature-item__label {
					top: -$base-unit*1.5;
				}
			}

			&.card-medium {
				padding: $base-unit*1.5 $base-unit*1.5 $base-unit*1.5 $base-unit;

				.feature-item-left {
					margin-right: $base-unit;
				}
				
				.feature-item__label {
					top: -$base-unit*1.5;
				}
			}

			&.card-large {
				padding: $base-unit*2 $base-unit*2 $base-unit*2 $base-unit*1.5;

				.feature-item-left {
					margin-right: $base-unit;
				}
			}

			.feature-item-right {
				position: relative;
				flex: 1;
			}

			.feature-item__label {
				top: -$base-unit*2;
			}
		}
	}

	/* @include media-breakpoint-up(lg) {
		// Set equal height in row
		.row & {
			height: 100%;
		}
	} */
}



// SEQUENCE OF ITEMS (arrow between items)
.feature-items {
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-breakpoint-down(md) {
		flex-direction: column;
		align-items: stretch;
		align-content: center;
	}

	.feature-item {
		width: 316px;
		//max-width: 90%;
		margin-bottom: 0;
		//height: 100%;
	}

	&__arrow {
		margin: 0 auto;
		@include sprite-image('icons', 'next', 32);
		@include media-breakpoint-down(md) {
			@include sprite-image('icons', 'down', 32);
		}

		&--left {
			@include sprite-image('icons', 'back', 32);
			@include media-breakpoint-down(md) {
				@include sprite-image('icons', 'down', 32);
			}
		}

		.inverse & {
			filter: $filter-white;
		}
	}

	// variant for System requirements
	&.feature-items-steps {
		.feature-item {
			width: auto;
		}

		@include media-breakpoint-down(md) {
			.feature-item {
				background-color: $white;
				margin-bottom: $base-unit*2;

				.inverse & {
					background-color: $blue-mid;
					border: solid 1px $blue-mid-mid !important;
				}
			}

			div:last-child {
				margin-bottom: 0;
			}

			.card-centered.card-secondary-mobile {
				.feature-item__label {
					transform: translateY(-50%);
				}
			}
		}

		@include media-breakpoint-up(lg) {
			border-radius: $base-unit*1.5;
			border: solid 1px $gray08;
			background-color: $white;

			.feature-item {
				border-radius: 0;
				width: 33.33%;
				border-left: solid 1px $gray08 !important;
			}
			
			.inverse & {
				border: solid 1px $blue-mid-mid;
				background-color: $blue-mid;

				.feature-item {
					border-left: solid 1px $blue-mid-mid !important;
				}
			}

			&>div:first-child {
				border-left: none !important;
				border-radius: $base-unit*1.5 0 0 $base-unit*1.5;
			}

			&>div:last-child {
				border-radius: 0 $base-unit*1.5 $base-unit*1.5 0;
			}
		}
	}
}
