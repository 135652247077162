.dropdown {
	.dropdown-toggle {
		text-align: initial;
		position: relative;

		&.custom-select-lg +.dropdown-menu {
			@include border-radius($base-unit*1.5);
			font-size: $body-md-font-size;
		}

		&.custom-select-sm +.dropdown-menu {
			@include border-radius($base-unit);

			&.ss-container {
				height: $base-unit*9.5;
			}

			.dropdown-item {
				padding: $base-unit/4 $dropdown-item-padding-x;
			}
		}
 
		//dropdown arrow
		&:after {
			@include sprite-image('icons', 'down-s', 16);
			content: "";
			position: absolute;
			right: $base-unit;
			top: 11px;
			transition: all ease-out .15s;

			.inverse & {filter: $filter-white;}

			&,
			.light & {filter: $filter-blue-dark;}
		}

		&.custom-select-sm:after {
			right: $base-unit*.75;
			top: 7px;
		}

		&.custom-select-lg:after {
			right: $base-unit;
			top: 14px;
		}

		&.custom-select-xl:after {
			right: $base-unit*1.25;
			top: 19px;
		}

		
		&.dropdown-icon {
			&.custom-select {
				background-image: none;
				padding-left: $base-unit*3.5;
				background-repeat: no-repeat;
				background-position-y: center;
				background-position-x: $base-unit*1.25;
				background-size: $base-unit*1.5 $base-unit*1.5;

				~ .icon {
					position: absolute;
					top: $base-unit/2;
					left: 20px;
					
					.inverse & {filter: $filter-white;}

					&,
					.light & {filter: $filter-blue-dark;}
				}
			}

			&.custom-select-sm {
				padding-left: $base-unit*2.5;
				background-position-x: $base-unit;
				background-size: $base-unit $base-unit;

				~ .icon {
					top: $base-unit/2;
					left: $base-unit;
				}
			}

			&.custom-select-lg {
				padding-left: $base-unit*3.75;
				background-position-x: $base-unit*1.5;

				~ .icon {
					top: 12px;
					left: $base-unit*1.5;
				}
			}

			&.custom-select-xl {
				padding-left: $base-unit*4;
				background-position-x: $base-unit*1.75;

				~ .icon {
					top: $base-unit;
					left: 28px;
				}
			}
		}

		&:not(.dropdown-icon) {
			background-image: none;
		}

		.inverse & {
			color: $gray06;
	
			&.js-is-filled,
			&.js-custom-filled {color: $white;}
		}
	
		&,
		.light & {
			color: $gray03;
	
			&.js-is-filled,
			&.js-custom-filled {color: $blue-dark;}
		}
	}


	&.show {
		.dropdown-toggle:after {
			transform: rotate(180deg);
		}
	}

	.dropdown-menu {
		z-index: 200;
		overflow: hidden;
		margin: -$base-unit*1.25 0 0;
		border-width: 1px;
		border-style: solid;

		.inverse & {
			background-color: $blue-deep;
			border-color: $blue-mid;
			
			@include box-shadow(0 10px 20px -10px rgba($black, .4));
		}

		&,
		.light & {
			background-color: $white;
			border-color: $input-border-color;

			@include box-shadow(0 10px 20px -10px rgba($blue-dark, .16));
		}


		&.ss-container {
			height: $base-unit*11;

			.ss-wrapper {
				overflow: hidden;
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 1;
				float: left;
			}

			.ss-content {
				height: 100%;
				width: calc(100% + 18px);
				padding: 0;
				position: relative;
				overflow: auto;
				box-sizing: border-box;
			}

			.ss-content.rtl {
				width: calc(100% + 18px);
				right: auto;
			}

			.ss-scroll {
				position: absolute;
				width: 4px;
				border-radius: 2px;
				top: $base-unit;
				right: $base-unit/2 !important;
				z-index: 3;
				cursor: pointer;
				//opacity: 0;
				height: 20%;
				transition: all .1s linear;

				.inverse & { background-color: $gray03; }

				&,
				.light & { background-color: $gray03; }
			}

			.ss-scrolldrive {
				position: absolute;
				width: 4px;
				border-radius: 2px;
				background-color: rgba($gray03, .16);
				top: $base-unit;
				z-index: 2;
				height: calc(100% - 2*#{$base-unit});
				right: $base-unit/2 !important;
			}

			.ss-hidden {
				opacity: 0;
			}

			.ss-grabbed {
				-o-user-select: none;
				-ms-user-select: none;
				-moz-user-select: none;
				-webkit-user-select: none;
				user-select: none;
			}
		}

		&:focus {
			outline: none;
		}
	}

	.dropdown-item {
		display: inline-flex;
		line-height: $body-md-line-height;
		text-decoration: none;

		&:focus {
			outline: none;
		}

		&:hover,
		&:focus {
			.inverse & { background-color: $blue-mid; }

			&,
			.light & { background-color: $dropdown-link-hover-bg; }
		}

		&.active {
			font-weight: 600;
		}

		&.disabled,
		&:disabled {
			.inverse & { color: $gray03; }

			&,
			.light & { color: $gray06; }

		}

		img,
		.icon {
			width: $base-unit*1.5;
			height: $base-unit*1.5;
			margin: 0 $base-unit*.75 0 $base-unit/-2;

			.inverse & {filter: $filter-white;}
			
			&,
			.light & {filter: $filter-blue-dark;}
		}
	}
}
