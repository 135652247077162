/* Forms Inputs Avast extension */

.form-control {
	margin-bottom: $base-unit;

	// Colors
	.inverse & {
		background-color: $blue-deep;
		color: $white;
		border-color: $blue-mid;

		@include box-shadow(inset 0 2px 4px rgba($black, .32));
	}

	&,
	.light & {
		background-color: $white;
		color: $input-color;
		border-color: $gray08;

		@include box-shadow(inset 0 2px 4px rgba($blue-dark, .12));
	}


	&:hover {
		.inverse & {border-color: #37586C;}

		&,
		.light & {border-color: $gray05;}
	}

	&:focus,
	&.focus,
	&:active,
	&.active,
	&:focus:hover,
	&.focus:hover,
	&:active:hover,
	&.active:hover {
		border-color: $blue-bright;

		.inverse & {
			@include box-shadow(inset 0 2px 4px rgba($black, .32), $input-focus-box-shadow);
		}

		&,
		.light & {
			@include box-shadow(inset 0 2px 4px rgba($blue-dark, .12), $input-focus-box-shadow);
		}
	}

	// Placeholder
	&::placeholder {
		.inverse & {color: $gray06;}
		
		&,
		.light & {color: $gray03;}
	}

	&:disabled,
	&[readonly] {
		pointer-events: none;
		box-shadow: none;
		opacity: 1;

		.inverse & {
			border-color: $blue-mid;
			color: $gray03;

			&::placeholder {
				color: $gray03;
			}

			~ .icon {
				filter: $filter-gray03;
			}
		}	

		&,
		.light & {
			border-color: $gray08;
			color: $gray06;

			&::placeholder {
				color: $gray06;
			}

			~ .icon {
				filter: $filter-gray06;
			}
		}
	}

	&-lg {
		padding: $input-padding-y-lg;
	}

	/*
	* Icons in the input element
	*/
	// 1 - Icon as sprite should be added only if input element is wrapped in .form-control-wrapper or .interactive-label-wrapper (see down in code)
	~ .icon {
		display: none;
	}

	// 2 - Icon as background image on input
	&.form-icon-before,
	&.form-icon-after {
		background-repeat: no-repeat;
		background-size: $base-unit*1.5 $base-unit*1.5;
		background-position-y: center;
	}

	&.form-icon-before {
		background-position-x: $base-unit*1.25;
		padding-left: $base-unit*3.5;
	}

	&.form-icon-after {
		background-position-x: calc(100% - #{$base-unit});
		padding-right: $base-unit*3.25;
	}


	&.form-control-sm {
		&.form-icon-before {
			background-position-x: $base-unit;
			background-size: $base-unit;
			padding-left: $base-unit*2.5;
		}

		&.form-icon-after {
			background-position-x: calc(100% - #{$base-unit*.75});
			background-size: $base-unit;
			padding-right: $base-unit*2.25;
		}
	}

	&.form-control-lg {
		&.form-icon-before {
			background-position-x: $base-unit*1.5;
			padding-left: $base-unit*3.75;
		}

		&.form-icon-after {
			background-position-x: calc(100% - #{$base-unit});
			padding-right: $base-unit*3.25;
		}
	}
	
	// all inputs have same styling on mobile
	@include media-breakpoint-down(md) {
		height: 40px;
		border-radius: 20px;
		font-size: 12px;
		line-height: 18px;
		padding-top: 8px;
		padding-bottom: 8px;
		
		&,
		&.form-control-sm,
		&.form-control-lg {
			&.form-icon-before {
				background-position-x: $base-unit*1.25;
				background-size: $base-unit*1.5;
				padding-left: $base-unit*3.5;
			}

			&.form-icon-after {
				background-position-x: calc(100% - #{$base-unit});
				background-size: $base-unit*1.5;
				padding-right: $base-unit*3.25;
			}
		}
	}
}


.form-text {
	@include font-size($form-text-font-size);
	line-height: $form-text-line-height;
}


// Text Area
textarea.form-control {
	@include border-bottom-right-radius(4px);
}


// Interactive label - is shrinking when is text field focused
.interactive-label-wrapper {
	.interactive-label {
		position: absolute;
		top: $base-unit*.825;
		left: ($base-unit + .1);
		margin: 0;
		transition: .2s;
		cursor: text;
		//label multiline ellipsis:
		text-overflow: ellipsis;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		max-height: $input-line-height-lg;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		pointer-events: none;

		.inverse & {color: $gray06;}

		&,
		.light & {color: $gray03;}
	}

	&.dropdown {
		.interactive-label {
			.inverse & {color: $gray06;}

			&,
			.light & {color: $gray03;}
		}
	}

	.form-control {
		padding: .75rem 1rem;
	}

	.form-icon-before + .interactive-label,
	.dropdown-icon + .interactive-label {
		left: $base-unit*3.8;
		padding-right: $base-unit * 1.5;
	}

	.form-icon-after + .interactive-label {
		right: $base-unit*3.5;
	}

	.form-control.js-is-filled,
	.form-control.js-custom-filled,
	.dropdown-toggle.js-is-filled,
	.dropdown-toggle.js-custom-filled { // won't be removed even if the input is empty
		padding-top: $base-unit*1.1875;
		padding-bottom: $base-unit*.3125;

		+ .interactive-label {
			font-size: $base-unit*.75;
			line-height: $base-unit;
			top: $base-unit*.375;

			.inverse & {color: $orange-bright;}

			&,
			.light & {color: $blue-bright;}
		}

		&:focus + .interactive-label {
			.inverse & {color: $orange-bright;}

			&,
			.light & {color: $blue-bright;}
		}
	}

	.form-control:focus,
	.form-control:active {
		+ .interactive-label {
			.inverse & {color: $gray06;}

			&,
			.light & {color: $gray03;}
		}
	}

	.dropdown-toggle:focus,
	.dropdown-toggle:active {
		+ .interactive-label {
			.inverse & {color: $gray06;}

			&,
			.light & {color: $gray03;}
		}
	}

	textarea.form-control {
		padding-top: $base-unit*.75;
		padding-bottom: $base-unit*.25;
	}
}


// Icon as sprite
.form-control-wrapper,
.interactive-label-wrapper {
	position: relative;

	.form-icon-before ~ .icon ,
	.form-icon-after ~ .icon {
		display: block;
		position: absolute;
	}

	.form-icon-before:not(:disabled) ~ .icon ,
	.form-icon-after:not(:disabled) ~ .icon {
		.inverse & {filter: $filter-white;}
		
		&,
		.light & {filter: $filter-blue-dark;}
	}

	.form-control {
		&.form-icon-before ~ .icon {
			top: $base-unit/2;
			left: 20px;
		}

		&.form-icon-after ~ .icon {
			top: $base-unit/2;
			right: $base-unit;
		}
	}

	.form-control-sm {
		&.form-icon-before ~ .icon {
			top: $base-unit/2;
			left: $base-unit;
		}

		&.form-icon-after ~ .icon {
			top: $base-unit/2;
			right: 12px;
		}
	}

	.form-control-lg {
		&.form-icon-before ~ .icon {
			top: 12px;
			left: $base-unit*1.5;
		}

		&.form-icon-after ~ .icon {
			top: 12px;
			right: $base-unit;
		}
	}

	.form-control-xl {
		&.form-icon-before ~ .icon {
			top: $base-unit;
			left: 28px;
		}

		&.form-icon-after ~ .icon {
			top: $base-unit;
			right: 20px;
		}
	}
	

	@include media-breakpoint-down(md) {
		.form-control,
		.form-control-sm,
		.form-control-lg {
			&.form-icon-before ~ .icon,
			&.form-icon-after ~ .icon {
				top: $base-unit*.75;
			}
		}
	}
}


.form-text,
.valid-feedback,
.invalid-feedback {
	margin-bottom: $base-unit;
}


.was-validated .form-control:valid,
.form-control.is-valid {
	color: $status-ok;

	&::placeholder {color: rgba($status-ok, .6);}

	.interactive-label {color: rgba($status-ok, .6);}
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
	color: $status-critical;

	&::placeholder {color: rgba($status-critical, .6);}

	.interactive-label {color: rgba($status-critical, .6);}
}