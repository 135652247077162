/*
 Breadcrumb navigation
 (used in hero pattern)
*/

.breadcrumb {
	position: relative;
	top: $base-unit * -3;
	line-height: $base-unit;

	.breadcrumb-item {
		// no link
		color: $breadcrumb-inactive-color;

		// with link
		a {
			color: $breadcrumb-active-color;
			//text-decoration: underline;
		}

		+ .breadcrumb-item {
			padding-left: $base-unit/4;

			&:before {
				padding-right: $base-unit/4;
			}

			@include media-breakpoint-up(md) {
				padding-left: $base-unit/2;

				&:before {
					padding-right: $base-unit/2;
				}
			}
		}
	}
}

// inverse colors
.inverse {
	.breadcrumb {
		.breadcrumb-item {
			// no link
			color: $breadcrumb-inactive-color-inverse;

			// with link
			a {
				color: $breadcrumb-active-color-inverse;
			}

			+ .breadcrumb-item {
				&:before {
					color: inherit;
				}
			}
		}
	}
}

