.articles {
	//Items above carousel
	&.articles-header {
		padding-top: $base-unit*8;

		@include media-breakpoint-down(md) {
			padding-top: $base-unit*4;
		}

		.articles-title {
			h2 {margin-bottom: 0;}
		}

		.articles-text {
			@include media-breakpoint-down(md) {
				margin-top: $base-unit*2;
			}
			@include media-breakpoint-up(md) {
				text-align: right;
			}

			.article-text-link-icon {
				filter: $filter-blue-bright;
			}
		}
	}
}

.carousel-slider[data-cmp-name="cmp-articles-carousel"] {
	.tiny-slider .tns-item {
		@include media-breakpoint-down(md) {
			@include elevation-box-shadow($size:"m");
		}
		@include media-breakpoint-up(lg) {
			&:hover {
				background-color: $blue-faint;
			}

			.inverse &:hover {
				background-color: $blue-deep;
			}
		}
	}
}
