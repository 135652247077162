/* Media object */
.media-container {
	background-size: cover;

	@include media-breakpoint-up(sm) {
		@include radius(48px);
	}
}

.media {
	align-items: center;
	padding-top: 8 * $base-unit;
	padding-bottom: 8 * $base-unit;
	min-height: $base-unit*31;

	@include media-breakpoint-down(md) {
		padding: $base-unit*6 0;
	}
	@include media-breakpoint-up(md) {
		// left / right flow
		&.media-image-right {
			flex-direction: row-reverse;
		}
	}

	.media-image {
		text-align: center;

		picture { position: relative; }

		img.media-image-desktop {
			height: auto;
			margin: auto;
			// In grid set width of the image the same as container
			width: 100%;
			image-rendering: -moz-crisp-edges;
			image-rendering: -o-crisp-edges;
			image-rendering: -webkit-optimize-contrast;
			image-rendering: crisp-edges;
			-ms-interpolation-mode: nearest-neighbor;

			&-large { max-width: $base-unit*41.5; }

			&-medium { max-width: 84.5%; }

			&-small { max-width: $base-unit*29; }
		}
	}

	.media-body {
		// mobile text is centered
		@include media-breakpoint-down(md) {
			text-align: center;

			.media-title {
				margin-bottom: $base-unit*2;
			}

			.media-body-image {
				margin-bottom: $base-unit*2;

				img.media-image-mobile {
					width: 100%;
					height: auto;
					image-rendering: -moz-crisp-edges;
					image-rendering: -o-crisp-edges;
					image-rendering: -webkit-optimize-contrast;
					image-rendering: crisp-edges;
					-ms-interpolation-mode: nearest-neighbor;
				}
			}
		}

		.platform-icons {
			display: inline-flex;
			flex-direction: row;
			margin-bottom: $base-unit;

			.icon:not(:last-child) {
				margin-right: $base-unit;
			}

			@include media-breakpoint-up(lg) {
				display: flex;
			}
		}


		.media-title,
		[class*='tagline'],
		[class*='badge'] {
			position: relative;
			z-index: 1;
		}

		[class*='tagline'] {
			margin-bottom: $base-unit;

			@include media-breakpoint-down(md) {
				display: block;
			}
		}

		.media-text {
			position: relative;
			font-size: $body-lg-font-size-m;
			line-height: $body-lg-line-height-m;

			@include media-breakpoint-up(lg) {
				font-size: $body-lg-font-size;
				line-height: $body-lg-line-height;
			}
		}
	}

	.media-image,
	.media-body-image {
		position: relative;

		.btn-wrapper {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.btn:not(:disabled):not(.disabled):focus,
			.btn:not(:disabled):not(.disabled):active:focus {
				box-shadow: none;
			}

			.media-img-button {
				padding: 0;

				img { height: 64px; }
			}
		}
	}

	.media-image-desktop.img-rounded,
	.media-image-mobile.img-rounded {
		border-radius: 0 32px 32px;

		@include media-breakpoint-up(lg) {
			border-radius: 0 48px 48px;
		}
	}
}

/* inverse media object has different top/bottom paddings */
.inverse .media,
.media-partial-section .media {
	padding-top: 5 * $base-unit;
	padding-bottom: 5 * $base-unit;
}


.media-partial-section {
	position: relative;
	overflow-x: hidden;

	&:before {
		content: '';
		display: block;
		position: absolute;
		height: 100%;
		width: 100vw;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}

	&.partial-right:before {
		@include media-breakpoint-up(lg) { right: $bg-offset-lg; }

		@media (min-width: $breakpoint-custom-lg1) { right: $bg-offset-lg1; }
		@media (min-width: $breakpoint-custom-lg2) { right: $bg-offset-lg2; }

		@include media-breakpoint-up(xl) { right: $bg-offset-xl; }
	}

	&.partial-left:before {
		@include media-breakpoint-up(lg) { left: $bg-offset-lg; }

		@media (min-width: $breakpoint-custom-lg1) { left: $bg-offset-lg1; }
		@media (min-width: $breakpoint-custom-lg2) { left: $bg-offset-lg2; }

		@include media-breakpoint-up(xl) { left: $bg-offset-xl; }
	}
}


/* v3 features */
//BLADE BACKGROUND
[class*="media-blade-"] {
	position: relative;
	overflow: hidden;

	&:before {
		display: none;
		content: '';
		background-image: url("../../i/v2/components/bg/blade-horizontal.svg");
		position: absolute;
		width: 935px;
		height: 255px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 0;
		top: 50%;
		transform: translateY(-50%);

		@include media-breakpoint-up(lg) {
			display: block;
			width: 1100px;
			height: 300px;
		}

		@media (min-width: 1280px) {
			width: 1741px;
			height: 476px;
		}
	}

	.media-body-image {
		&:before {
			display: block;
			content: '';
			background-image: url("../../i/v2/components/bg/blade-horizontal.svg");
			position: absolute;
			width: 2000px;
			height: calc(100% + 50px);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			z-index: -1;
			top: 50%;

			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
	}

	.media-body {
		@include media-breakpoint-down(md) {
			.media-title {
				margin-bottom: $base-unit*4.25;
			}

			.media-body-image {
				margin-bottom: $base-unit*4.25;
			}
		}
	}
}

.media-blade-left {
	&:before {
		left: calc(50% - 935px);

		@include media-breakpoint-up(lg) {
			left: calc(50% - 1200px);
		}

		@media (min-width: 1280px) {
			left: calc(50% - 1855px);
		}
	}

	.media-body-image {
		&:before {
			transform: translateY(-50%) translateX(-57%);
		}
	}
}

.media-blade-right {
	&:before {
		right: calc(50% - 935px);
		transform: scaleX(-1) translateY(-50%);

		@include media-breakpoint-up(lg) {
			right: calc(50% - 1200px);
		}

		@media (min-width: 1280px) {
			right: calc(50% - 1855px);
		}
	}

	.media-body-image {
		&:before {
			transform: scaleX(-1) translateY(-50%) translateX(-57%);
			right: 0;
		}
	}
}
