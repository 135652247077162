/*
Platform-switcher
=================
*/

// Show default variant for users without javascript
html.no-js {
	.js-android,
	.js-ios,
	.js-mac {
		&:not(.js-pc) {
			display: none !important;
			position: absolute !important;
			left: -9999px !important;
		}
	}
}
// Default non js

// Legacy platform selector
.js-android-market {
	display: none !important;
	position: absolute !important;
	left: -9999px !important;
}

// PC
html.js-pc {
	.js-mac,
	.js-android,
	.js-ios {
		&:not(.js-pc) {
			display: none;
		}
	}

	.js-not-pc {
		display: none;
	}
}

// MAC
html.js-mac {
	.js-pc,
	.js-android,
	.js-ios {
		&:not(.js-mac) {
			display: none;
		}
	}

	.js-not-mac {
		display: none;
	}
}

// ANDROID
html.js-android {
	.js-pc,
	.js-mac,
	.js-ios {
		&:not(.js-android) {
			display: none;
		}
	}

	.js-not-android {
		display: none;
	}
}

// IOS
html.js-ios {
	.js-pc,
	.js-mac,
	.js-android {
		&:not(.js-ios) {
			display: none;
		}
	}

	.js-not-ios {
		display: none;
	}
}


/* # Show default variant for users without javascript */
/* html.no-js {
	.js-platform-switch {
		.js-pc {
			position: relative;
			left: auto;
		}
		.js-android,
		.js-android-market,
		.js-ios,
		.js-mac {
			position: absolute;
			left: -9999px;
		}
	}
}

// # PC
html.js-pc {
	.js-platform-others {
		.js-pc {
			display: none;
		}
	}

	.js-platform-switch {
		.js-android,
		.js-android-market,
		.js-ios,
		.js-mac {
			display: none;
		}
	}
}

// # Android
html.js-android {
	.js-platform-others {
		.js-android {
			display: none;
		}
	}

	.js-platform-switch {
		.js-pc,
		.js-android-market,
		.js-ios,
		.js-mac {
			display: none;
		}
	}
}

// # iOS 
html.js-ios {
	.js-platform-others {
		.js-ios {
			display: none;
		}
	}

	.js-platform-switch {
		.js-android,
		.js-android-market,
		.js-pc,
		.js-mac {
			display: none;
		}
	}
}

// # Mac
html.js-mac {
	.js-platform-others {
		.js-mac {
			display: none;
		}
	}

	.js-platform-switch {
		.js-android,
		.js-android-market,
		.js-pc,
		.js-ios {
			display: none;
		}
	}
} */

// browser detection
[data-browser] {
	display: none;

	&:first-child {
		display: initial;
	}
}
