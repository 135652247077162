/******************
// AWARD COMPONENT
******************/

.award {
	text-align: center;

	@include media-breakpoint-down(md) {
		margin-bottom: $base-unit;
	}

	.card-img-top {
		margin: $base-unit auto;
		max-width: 160px;
		width: 100%;
		height: 80px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain !important;

		@include media-breakpoint-up(lg) {
			margin: $base-unit*2.5 auto 0;
		}
	}

	.card-body {
		padding-top: $base-unit*1.5;
		padding-bottom: 0;
	}

	.rating {
		margin-bottom: $base-unit/2;
	}

	.card-title {
		margin-bottom: $base-unit/2;
	}

	.card-date {
		margin-bottom: 0;
	}

	.card-footer {
		padding-top: $base-unit;
		padding-bottom: $base-unit*1.5;

		@include media-breakpoint-up(lg) {
			padding-top: $base-unit*1.5;
			padding-bottom: $base-unit*2.5;
		}

		&.google,
		&.apple {
			img {
				max-height: 40px;
			}
		}
	}

	&.card-footer-empty {
		.card-body {
			padding-bottom: $base-unit*.5;

			@include media-breakpoint-up(lg) {
				padding-bottom: $base-unit*2.5;
			}
		}
	}
}