.chrome-banner {
	padding: ($base-unit * 5) 0;
	background: #112633;
	box-shadow: inset 0 1px 0 rgba(57, 67, 73, .5);

	@include media-breakpoint-down(md) {
		padding: ($base-unit * 3) 0;
	}

	&__content {
		display: flex;
		align-items: center;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__info {
		display: flex;
		align-items: center;
		width: 50%;

		@include media-breakpoint-down(sm) {
			width: 100%;
			margin-bottom: $base-unit;
			align-items: flex-start;
		}
	}

	&__ico {
		margin-right: ($base-unit * 1.25);

		img {
			width: ($base-unit * 4);

			@include media-breakpoint-down(sm) {
				width: ($base-unit * 3);
			}
		}
	}

	&__title {
		margin: 0;
	}

	&__cta {
		text-align: right;
		flex: 1;

		@include media-breakpoint-down(sm) {
			text-align: left;
			margin-left: ($base-unit * 4.25);
		}
	}

	&__play {
		img {
			width: 135px;
		}
	}
}
